.single-select-dropdown {
  font-size: 0.75rem !important;
  position: relative;
  width: 390px;
  border-bottom: 1px solid black;
  background-color: var(--neutral__enabled__fill__default);

  @media only screen and (max-width: 768px) {
    width: 200px;
    float: right;
    margin-left: 50px;
  }
}

.single-select-dropdown__header {
  cursor: pointer;
  padding: 4px 10px;

  &:hover,
  &:active {
    background-color: var(--neutral__enabled__fill__hovered);
  }

  .single-select-dropdown__arrow {
    width: 20px;
    float: right;
  }
}

.single-select-dropdown__content {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.single-select-dropdown__options {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 350px;
  overflow-y: auto;
  width: 390px;
  background-color: var(--neutral__enabled__fill__hovered);
  z-index: 999;

  @media only screen and (max-width: 768px) {
    width: 200px;
  }
}

.single-select-dropdown__search {
  width: 100%;
  padding: 10px;
  background-color: var(--neutral__enabled__fill__default);
  border: none;
  font-size: 0.95rem;

  @media only screen and (max-width: 768px) {
    width: 200px;
  }
}

.single-select-dropdown__option {
  padding: 5px 10px;
  cursor: pointer;

  &:hover {
    color: white;
    background-color: var(--major-accent__enabled__fill__default);
  }
}