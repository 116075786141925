@import '../../../stylesheet/variables.scss';
@import '../../../stylesheet/mixins.scss';

.item {
  &__heading {
    font-size: 1.5rem;
    color: var(--bosch-black);
    font-weight: 600;

    @media only screen and (max-width: 768px) {
      margin-left: 1rem;
    }
  }

  &__api__manually {
    margin-left: 2rem;

    @media only screen and (max-width: 768px) {
      margin-left: 0;
    }
  }

  &__previous_button {
    text-align: center;
    font-size: 1rem;
    letter-spacing: 0rem;
    color: var(--bosch-white);
    background: var(--bosch-blue-50);
    opacity: 1;
    width: 4rem;
    border-style: none;
    height: 2rem;
    cursor: pointer;
  }

  &__sub-heading {
    color: var(--bosch-gray-71);
    margin: 0.5rem 0 1rem 0;

    @media only screen and (max-width: 768px) {
      margin-left: 1rem;
    }
  }

  &__close_button {
    display: flex;
    justify-content: space-between;
    padding: 0.625rem;
  }

  &__close_text {
    text-align: left;
    font-size: 1rem;
    letter-spacing: 0rem;
    color: var(--bosch-blue-50);
    opacity: 1;
    cursor: pointer;
    margin-left: 2rem;
  }

  &__secondary_main {
    display: flex;
    margin-right: 2rem;
  }

  &__secondary_sub_main {
    margin-right: 1rem;
  }

  &__swagger__icon {
    display: flex;
    align-items: center;
    position: absolute;
    right: 1rem;
    top: 3rem;
  }

  &__background-smoke {
    background: var(--bosch-white);
    margin-bottom: 2rem;
    border: 0.125rem solid $--bosch-white-20;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    padding: 1rem;
    border-radius: 1.175rem;
  }

  &__background-smoke_mkosint {
    background: var(--bosch-white);
    margin-bottom: 2rem;
    border: 0.125rem solid $--bosch-white-20;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    padding: 1rem;
    border-radius: 1.175rem;
  }

  &__box-heading-swagger {
    font-size: 1.5rem;
    color: var(--bosch-blue-50);
  }

  &__add-api-img-style {
    width: 69%;
    margin: 0 auto;
    @include flexAlignCenter;
    justify-content: center;

    @media only screen and (max-width: 768px) {
      width: 36%;
    }
  }

  &__api-title {
    margin-top: 1rem;
    padding-bottom: 0.375rem;
    font-weight: 900;
    color: var(--bosch-white);
  }

  &__api-title1 {
    margin-top: 0.5rem;
    padding-bottom: 0.375rem;
    font-weight: 900;
    color: var(--bosch-black);
    display: flex;
    justify-content: center;
  }

  &__api-selected {
    background: var(--bosch-white);
    border-radius: 1rem;
    width: 10.5rem;
    height: 10.4rem;
    border: 0.125rem solid var(--bosch-blue-50);
    cursor: pointer;
    display: flex;
    flex-flow: column;
    position: relative;
    justify-content: space-around;
  }

  &__api-disabled {
    background: var(--bosch-white);
    border-radius: 1rem;
    width: 10.5rem;
    height: 10.5rem;
    opacity: 1;
    border: 0.125rem solid $--bosch-white-20 !important;
    display: flex;
    flex-flow: column;
    justify-content: space-around;

    .item__api_list {
      border: 0.063rem solid var(--bosch-gray-70);
      width: 1.6rem;
      height: 1.6rem;
      margin: 0.4rem 0.7rem;
    }
  }

  &__api-icon {
    margin-top: 0.3125rem;
  }

  &__checked {
    width: 2.25rem;
    height: 2.25rem;
    border-radius: 50%;
    vertical-align: middle;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
    margin: 0 auto;
    position: relative;
    top: -0.188rem;
    left: -0.188rem;
  }

  &__check-icon {
    color: var(--bosch-white);
    font-size: 1rem !important;
    padding-top: 0.1875rem;
    padding-left: 0.125rem;
    margin-left: 0.0938rem;
  }

  &__vertical {
    margin-top: 1.25rem;
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: var(--bosch-white);
    left: 0;
  }

  &__api_selection {
    @include flexCenter;
    gap: 2rem;

    @media only screen and (max-width: 768px) {
      flex-wrap: wrap;
      margin: 0 1rem 1rem 1rem;
    }
  }

  &__api_list {
    border: 0.125rem solid var(--bosch-white-20);
    border-radius: 50%;
    vertical-align: middle;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
    margin: 0.25rem 0.3125rem 0rem 0.3125rem;
  }

  &__certain-icon {
    color: var(--bosch-white);
    font-size: 1rem !important;
    padding-top: 0.1875rem;
    padding-left: 0.125rem;
    margin-left: 0.0938rem;
  }

  &__list-item {
    list-style: none;
    flex: 0 0 10% !important;
    position: relative;
  }

  &__list-content {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 0;
    top: 0;
  }

  &__api-content {
    display: flex;
    justify-content: center;

    img {
      width: 5rem;
      height: 5rem;
      object-fit: contain;
    }
  }

  &__api-title-selected {
    margin-top: 0.3125rem;
    padding-bottom: 0.375rem;
    font-weight: 900;
    display: flex;
    justify-content: center;
  }

  &__checkbox-round {
    width: 1.5rem;
    height: 1.5rem;
    background-color: var(--bosch-white);
    border-radius: 50%;
    vertical-align: middle;
    border: 0.125rem solid var(--bosch-gray-71);
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
    margin: 0.25rem 0.3125rem 0rem 0.3125rem;
  }

  &__arrow-back {
    display: none;
  }

  &__img-icon {
    height: 5rem;
    width: 5rem;
  }
}

.apiflow_getstart_page_mkosint,
.apiflow_getstart_page {
  background-color: var(--bosch-white);
  &.apiflow_getstart_page {
    height: calc(100vh - 13rem);
  }
  &.apiflow_getstart_page_mkosint {
    height: calc(100vh - 9rem);
  }
  @include flexAlignCenter;
  border-radius: 0.625rem;

  @media only screen and (max-width: 768px) {
    flex-flow: column-reverse;
    justify-content: space-evenly;
    height: 100%;

    .apiflow_getstart_box {
      width: 100%;
      overflow-y: scroll;
    }

    .outer-box {
      margin: 0 !important;
    }
  }

  .swagger {
    &-container {
      margin: 0;
      margin-left: 3rem;
      margin-top: 5rem;

      @media only screen and (max-width: 768px) {
        margin: 0 1rem 1rem 1rem;
      }

      &-wrapper {
        @include flex_col();
        gap: 1.5rem;

        .heading,
        .sub-heading {
          color: var(--bosch-black);
        }

        .dropzone-wrapper {
          @include flexAlignCenter;
          gap: 2rem;
          height: 10rem;

          @media only screen and (max-width: 768px) {
            height: unset;
          }

          .sub-heading-file-name {
            @media only screen and (max-width: 768px) {
              @include text-ellipsis;
              max-width: 10rem;
            }
          }
        }
      }
    }
  }

  .sub-heading-description-icon {
    width: 2.5rem;
    height: 2.5rem;
  }
}

.api__section {
  background-color: $--bosch-concrete-solid;
  width: 100%;

  @media only screen and (max-width: 768px) {
    background-color: $--bosch-white;
  }
}

.api__add__manually {
  height: calc(100vh - 5rem);
  @include flexJustifyCenter;
  flex-direction: column;
  width: 86%;
  margin: 0 auto;

  @media only screen and (max-width: 768px) {
    padding-top: 3rem;
    padding-bottom: 5rem;
    width: 100%;
  }
}

.api {
  @include flexSpaceBetween;
  width: 100%;

  @media only screen and (max-width: 768px) {
    @include flexCustom(space-between, flex-start);
  }

  &__products {
    @include flexAlignCenter;

    @media only screen and (max-width: 768px) {
      @include flexCustom(flex-end, flex-start);
      flex-direction: column;
    }
  }

  &__add_icon,
  &__add_icon_mkosint {
    background: var(--bosch-green-85);
    @include flexCenter;
    border-radius: 50%;

    @media only screen and (max-width: 768px) {
      width: 4rem;
      height: 4rem;
    }

    img {
      @media only screen and (max-width: 768px) {
        width: 2rem;
        height: 2rem;
      }
    }
  }

  &__add_icon {
    width: 7.375rem;
    height: 7.375rem;

    img {
      width: 4rem;
      height: 4rem;
    }
  }

  &__add_icon_mkosint {
    width: 5rem;
    height: 5rem;

    img {
      width: 3rem;
      height: 3rem;
    }
  }

  &__add__swagger__icon,
  &__add__swagger__icon_mkosint {
    background: var(--bosch-purple-85);
    justify-content: center;
    @include flexAlignCenter;
    border-radius: 50%;

    @media only screen and (max-width: 768px) {
      width: 4rem;
      height: 4rem;

      img {
        width: 2rem;
        height: 2rem;
      }
    }

    img {
      @media only screen and (max-width: 768px) {
        width: 3rem;
        height: 3rem;
      }
    }
  }

  &__add__swagger__icon_mkosint {
    width: 5rem;
    height: 5rem;
    img {
      @media not (max-width: 768px) {
        width: 3rem;
        height: 3rem;
      }
    }
  }

  &__add__swagger__icon {
    width: 7.375rem;
    height: 7.375rem;
    img {
      @media not (max-width: 768px) {
        width: 4rem;
        height: 4rem;
      }
    }
  }

  &__bosch_system_icon,
  &__bosch_system_icon_mkosint {
    background: var(--bosch-blue-85);
    @include flexAlignCenter;
    border-radius: 50%;

    @media only screen and (max-width: 768px) {
      width: 4rem;
      height: 4rem;

      img {
        width: 2rem;
        height: 2rem;
      }
    }

    img {
      @media not (max-width: 768px) {
        width: 4rem;
        height: 4rem;
      }

      @media only screen and (max-width: 768px) {
        width: 3rem;
        height: 3rem;
      }
    }
  }

  &__bosch_system_icon_mkosint {
    width: 5rem;
    height: 5rem;
    justify-content: center;
  }

  &__bosch_system_icon {
    width: 7.375rem;
    height: 7.375rem;
    @include flexCenter;
  }

  &__img {
    @include flexAlignCenter;
  }

  &__text {
    margin: 0.5rem;
  }

  &__main_text {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  &__space-around {
    padding: 0.875rem;
  }

  &__icon {
    height: 1.875rem;
    width: 1.875rem;
    color: var(--bosch-gray-75);
  }
  &__arrow-icon {
    margin-top: 2rem;
    @media only screen and (max-width: 768px) {
      width: 1.5rem;
      height: 1.5rem;
      margin: 2rem 0.5rem;
    }
  }

  &__arrow-icon-diabled {
    height: 1.875rem;
    width: 1.875rem;
    color: var(--bosch-gray-75);
    margin-left: 0.5rem;
    border: 0.063rem solid var(--bosch-gray-75);
    border-radius: 50%;
    margin-top: 2rem;

    @media only screen and (max-width: 768px) {
      width: 1.5rem;
      height: 1.5rem;
      margin: 2rem 0.5rem;
    }
  }
}

.add__api__subtitle {
  color: var(--bosch-black) !important;
  font-weight: 600;
  font-size: 1.5rem;
  margin-bottom: 0.2rem;
}

.add__api__description {
  width: 20.625rem;

  @media only screen and (max-width: 768px) {
    width: 13rem;
  }
}

.drop-zone-box {
  border: 0.0625rem dashed $--bosch-silver-sand !important;
  text-align: center;
  @include flexAlignCenter;
  height: 10rem;
  margin-left: 2rem;
  justify-content: center;

  @media only screen and (max-width: 768px) {
    margin-left: unset;
    margin-right: unset;
  }
}

.drop-zone-label {
  color: var(--bosch-gray) !important;
}

.drop-zone-msg {
  color: var(--bosch-gray) !important;
  padding-left: 1.75rem;

  @media only screen and (max-width: 768px) {
    padding-left: unset;
  }
}

.item___background-smoke-active_mkosint,
.item___background-smoke-active {
  background: var(--bosch-white);
  margin-bottom: 2rem;
  border: 0.125rem solid var(--major-accent__enabled__fill__default);
  @include flexAlignCenter;
  position: relative;
  overflow: hidden;
  padding: 1rem;
  border-radius: 1.175rem;
}

img.error-symbol {
  width: 1.5rem;
}

.hide-modal {
  display: none;
}

.modal-div {
  border: 0.0625rem dashed $--bosch-silver-sand !important;
}
