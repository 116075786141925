@import '../../../variables.module.scss';
@import '../../../stylesheet/variables.scss';
@import '../../../stylesheet/mixins.scss';

.footer {
  display: flex;
  align-items: flex-end;
  color: var(--bosch-black);
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background-color: var(--bosch-white);
  height: 3.75rem;

  @media only screen and (max-width: 900px) {
    position: unset;
  }

  @media only screen and (max-width: 768px) {
    display: block;
  }
}

.footer-link {
  @include textAlignCenter;
  max-width: 160;
  color: var(--bosch-black);
  cursor: pointer;
}

.login-footer {
  background-color: var(--bosch-white);
  display: flex;
  align-items: flex-end;
  width: 100%;
  box-shadow: $--bosch-black-background-04 0 0.07rem 0.75rem 0.14rem;
  gap: 3rem;
  padding: 1.1rem 5rem 1.1rem;
  font-family: $bosch-font-family-general;
  justify-content: center;

  @media only screen and (max-width: 900px) {
    padding: 1.5rem;
  }

  @media only screen and (max-width: 768px) {
    display: block;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__container {
    display: inline-flex;
    gap: 2rem;
    cursor: pointer;

    @media only screen and (max-width: 768px) {
      flex-direction: column;
      gap: unset;
    }
  }
}

.entity-width {
  width: 40%;
}
