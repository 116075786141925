@import '../../../stylesheet/variables.scss';

.switch__market__link {
  display: flex;
  position: relative;
  bottom: 6rem;
  gap: 1rem;
  align-items: center;
  padding: 1rem;
}

.nav_alignment {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - $header-ht - $footer-ht) !important;
  margin: 5.5rem 0 0 0 !important;
  padding: unset !important;

  @media only screen and (max-width: 768px) {
    height: calc(100% - $header-ht) !important;
    margin-top: 5.5rem !important;
  }
}

.nav_alignment .-open {
  height: auto !important;
}

.no-rotate {
  transform: unset !important;
}

.m-side-navigation {
  &-open {
    @media only screen and (max-width: 768px) {
      display: none;
    }
  }
}

.api_side_nav_onboarding {
  display: none;

  @media only screen and (max-width: 768px) {
    display: block;
  }
}

@media only screen and (max-width: 768px) {
  .side-navigation-hide {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .side-navigation-open {
    width: 100% !important;
    display: block !important;
  }
}

.side-navigation-disabled {
  opacity: 0.2;
  pointer-events: none;
}

.sub-menu-btn {
  height: unset;
  padding-right: 1rem;
}
