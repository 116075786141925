@import '../../../stylesheet/mixins.scss';
@import '../../../stylesheet/variables.scss';

.scroll-to-top {
  position: fixed;
  bottom: 5.75rem;
  @include flexCenter();
  z-index: 1000;
  cursor: pointer;
  animation: fadeIn 0.3s;
  transition: opacity 0.4s;
  opacity: 0.5;
  text-align: center;
  background-color: $--bosch-blue-68;
  color: $--bosch-white !important;
  border-radius: 1.25rem;
  padding: 0.5rem;
  height: 2rem;
  width: 2rem;
  right: 3rem;

  @media only screen and (max-width: 768px) {
    bottom: 2rem;
  }
}

.scroll-to-top:hover {
  opacity: 1;
  padding: 0.5rem;
  height: 2rem;
  width: 2rem;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}

.scroll-alignment {
  padding-left: 1rem;
}
