@import '../../../../../variables.module.scss';
@import '../../../../../stylesheet/mixins.scss';

.plan-card {
  background: var(--bosch-white);
  border-radius: 0 !important;
  height: 27.25rem;
  padding-bottom: 0.1rem;
  width: 19rem;
  box-shadow: none !important;
  border: 0.0625rem solid var(--bosch-gray-70);

  &-name {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 15rem;
  }
}

.background-custom {
  background: var(--primary-color) !important;
  color: var(--bosch-white);
  text-align: center;
}

.plan-card-header {
  height: 30%;
  justify-content: center;
  @include flexAlignCenter;
  flex-direction: column;

  &-customtooltip:hover:before {
    left: 5% !important;
  }
}

.list-style {
  padding: 0.05rem 0.4rem 0.05rem 0.9rem !important;
  padding: 0 0.5rem !important;
  display: flex;
}

.list-item-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 15rem;
  padding-left: 0 !important;
  padding-right: 0 !important;
  color: var(--bosch-black);
  font-family: $bosch-font-family-light;
  font-size: 0.95rem !important;
}

.list-item-icon-style {
  min-width: 2rem !important;
}

.list-item-icon {
  width: 1.5rem;
  height: 1.5rem;
}

.button-box-style {
  height: 14%;
}

.bold-style {
  font-weight: bold;
  font-size: 1.2rem;
}

.check-icon-style {
  color: var(--primary-color);
}

.list-content {
  min-height: 35%;
}

.checklist__item img {
  @include Width_height_15;
}

.checklist__item {
  padding: 1rem 0;
}

.pricing__view__button {
  @include textAlignCenter;

  .align__center__button {
    @include flexCenter;
    gap: 0.5rem;

    .view_details {
      color: $primary_color;
    }
  }
}

.marketplace-button {
  width: 80%;
  text-transform: uppercase;
}

.mt__1 {
  margin-top: 1rem;
}
