@import '../../../stylesheet/variables.scss';

.preview-modal {
  &__img {
    width: 100%;
  }

  &__video {
    width: 100%;
  }

  &__height {
    height: 100%;
    max-height: calc(100vh - 4rem);
    overflow-y: unset;
    width: 75%;
    max-width: 100%;

    @media only screen and (max-width: 768px) {
      height: auto;
      width: 100%;
    }
  }

  &__header {
    background-color: $--bosch-white !important;
  }

  &__body {
    overflow-y: auto;
  }

  &__close {
    color: unset !important;
    top: 0.25rem;
    right: 0.5rem;
  }
}
