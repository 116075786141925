.list-category {
  display: flex;
  justify-content: space-between;
  max-width: 97.625rem;
  padding-left: 2rem;
  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
    overflow: auto;
    padding: 0;
  }
  &__left {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 2rem;
    column-gap: 1rem;
    overflow: auto;
    width: auto;
    padding: 1rem 1rem 7rem 0;
    max-width: calc(14.2rem * 3 + (1rem * 2));
    max-height: calc(100vh - 4rem);
  }
  &__header {
    padding: 0.5rem 2rem;
  }
  &__footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    max-height: 5rem;
  }

  &__custom-radio-long {
    position: relative;
    width: max-content;
    max-width: 13.25rem;
    max-height: 15.5rem;
    &__image {
      max-width: calc(100% - 2rem);
      height: auto;
      max-height: 75%;
    }
    &__bottom-text {
      position: absolute;
      width: 100%;
      bottom: 2rem;
      left: 0;
      padding: 0 0.5rem;
      display: flex;
      justify-content: center;
    }
    .custom-radio_button {
      margin: 0;
      &__label:before {
        height: 2rem;
        width: 2rem;
      }
      &__label {
        height: 14.5625rem;
        width: 13.375rem;
      }
    }
  }

  &__category-thumb-img {
    position: absolute;
    top: 46%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 8rem;
    width: 8rem;
    border-radius: 50%;
  }

  &__right {
    @media screen and (max-width: 768px) {
      justify-content: center;
      display: flex;
    }
    &__image {
      max-width: calc(100% - 2rem);
      height: auto;
      margin-top: -2rem;
      max-height: 36rem;
      @media screen and (max-width: 768px) {
        width: 100%;
        margin-top: 0;
      }
    }
  }
}
