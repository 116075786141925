@import '../../variables.module.scss';
@import '../../stylesheet/variables.scss';
@import '../../stylesheet/mixins.scss';

.bg-grey-profile {
  border: 0.07rem solid $--bosch-gray-10;

  @media screen and (max-width: 768px) {
    margin-bottom: 1rem;
  }
}

.profile-heading-size {
  font-size: 1.7rem !important;
}

.profile-styling {
  padding-left: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.status-checked {
  color: $--bosch-green-37 !important;
  margin-left: 0.5rem;
}

.user-heading {
  color: var(--bosch-black);
  @include flexAlignCenter;
}

.profile-heading {
  color: var(--bosch-black);
  padding-left: 1rem;
  background-color: $--bosch--gray-56;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.expansion-display-type {
  padding-bottom: 0.5rem;
}
.request-account-deletion .dialog_modal__content {
  height: 18.5rem;
}
.label-text {
  margin-left: 1rem;
}

.mail-text {
  @include flexAlignCenter;
}

.number-text {
  @include flexAlignCenter;
}

.bottom-space {
  margin: 0;
}

.background-color-pending-status {
  @include flexAlignCenter;
  background-color: $--bosch-yellow-79;
  color: var(--bosch-black);
  border: 0.07rem solid var(--bosch-yellow-75);
  margin-bottom: 1rem;
}

.background-color-approved-status {
  height: 1.56rem;
  margin: 0 0 -0.31rem 0.65rem;
}

.background-color-rejected-status {
  @include flexAlignCenter;
  background-color: var(--bosch-red-76);
  color: var(--bosch-white);
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.user-details {
  @include flexAlignCenter;
  flex-direction: row;
  margin-top: 0.4rem;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: baseline;
  }
}
.sales-rep-margin {
  margin-top: 0.4rem;
}
.company-details {
  @include flex_row;
  width: 100%;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.icon-size {
  font-size: 2rem !important;
}

.document-heading {
  @include flexSpaceBetween;
  margin-top: 1.87rem;
}

.document-section {
  display: flex;
  color: $--bosch-blue-88;
}

.document-details {
  margin-left: 0.63rem;
  cursor: pointer;
}

.item {
  flex-basis: 50%;
}

.profile-account-deletion {
  @include flexAlignCenter;
  color: var(--bosch-blue-50);
  cursor: pointer;
  justify-content: flex-end;
  margin: 2rem 0;

  @media screen and (max-width: 768px) {
    display: block;
    margin: auto;
  }
}

.profile-account-request {
  padding: 1rem !important;
  color: var(--bosch-blue-50);
  background-color: unset;
  @include flexAlignCenter;

  @media screen and (max-width: 768px) {
    padding: 0 1rem !important;
  }

  span {
    text-decoration: underline;
    margin-left: 0.5rem;
  }
}

.warning-icon {
  padding: 1rem;
}

.address-display {
  margin-right: 10px;
}
.dialog {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  color: '#000';
}
.dialog.popupOpen {
  display: flex;
}
.dialog-content {
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  width: 53%;
  .close-button-wrapper {
    text-align: right;
  }
}
.content-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #007bc0;
  .left-part {
    width: 40%;
    background-color: #fff;
    color: #000;
    clip-path: polygon(0% 0%, 100% 0%, 95% 100%, 0% 100%);
    padding: 2.5rem;
    font-weight: bold;
    .image-container {
      background-color: #007bc0;
      border-radius: 50%;
      width: 6rem;
      height: 6rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      margin-bottom: 1rem;
    }
    p {
      text-align: center;
      font-weight: bold;
    }
  }
  .right-part {
    width: 60%;
    background-color: #007bc0;
    color: #fff;
    padding: 0rem 2rem 2rem 2rem;
    text-align: center;
    font-weight: bold;
    .delete-singlekeyid-button {
      background-color: #007bc0;
      color: #fff;
      border: 1px solid #fff;
      padding: 0.4rem;
      margin-top: 0.5rem;
      cursor: pointer;
    }
    .close-button-wrapper {
      text-align: right;
      position: relative;
      bottom: 2rem;
      left: 1rem;
      .popup-close-button {
        background-color: transparent !important;
        color: #fff;
        border: none;
        cursor: pointer;
      }
    }
  }
}
