* {
  box-sizing: border-box;
}

.dropdown-wrapper h1 {
  margin-top: 100px;
  text-align: center;
}

.dropdown-wrapper .dropdown {
  margin: 0 auto;
  width: 280px;
  border: 1px solid #cedfea;
}

.dropdown-wrapper .dropdown-text {
  color: black;
  padding: 10px 16px;
  cursor: pointer;
  height: auto;
}

.dropdown-wrapper .dropdown-text:hover {
  color: black;
}

.dropdown-wrapper .dropdown-text:after {
  content: '';
  transition: all 0.3s;
  border: solid #ccc;
  border-width: 0 1px 1px 0;
  float: right;
  margin-top: 1px;
  margin-right: 6px;
  padding: 5px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.dropdown-wrapper .dropdown.active .dropdown-text:after {
  margin-top: 8px;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.dropdown-wrapper .dropdown-items {
  position: relative;
  visibility: hidden;
  opacity: 0;
  max-height: 0px;
  transition: max-height 0.6s ease;
}

.dropdown-wrapper .dropdown.active .dropdown-items {
  visibility: visible;
  border-top: 1px solid #ccc;
  height: auto;
  max-height: 290px;
  opacity: 1;
  transition:
    max-height 0.5s,
    opacity 1s,
    visibility 2s ease;
}

.dropdown-wrapper .dropdown-item {
  cursor: pointer;
  padding: 10px 18px;
}

.dropdown-wrapper .dropdown-item:not(:last-child) {
  border-bottom: 1px solid #ccc;
}

.dropdown-wrapper .dropdown-item:hover {
  background: #005691;
  color: white;
}
