@import '../../../../stylesheet/variables.scss';

.product-details {
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
  flex-wrap: wrap;
  min-width: 21.875rem;

  @media screen and (max-width: 768px) {
    width: 100%;
    flex-direction: column;
  }

  &__specs {
    min-width: 18.5rem;
    display: flex;

    @media screen and (max-width: 768px) {
      width: 100%;
      flex-direction: column;
    }

    &__image {
      margin-right: 2rem;

      @media screen and (max-width: 768px) {
        margin-bottom: 1.25rem;
      }
    }

    &__details {
      @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
      }

      &__name {
        min-width: 11rem;
      }

      &__sku {
        color: $--bosch--light-grey;
      }

      &__category {
        margin-top: 0.4rem;

        @media screen and (max-width: 768px) {
          margin-top: 0;
          flex-direction: row-reverse;
          padding: 0.5rem 0;
        }
      }
    }
  }

  &__seller {
    max-width: 11.25rem;
  }

  &__quantity {
    display: inline-block;
    width: auto;
    max-width: 5rem;
  }

  &__order-date {
    max-width: 15rem;
  }

  &__price {
    display: inline-block;
    width: auto;
    font-weight: 900;
  }
}
