@import '../../stylesheet/mixins.scss';
@import '../../stylesheet/variables.scss';

.automotive-field {
  @include flex_col;

  &_items {
    width: 100%;
    @include flexCustom(space-between, center);

    &_buttons {
      @include flexCustom(space-between, center);

      &_product {
        padding-right: 0rem;
        padding-left: 1.5rem;
      }
    }
  }

  &_automative_option {
    right: calc(0.01% - 0.1rem) !important;
    max-width: 13rem !important;
  }

  &__tooltip-add-product {
    max-width: 18rem;
    padding: 0.7rem;
  }
}

.context-menu {
  &__box {
    width: 3rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border: 0.0625rem solid $--bosch-blue-50;
  }
}

.m-popover {
  &__left {
    right: calc(100% - 3rem);
    height: auto;
    max-height: 12.2rem;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 1;
    box-shadow: 0rem 0 1rem $--bosch-shuttle-gray;
  }
}
