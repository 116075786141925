@import '../../stylesheet/mixins.scss';
@import '../../stylesheet/variables.scss';

.first-hardware {
  width: 100%;

  &_content {
    @include flex_col;
    align-items: center;

    &_text-adjustment {
      text-align: center;
      margin: 1rem;
      margin-top: 1.5rem;
    }

    &_button {
      margin-top: 1rem;
      text-align: center;
    }

    &_element {
      width: 22rem;
    }

    &_sales-rep-element {
      width: 15rem;
    }
  }

  @media only screen and (max-width: 768px) {
    padding: 1rem;
    margin: unset !important;
  }
}

.hardware-field {
  @include flex_col;
  overflow-x: hidden;
  overflow-y: hidden;

  .searchbar-icon {
    display: none;
  }

  @media only screen and (max-width: 768px) {
    padding: 0;
    height: 100%;

    &__add-btn {
      .a-button__label {
        padding: 0 !important;
        font-size: 2rem;
        width: 3rem;
      }
    }

    .searchbar-hide {
      display: none;
    }

    .searchbar-show {
      position: absolute;
      left: 0%;
      top: 10rem;
      z-index: 9;
      width: 100%;
      padding: 1rem 1rem 0 1rem;

      .product__search {
        width: 100%;
      }
    }

    .searchbar-icon {
      display: block;
    }
  }

  &_items {
    width: 100%;
    @include flexCustom(space-between, center);
    margin-top: 2rem;

    @media only screen and (max-width: 768px) {
      margin-left: 0;
      padding: 0 1rem;
    }

    &_buttons {
      @include flexCustom(space-between, center);

      &_search {
        padding-right: 1rem;
      }

      &_product {
        @media only screen and (max-width: 768px) {
          padding-right: 0rem;
        }

        padding-right: 3rem;
        padding-left: 1rem;
      }
    }
  }

  &_cards {
    width: 100%;
    margin: 1rem;
    margin-left: unset;

    @media only screen and (max-width: 768px) {
      margin-bottom: unset !important;
      @include flex_col(space-between);
      height: 100%;
    }

    &_space {
      width: 100%;
    }

    &_scroll {
      overflow-x: hidden;
      overflow-y: auto !important;

      &_seller-height {
        max-height: calc(100vh - 165px - $footer-ht);
      }

      &_sales-rep-height {
        max-height: calc(100vh - 280px - $footer-ht);
      }

      @media only screen and (max-width: 768px) {
        max-height: calc(100vh - 85px - $footer-ht);
      }
    }
  }

  &_searchbar_open {
    @media only screen and (max-width: 768px) {
      margin-bottom: unset !important;
      @include flex_col(space-between);
      height: 100%;
      margin-top: 4.5rem;
    }
  }

  &_line-border {
    border: 1px solid $--bosch-white-20;
    width: 100%;
  }
  &__tooltip-add-product {
    max-width: 18rem;
    padding: 0.7rem;
  }

  &__add-product-tooltip-wrapper {
    &:hover:before {
      bottom: auto !important;
      top: 3rem;
      left: 0 !important;
    }
  }

  &__category-name-tooltip {
    max-width: 24rem;
  }

  &__category-name-tooltip-wrapper {
    &:hover:before {
      bottom: auto !important;
      top: 2rem;
      left: 0 !important;
    }
  }
}
