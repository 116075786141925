@import '../../variables.module.scss';
@import '../../stylesheet/variables.scss';
@import '../../stylesheet/mixins.scss';

.pending-field {
  @include flex_col;
  padding: 1rem;
  margin-top: 1rem;

  &_adjustment {
    margin-left: 1rem;
  }
}

.username-block {
  cursor: pointer;

  @media only screen and (max-width: 768px) {
    @include flexAlignCenter;
    position: relative;
    gap: 0.5rem;
  }

  .navigation-icon-wrapper {
    @media only screen and (min-width: 768px) {
      display: none;
    }
  }
}

.info-style {
  font-size: 1.8rem !important;
  margin-right: 0.4rem;
}

.user-account-usernames {
  color: var(--bosch-black);
  text-align: left;
  @include text-ellipsis;
  margin-bottom: 0;
  width: 18.75rem;
}

.user-account-email {
  @include text-ellipsis;
  max-width: 15.45rem;
}

.user-account-description {
  color: var(--bosch-black);
  @include text-ellipsis;
  width: 21.9rem;
  padding-right: 1.25rem;
}

.user-account-dropdown-option {
  color: var(--bosch-black);
  cursor: pointer;
  padding: 0.5rem 1rem;
}

.user-account-dropdown-option:hover {
  color: $--bosch--blue-53;
}

.user-account-signout-button {
  background: none;
  color: var(--bosch-black);
  cursor: pointer;
  margin-top: 0.5rem;
  width: 100%;
  @include flexCustom(center, center);
}

.company-registration-status-box {
  background-color: var(--bosch-yellow-95);
  width: auto;
  margin-bottom: 0.45rem;
}

.pending-approval-text {
  padding: 1rem;
  margin-top: 1rem;
}

.status-value {
  padding-bottom: 0.5rem;
  display: flex;
  align-items: center;
}

.company-status-styling-module {
  background-color: var(--bosch-yellow-95);
}

.view-profile-icon {
  max-width: 2.75rem;
  margin-left: 0.25rem;
}

.popover-styles {
  min-height: 21.7rem !important;
}

.pending {
  position: absolute;
  background-color: var(--bosch-white);
  box-shadow:
    rgba(0, 0, 0, 0.2) 0px 5px 5px -3px,
    rgba(0, 0, 0, 0.14) 0px 8px 10px 1px,
    rgba(0, 0, 0, 0.12) 0px 3px 14px 2px;
  right: 1.3rem;
  top: 4.2rem;
  width: 23rem;

  @media only screen and (max-width: 768px) {
    width: auto;
  }
}

.snackbar {
  position: fixed;
  top: -3rem;
  left: 50%;
  transform: translateX(-50%);
  @include flexCustom(center, center);
  width: 90%;
  max-width: 37.5rem;
  height: 3rem;
  border-radius: 0.25rem;
  @include textAlignCenter;
  transition: top 0.2s ease-out;
  z-index: 9999;
}

.snackbar.success {
  background-color: $--bosch-green-37;
  color: var(--bosch-white);
}

.snackbar.warning {
  background-color: var(--bosch-yellow-75);
  color: var(--bosch-black);
}

.snackbar.error {
  background-color: var(--bosch-red-45);
  color: var(--bosch-white);
}

.snackbar.open {
  top: 0.75rem;
}

.logout-icon {
  padding-left: 0.45rem;
}

.profile-logout {
  @include flexCenter;
}

.register-button {
  cursor: pointer;
  width: 100%;
  @include flexCustom(center, center);
}

.user-account-details {
  padding: 1rem;
  padding-bottom: 0;
}
