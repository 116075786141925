@import '../../../stylesheet//variables.scss';
@import '../../../stylesheet//mixins.scss';

.dashboard-container-box {
  padding: 25px;
}

.dashboard-box {
  margin: 5px 0 20px;
}

.dashboard-box-title {
  font-size: 18px;
  text-align: left;
  font-weight: bold;
  color: $--bosch-black;
}

.dashboard-user-info-box {
  background-color: white;
  display: flex;
  justify-content: space-between;
  padding: 15px;
}

.dashboard-box-user-details {
  display: flex;
}

.welcome-text-font-size {
  font-size: 15px;
  font-weight: bold;
}

.bold-text {
  font-weight: bold;
}

.a-icon-size {
  font-size: 15px;
  margin-right: 5px;
}

.user-icon {
  font-size: 40px !important;
  padding-right: 15px;
}

.select-field-box {
  display: flex;
  align-items: center;
}

.select-drop-down-box {
  margin-right: 18px;
  width: 30rem;
}

.seller-list-box {
  margin: 4rem 0 20px;
}

.table-styling {
  display: flex;
  justify-content: center;
  align-items: center;
}

.field-center-align {
  @include textAlignCenter;
}

.header-style {
  padding: 10px;
}

.sub-text-sales-rep {
  display: flex;
  p {
    margin-top: -2px;
  }
}
.dashboard-table-header {
  &__table_container_style_head {
    background-color: #f2f2f2;
  }
  &__table_container_style_body {
    background-color: #ffffff;
  }
  &__table_container_style_row {
    td {
      word-wrap: break-word;
      white-space: normal;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &__seller_table {
    max-width: 100%;
  }
}
