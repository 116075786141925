.order-status {
  &__indicator {
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }

  @media screen and (max-width: 600px) {
    width: auto;
    overflow: auto;
  }
}

.mobile-indicator {
  @media screen and (max-width: 600px) {
    width: 100%;
    overflow-x: scroll;
  }
}

.mobile-indicator::-webkit-scrollbar {
  width: 0.5em;
}

.mobile-indicator::-webkit-scrollbar-track {
  background-color: transparent;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;
}

.mobile-indicator::-webkit-scrollbar-thumb,
.mobile-indicator::-webkit-scrollbar-thumb:hover {
  background-color: transparent;
}

.order-status-node {
  @media screen and (max-width: 600px) {
    width: max-content;
  }
}
