@import '../../../../../variables.module.scss';

.text-area-style {
  width: 100% !important;
  margin-top: 1rem !important;
  min-height: 15.875rem !important;
  max-height: 25rem !important;
  resize: vertical !important;
  padding-left: 0.375rem;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  overflow-y: auto !important;
  font-family: $bosch-font-family-general;
  background-color: var(--neutral__enabled__fill__default) !important;
}

.text-area-style::placeholder {
  font-family: $bosch-font-family-light;
}

.text-area-style:focus-visible {
  outline: none !important;
}

.text-area-disabled {
  color: var(--neutral__enabled__front__default) !important;
  font-family: $bosch-font-family-general;
}

.sample-title {
  margin-top: 16px !important;
  margin-bottom: 8px !important;
  font-family: $bosch-font-family-medium;
}

.sample-title-preview {
  margin-bottom: 8px !important;
  font-family: $bosch-font-family-bold;
  font-size: 0.95rem !important;
}

.no-bold-sample-title-preview {
  margin-bottom: 8px !important;
  font-family: $bosch-font-family-medium;
  font-size: 0.95rem !important;
}

.bold-title {
  font-weight: bold !important;
}

.samples-tab-panel {
  font-family: $bosch-font-family-medium;
  text-transform: capitalize !important;
}

.select-container {
  display: flex !important;
  justify-content: flex-end !important;

  @media only screen and (max-width: 768px) {
    justify-content: flex-start !important;
  }
}

.select-container-view {
  float: right;
  z-index: 1;
}

.content-type-container {
  float: right !important;
}

.response-select-container {
  float: left !important;
  margin-top: 16px !important;
  margin-bottom: 24px !important;
}

.select-label {
  z-index: 1 !important;
  margin-top: 6px;
  margin-left: 12px;
  color: var(--neutral__enabled__front__default) !important;
  font-family: $bosch-font-family-medium;
}

.content-type-select-field {
  width: 250px;
  text-overflow: ellipsis;
  border-radius: 0 !important;
  height: 3rem !important;
  background-color: var(--neutral__enabled__fill__default);
  font-family: $bosch-font-family-general;
  font-size: 0.9rem !important;
  margin-top: 0 !important;
}

.content-type-select-field-disabled {
  -webkit-text-fill-color: rgba(0, 0, 0, 0.7) !important;
}

.content-type-select-field-select {
  padding-left: 12px !important;
  padding-top: 24px !important;
}

.content-type-select-field-icon {
  width: 1em !important;
  height: 1em !important;
  display: inline-block !important;
  font-size: 1.5rem !important;
  position: absolute !important;
  right: 7px !important;
  top: calc(50% - 0.5em) !important;
  pointer-events: none !important;
}

.content-type-select-field-root {
  background-color: var(--neutral__enabled__fill__default) !important;
}

.content-type-select-field:before {
  border: none !important;
  content: none !important;
}

.content-type-select-field:after {
  border: none !important;
}

.content-type-select-field::placeholder {
  font-family: $bosch-font-family-light;
}

.response-code-text-field {
  :global(.MuiFilledInput-root) {
    background-color: var(--neutral__enabled__fill__default) !important;
    border-radius: 0 !important;
    font-family: $bosch-font-family-general;
  }
  :global(.MuiFilledInput-input) {
    padding-top: 24px !important;
  }
  :global(.MuiFormHelperText-root) {
    margin-left: 0 !important;
    margin-bottom: 8px !important;
  }
}

.response-code-label {
  color: var(--neutral__enabled__front__default) !important;
  font-family: $bosch-font-family-medium;
}

.generate-button-schema {
  text-transform: capitalize;
  border-radius: 0;
  padding: 1px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.error-text {
  font-family: $bosch-font-family-light;
  margin-top: 2px !important;
  color: red !important;
}

.success-text {
  font-family: $bosch-font-family-light;
  margin-top: 2px !important;
  color: green !important;
}

.error-text-icon {
  font-size: 16px !important;
  color: red !important;
  font-weight: bolder !important;
  vertical-align: middle !important;
}

.success-text-icon {
  font-size: 16px !important;
  color: green !important;
  font-weight: bolder !important;
  vertical-align: middle !important;
}

.info-icon-cursor-styling {
  cursor: pointer;
  width: 18px;
  margin-left: 14px;
  margin-top: 13px;
  margin-bottom: -3px;
}
.format-button {
  border: none;
  padding: 8px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-right: 4px;
  cursor: pointer;
  background-color: var(--bosch-white);
  font-weight: bold;

  @media only screen and (max-width: 768px) {
    padding: 3px !important;
  }
}
