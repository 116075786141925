@import '../../../stylesheet/variables.scss';
@import '../../../stylesheet/mixins.scss';

.quotations {
  background: $--bosch-concrete-solid;
  padding: 1rem;
  margin-bottom: 4rem;

  &__heading {
    margin: 1rem 0;
  }

  &__wrapper {
    background: $--bosch-white;
    border: 0.06rem solid $--bosch-gray-71;
  }

  &__warning {
    display: flex;
    justify-content: center;
    background: $--bosch-yellow-85;
    height: 4rem;
    align-items: center;

    &__icon {
      font-size: 3rem !important;
    }

    &__expiry {
      text-align: left;
      margin-left: 1rem;
    }
  }

  &__details {
    @include flexSpaceBetween();
    padding: 1rem;
  }
}
