@import '../variables.scss';

@media screen and (max-width: 900px) and (min-width: 768px) {
  .m-side-navigation.-open,
  .m-side-navigation.-opening {
    width: 0;
  }
}

.m-side-navigation {
  width: 20rem !important;
  overflow: auto;
  background: white;
  padding: 7rem 0 4rem 0;

  @media only screen and (max-width: 768px) {
    width: 100% !important;
    padding: unset !important;
  }
}

.m-side-navigation__group.--nopadding {
  padding: 0.25rem !important;
  align-items: center !important;

  @media only screen and (max-width: 768px) {
    padding-right: 1.5rem !important;
  }
}

.m-side-navigation__menuItem.-sub-menu {
  padding-left: 2rem !important;
}

.m-side-navigation__menuSubitems.-sub-menu {
  padding-left: 2rem !important;
}

.m-side-navigation__menuSubitem.-sub-menu {
  padding-left: 1.25rem !important;
}
