@import '../../../variables.module.scss';

.background-smoke {
  background: #ffffff;
  min-height: 8rem;
  padding: 1rem;
}
.background-smoke:hover {
  background: lightgrey;
}
.outer-box {
  margin: 1rem;
  padding: 1rem;
  cursor: pointer;
}
.heading {
  font-size: 2.7rem;
  color: white;
}
.sub-heading {
  color: white;
}
.img-icon {
  width: 70% !important;
}
.box-heading-manual {
  font-size: 1.5rem;
  color: #7fbe78;
}
.box-heading-swagger {
  font-size: 1.5rem;
  color: #02abcc;
}
.icon {
  color: #a3a3a3;
  cursor: pointer;
}

.link-style {
  text-decoration: none;
  color: #a3a3a3;
}

.close-icon {
  color: white;
  cursor: pointer;
  background-color: var(--bosch-black);
  border-radius: 50%;
}

.add-api-img-style {
  width: 51%;
  margin-top: 4rem;
}

.first-api-style {
  width: 90%;
  align-self: center;
}

.first-api-heading {
  color: #005691;
}

.drop-zone-box {
  border: 1px dashed #e8e8e8;
  text-align: center;
}

.drop-zone-label {
  color: #e8e8e8;
}

.drop-zone-msg {
  color: #e8e8e8;
  text-align: center;
  font-size: 0.75rem;
}

.drop-zone-display {
  display: none;
}

.drop-zone-block {
  display: block;
}

.modal-div {
  position: absolute;
  background-color: white;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1rem;
  font-family: $bosch-font-family-general;
  width: 20rem;
  text-align: center;
}

.error-label {
  font-size: 2rem;
  color: black;
}

.error-symbol {
  color: red;
  font-size: 2rem !important;
}

.cursor-pointer {
  cursor: pointer;
}

.button-box {
  text-align: end;
}

.api-selected {
  background: white;
  border-radius: 1rem;
  width: 8rem;
  height: 9rem;
}

.api-icon {
  margin-top: -10px;
}
.checked {
  background: #70bf54;
  border-radius: 20px;
  width: 21px;
}

.check-icon {
  color: white;
  font-size: 16px !important;
  padding-top: 3px;
  padding-left: 2px;
  margin-left: 1.5px;
}

.api-title {
  margin-top: 1rem;
  padding-bottom: 6px;
  font-weight: 900;
  color: white;
}

.api-title-selected {
  margin-top: 5px;
  padding-bottom: 6px;
  font-weight: 900;
}

.arrow-back {
  margin-right: 1rem;
}

.spacing {
  margin-top: 1.5rem;
}

.api-disabled {
  opacity: 0.5;
  margin-top: 0.5rem;
}

.subtitle {
  color: white;
  margin-bottom: 5rem;
}

.breadcrumb-styling {
  text-decoration: none;
  color: #005691;
  margin: 0.5rem;
  font-family: $bosch-font-family-general;
  font-size: 0.9rem;
  font-weight: 400;
}

.list-style {
  font-family: $bosch-font-family-general;
  font-size: 0.8rem;
}

.api-list-view-box {
  margin: '25px 10px';
}

.myapi-container-grid {
  margin-top: 1rem !important;
}

.myapi-list-spacing {
  padding: 0 !important;
}

.no-result-section {
  margin-left: 200px !important;
}
