@import '../../../stylesheet/mixins.scss';
@import '../../../stylesheet/variables.scss';

.sidebar-content {
  @include flex_col;
  padding: 0 2rem 0 1rem;
  background-color: $--bosch-concrete-solid;
  height: calc(100vh - $header-ht - $footer-ht);

  @media only screen and (max-width: 768px) {
    padding: unset;
    height: calc(100vh - $header-ht);
    @include flexSpaceBetween;
  }
}
