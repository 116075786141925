@import '../../../../../variables.module.scss';

.modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: var(--shadow-fill);

  &__dialog__body {
    padding: 1rem 2rem 4rem;
  }

  &__information__height {
    height: 22rem !important;

    @media only screen and (max-width: 768px) {
      height: fit-content !important;
      max-height: 50rem;
    }
  }
}

.modal__open {
  display: block;
}

.modal__close {
  display: none;
}
