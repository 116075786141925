@import '../../../variables.module.scss';

.rich-text-editor-options {
  background: #efeff0;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  margin-top: 10px !important;
  margin-bottom: -10px !important;
}

.rich-text-editor-root {
  background: #efeff0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  font-family: $bosch-font-family-medium;
  font-size: 14px;
  padding-left: 13px;
  padding-right: 13px;
  overflow: auto;
  max-height: 200px;
  height: 200px;
  overflow-x: hidden;
}

.rich-text-editor-root-disabled {
  color: rgba(0, 0, 0, 0.38) !important;
  border-bottom: 1px dotted rgba(0, 0, 0, 0.42) !important;
}

.rich-text-editor-textarea {
  height: 50px !important;
  cursor: text;
  font-size: 1rem !important;
  margin-top: 10px;
}

.rich-text-editor-textarea-disabled {
  height: 50px !important;
  cursor: default !important;
  font-size: 1rem !important;
}

:global(.public-DraftStyleDefault-orderedListItem) {
  list-style-type: decimal !important;
  padding-left: 0 !important;
}

:global(.public-DraftStyleDefault-unorderedListItem) {
  padding-left: 0 !important;
  white-space: normal;
}

:global(.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root) {
  display: none !important;
}

:global(.public-DraftEditorPlaceholder-root) {
  color: #9197a3;
  width: 100%;
  z-index: 1;
  font-size: small !important;
  font-family: $bosch-font-family-general;
}

.rich-text-editor-controls {
  font-family: $bosch-font-family-medium;
  font-size: 14px;
  user-select: none;
  display: flex !important;
  border-bottom: 1px solid lightgray;
}

.rich-text-editor-style-button {
  color: black;
  cursor: pointer;
  margin-right: 5px;
  padding: 1px 1px;
  margin-top: 10px;
  margin-bottom: 10px;

  @media only screen and (max-width: 768px) {
    margin-right: 14px;
  }
}

.rich-text-editor-style-button-disabled {
  color: rgba(0, 0, 0, 0.38) !important;
  cursor: default !important;
  pointer-events: none !important;
}

.rich-text-editor-url-button {
  cursor: pointer;
  margin-right: 5px;
  padding: 1px 1px;
  margin-top: 9px;
}

.rich-text-editor-url-button-disabled {
  cursor: default !important;
  color: rgba(0, 0, 0, 0.38) !important;
  pointer-events: none !important;
}

.rich-text-editor-active-button {
  background-color: darkgray;
}

.rich-text-editor-style-icon {
  font-size: 1rem !important;
  margin-bottom: -3px;
}

.header-style {
  margin-top: 0px !important;
  font-weight: normal !important;
}

.select-style {
  margin: 0px !important;
  background-color: #efeff0 !important;
  border-radius: 0 !important;
  color: black !important;
  text-transform: capitalize !important;
}

.select-style-disabled {
  color: rgba(0, 0, 0, 0.26) !important;
}

.modal-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  border: 1px solid #000;
  background-color: white;
  box-shadow: 24;

  @media only screen and (max-width: 768px) {
    width: 95%;
  }
}

.confirm-button {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
  box-shadow: none !important;
  border-radius: 0px !important;
  text-transform: capitalize !important;
  font-size: 1em !important;
  font-family: $bosch-font-family-general;
  color: white !important;
  background-color: #007bc0 !important;
  border: 1px solid #007bc0 !important;
  padding-left: 18px !important;
  padding-right: 18px !important;
}

.cancel-button {
  margin-left: 15px !important;
  background-color: white !important;
  color: #007bc0 !important;
}

.confirm-button-disabled {
  opacity: 0.4 !important;
}

.buttons-display {
  display: flex !important;
  justify-content: flex-end !important;
  margin-top: 27px !important;
}

.helper-text {
  font-size: 0.8rem !important;
  font-family: $bosch-font-family-medium;
  color: red !important;
}

.header-style-menu {
  margin: 0 !important;
  font-weight: normal !important;
}

.header-one {
  font-size: 24px;
}

.header-two {
  font-size: 20px !important;
}

.header-three {
  font-size: 16px !important;
}

.header-four {
  font-size: 14px !important;
}

.header-five {
  font-size: 12px !important;
}

.header-six {
  font-size: 10px !important;
}

.tooltip-style {
  margin-top: -15px !important;
}

.modal-text-field-label {
  padding-left: 15px !important;
  padding-top: 5px !important;
  z-index: 1 !important;
  color: black !important;
  font-size: 14px !important;
  font-family: $bosch-font-family-general;
}

.size-of-text {
  font-size: 1rem !important;
  padding-left: 0.8rem !important;
  height: 35px !important;
  margin-top: 10px !important;
}

:global(.rich-text-field-input) {
  background-color: #efeff0 !important;
  width: 100% !important;
  margin-top: 20px !important;
}

.root-input {
  margin-top: 0 !important;
}

.modal-label {
  font-size: 21px !important;
}

.link-image {
  width: 20px;
  height: 15px;
}

.link-image-disabled {
  opacity: 0.5 !important;
}

.popup-image {
  width: 100%;
  display: block;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  object-fit: cover;
}

.close-icon-style {
  position: absolute !important;
  top: 0rem !important;
  right: 0rem !important;
}

.text-style-modal {
  padding: 25px;
  padding-top: 9px;
  padding-bottom: 3px;
}

.unstyled-block-word-break {
  word-break: break-word;
}
