@import '../../../../../variables.module.scss';

.typo-style {
  float: right;
}
.modal-contentstyle {
  font-family: $bosch-font-family-general;
  background-color: #fbfbfb;
  overflow: auto;
  max-height: 28rem;
  padding: 20px;
}
.agreement-div-style {
  position: absolute;
  background-color: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2rem;
  font-family: $bosch-font-family-general;
  width: 70rem !important;
  max-height: 40rem !important;
}

@media only screen and (max-width: 1440px) {
  .agreement-div-style {
    width: 98% !important;
  }
  .width-document {
    width: 1500px !important;
  }
}
.heading-style {
  font-family: $bosch-font-family-general;
  font-size: 1.6rem;
  font-weight: 100;
}

.confirm-button {
  margin-right: 1rem !important;
  font-size: 1.2rem !important;
}

.decline-button {
  font-size: 1.2rem !important;
}
