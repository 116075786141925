.page {
  &__body_default {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: inherit;

    @media only screen and (max-width: 768px) {
      padding: 0 1rem;
    }


    &_image_container {
      height: 22rem;
      max-width: 21.5rem;
      max-height: 22.6875rem;
      margin-bottom: 2rem;
      margin-top: 3rem;
      position: relative;
    }

    &_image {
      width: 100%;
      height: 100%;

      &_content {
        position: absolute;
        top: 5.5rem;
        left: 4rem;
        font-size: 1.125rem;
      }
    }
  }
}

/* this Styles applies only for Firefox */
@supports (-moz-appearance: none) {
  .add-new-category {
    margin-top: 20px; 
  }
}
