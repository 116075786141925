@import '../../../../../variables.module.scss';
@import '../../../../../stylesheet/mixins.scss';

.close-icon-style {
  top: 0%;
  color: $--bosch-white !important;
  right: 0%;
  position: absolute !important;
}

.div-content-style {
  position: absolute;
  background-color: $--bosch-white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: $bosch-font-family-general;
  width: 55%;
  cursor: default !important;

  @media only screen and (max-width: 768px) {
    width: 96%;
  }
}

.plan-name-style {
  font-size: 1.3rem;
  font-family: $bosch-font-family-light;
  margin-bottom: 0.5rem !important;

  @media only screen and (max-width: 768px) {
    @include text-ellipsis;
    max-width: 13rem;
  }
}

.price-display-margin {
  margin-bottom: 0.5rem !important;
}

.billing-style {
  font-size: 1.5rem;
  margin-right: 0.5rem;
}

.dialog-heading-style {
  color: $--bosch-black;
  font-family: $bosch-font-family-general;
  margin-top: 0.9rem;
  word-wrap: break-word;
}

.content-style {
  padding: 0.9rem 1.3rem 0.9rem 1.8rem;
  display: block;
  overflow-y: auto;
  max-height: 37.5rem;
}

.table-container {
  box-shadow: none !important;
  margin: 1.3rem 0;
  border-radius: 0 !important;

  @media only screen and (max-width: 768px) {
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.table-style {
  min-width: 41.6rem !important;
  border: 0.1rem solid $--bosch-light-gray;
  padding: 0.4rem;
  border-radius: 0;
}

@mixin tableHeadStyle($width) {
  font-family: $bosch-font-family-general;
  font-weight: bold !important;
  font-size: 1rem !important;
  background-color: $--bosch-gray-10 !important;
  padding-left: 0.9rem !important;
  width: $width;
}

.sl-no-content {
  @include tableHeadStyle(3rem);
}

.pricing-tier-content {
  @include tableHeadStyle(10rem);
}

.quota-content {
  @include tableHeadStyle(4rem);
}

.price-content,
.unit-content {
  @include tableHeadStyle(4rem);
}

.details-card {
  border-radius: 0 !important;
  border-style: solid;
  border-width: 0.1rem;
  border-color: $--bosch-light-gray;
  box-shadow: none !important;
  width: 50% !important;
}

.list-style {
  padding: 0.05rem 0.4rem 0.05rem 0.4rem !important;
}

.list-item-text {
  padding: 0;
}

.plan-benefit-item {
  @include text-ellipsis;
}

.list-item-icon-style {
  min-width: 2rem !important;
}

.info-icon-cursor-styling {
  cursor: pointer;
  width: 1.5rem;
}

.bold-desc {
  font-weight: bold !important;
  font-family: $bosch-font-family-bold;
  font-size: 1.16rem;
}

.bold-style {
  font-weight: bold !important;
  font-size: 1.2rem !important;
}

.slab-style {
  font-size: 1rem !important;
  font-family: $bosch-font-family-light;
  margin-bottom: 0.5rem !important;
  width: max-content !important;
}

.italic-style {
  font-style: italic !important;
  font-weight: bold;
  font-family: $bosch-font-family-general;
  font-size: 1rem;
}

.plan-details-display {
  flex: auto;
}

.check-icon-style {
  color: $--bosch-blue-68 !important;
}

.card-display {
  display: flex;
}

.card-margin {
  margin-right: 1.3rem;
}

.application-width {
  width: 10rem;
  flex: none !important;
}

.pricing_text_div {
  @include flexAlignCenter();
  justify-content: center;
  color: $--bosch-white;
}

.check_icon {
  position: absolute;
  right: 0;
  top: 0;
}

.icon__check__side {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0rem 5rem 5rem 0;
  border-color: transparent $--bosch-blue-88 transparent transparent;

  svg {
    position: absolute;
    right: 0.5rem;
    top: 0.75rem;
    fill: $--bosch-white;
  }
}

.card__top__div {
  text-align: center;
  padding: 0 1rem;

  &-customtooltip:hover:before {
    bottom: unset !important;
    max-width: 100% !important;
    left: 0% !important;
  }
}

.card__div__bottom {
  @include flexAlignCenter();
  flex-flow: column;
}

.mb__1 {
  margin-bottom: 1rem;
}

.edit__icon {
  width: 2rem !important;
}

.bottom__icon__div {
  @include flexAlignCenter();
}
