@import '../../../../stylesheet/mixins.scss';
@import '../../../../stylesheet/variables.scss';

.multi_select_field {
  cursor: pointer;
}

.multi-select-disabled {
  pointer-events: none;
}

.file_download_disabled {
  @include flexEnd;
  position: relative;
  right: 0rem;
  top: -2rem;
  color: var(--minor-accent__enabled__front__default);
  pointer-events: none;
  opacity: 0.5;
}

.file_download {
  @include flexEnd;
  position: relative;
  right: 0rem;
  top: -2rem;
  color: var(--minor-accent__enabled__front__default);
}

.file_download-text {
  margin-top: 8px;
  cursor: pointer;
}

.long__description__api {
  @include flexSpaceBetween;
}

.download-image-swagger {
  width: 1.25rem;
  position: relative;
  top: 0.2rem;
}

#customized-menu {
  ul {
    li:before {
      opacity: 0;
    }
  }
}

.public-DraftEditor-content {
  ul {
    li:before {
      opacity: 0;
    }
  }
}

.rich_text_editor {
  background-color: $--bosch-white-10;
  padding: 0.4rem 1rem;
  border-bottom: 0.0625rem solid var(--neutral__enabled__front__default);
  z-index: 0;

  .rich-text-editor__root {
    padding: 0;
  }

  .public-DraftStyleDefault-ol li {
    margin-left: 1.5em !important;
  }

  .rich-text-editor__root__container {
    padding: 0;
    border-bottom: 0 !important;
    padding-left: 0.4rem;
  }
}

.form_width {
  .multi_select_field {
    li {
      margin-bottom: 0 !important;
      padding: 0.5rem !important;
      @include flexAlignCenter;

      &::before {
        display: none;
      }
    }

    input[type='checkbox']:checked + label::after {
      content: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="white" viewBox="0 0 24 24"><path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"/></svg>');
      display: block;
      position: absolute;
      left: 0.1875rem;
      top: 0.1875rem;
    }

    input[type='checkbox'] {
      width: 1rem;
      height: 1rem;
      accent-color: var(--bosch-blue-50);
    }

    input[type='checkbox']:checked + label::before {
      content: '';
      display: block;
      position: absolute;
      text-align: center;
      @include Width_height_125;
      left: 0;
      top: 0.3125rem;
      background-color: var(--minor-signal-error__enabled__fill__default);
      border-radius: 0.125rem;
      border: 0.0625rem solid var(--bosch-gray-70);
    }

    .optionContainer {
      margin-top: 0.3rem;
      padding: 0.5rem;
      border: 0.0625rem solid var(--bosch-gray-70);
      border-radius: 0.3rem;
      max-height: 12rem;
      position: absolute;
      width: 100%;
      z-index: 1;
      background: var(--bosch-white);
      overflow: auto;

      .option {
        &:hover {
          background: unset;
          color: var(--bosch-black);
        }

        input {
          margin-top: 0;
        }
      }
    }

    input {
      margin-bottom: 0;
      padding: 0;
      color: var(--bosch-black);
    }

    label {
      position: absolute;
      font-size: 0.75rem;
      max-width: calc(100% - 4rem);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      z-index: 1;
      margin: 0.25rem 0rem auto;
    }

    .mandatory__symbol {
      color: $--bosch-red;
    }

    .searchWrapper {
      height: 3.2rem;
      width: 100%;
      background-color: var(--bosch-gray-90);
      padding: 0.4rem 1rem;
      border-bottom: 0.0625rem solid var(--neutral__enabled__front__default);

      .chip {
        position: relative;
        top: 2.6875rem;
        left: -0.875rem;
        margin: 0.3rem 1rem 0.3rem 0 !important;
        width: unset;
        line-height: 1.5rem;

        svg {
          top: -0.1rem;
        }
      }
    }

    .multiple__div {
      background-color: unset !important;
      border-bottom: unset !important;

      .arrow-right {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
      }
    }
  }
}

.error_custom_text {
  margin-left: 0.3rem;
  color: var(--bosch-black);

  div {
    color: var(--bosch-black);
  }
}

.error_custom_div {
  padding: 0.2rem 0.625rem;
  display: flex;

  &_text {
    margin-left: 1rem;
  }

  img {
    display: flex;
    @include width_15;
  }

  span {
    color: var(--bosch-black);
  }

  color: var(--bosch-black);
  background-color: var(--bosch-red-90);
}

.description_custom {
  @include flexEnd;
  margin-top: 0.4rem !important;
  color: $--bosch-silver-sand !important;
}

.revamped_textarea {
  height: unset !important;
  min-height: unset !important;
}

.input_container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;

  @media only screen and (max-width: 768px) {
    display: block;

    .input__addinal-info {
      margin-bottom: 1rem;
    }

    .description_custom {
      margin-bottom: 1rem;
    }

    .input_group {
      margin-bottom: 1rem;
    }
  }

  &-wrapper {
    margin: unset !important;
    @include flex_col;

    @media only screen and (max-width: 768px) {
      margin-bottom: 1rem !important;
    }

    .public-DraftStyleDefault-block {
      overflow-wrap: anywhere;
    }
  }
}
