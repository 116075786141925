.plan-config {
  &__title {
    font-size: 1.25rem;
    margin-bottom: 2.2rem;
  }
  &__fields {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;

    button {
      min-width: 4rem;
    }
  }
  &__cust-grid {
    margin-top: 1rem;
  }
}
