.bread-crumb {
  &__container {
    &__arrow {
      display: flex;
      flex-direction: row;
      height: 1.5rem;
    }

    &__items {
      text-decoration: none;
      color: black;
      font-size: 0.9rem;
    }

    &__items:active {
      font-weight: 900;
    }
    &__items.disabled {
      cursor: context-menu !important;
    }
  }
}

.breadcrumb {
  &__item-list-element {
    max-width: 10rem;
    white-space: nowrap;

    &:last-child {
      color: black;
    }

    &-tooltip {
      &:hover:before {
        max-width: unset !important;
        bottom: unset !important;
        word-break: break-all;
        @media only screen and (max-width: 768px) {
          white-space: initial !important;
          top: 20px !important;
        }
      }
    }
  }
}
