@import '../../../../stylesheet/mixins.scss';
@import '../../../../stylesheet/variables.scss';

@mixin mobileFullWidth() {
  @media screen and (max-width: 768px) {
    max-width: 100%;
    width: 100%;
    margin-top: 1rem;
  }
}

.order-overview {
  &__head {
    &__order-date {
      margin-left: 1rem;

      @media screen and (max-width: 768px) {
        margin: 0.5rem 0 0 0;
      }
    }
  }

  &__body {
    display: flex;
    justify-content: space-between;
    padding: 1rem 0 2rem 0;
    width: 100%;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }

    &__flex {
      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }

    &__address {
      text-align: left;
      min-width: 30%;
      max-width: 30%;
      @include mobileFullWidth();

      .names-container{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        gap: 0.8rem;
        margin-bottom: 0.8rem;
      }

      .email-container{
        margin-top: 0.6rem;
      }
    }

    &__payment {
      @include flexCustom(center, flex-start);
      max-width: 10.0625rem;
      @include mobileFullWidth();
    }

    &__summary {
      min-width: 33%;
      max-width: 33%;

      &--flex {
        width: 100%;
        @include flexCustom(space-between, flex-start);
      }

      @include mobileFullWidth();

      &__tax-text {
        margin-top: 0.5rem;
      }
    }

    &__documents {
      min-width: 33%;
      max-width: 33%;
      @include flexStart;
      @include mobileFullWidth();

      @media screen and (max-width: 768px) {
        justify-content: left;
      }

      &__list--item {
        display: flex;
        align-items: center;
        word-break: break-all;
        text-align: justify;
        margin-right: 1rem;
        gap: 8px;
      }
    }
  }
}

.product-documents {
  font-weight: 800;
  text-decoration: none;
}

.item-grand-total {
  font-weight: bold;
}

.a-link {
  text-decoration: none;
  color: none !important;
}

.doc-name {
  color: $--bosch-blue-50;
}

.order-overview-container {
  @media screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
  }
}

.-mobile {
  @media screen and (max-width: 600px) {
    font-size: 0.7rem;
  }
}

.payment-status {
  @include flexCustom(space-between, center);
}

.payment-type {
  font-size: 1.05rem;
}

.paid {
  background-color: $--bosch-success-light;
  color: $--bosch-success-main;
}

.open {
  background-color: $--bosch-danger-dark;
  color: $--bosch-danger-light;
}

.overdue {
  background-color: $--bosch-warning-dark;
  color: $--bosch-warning-light;
}

.unspecified {
  background-color: $--bosch-secondark-light;
  color: $--bosch-secondary-dark;
}

.text-bold {
  font-weight: 800;
}

.margin_top_bottom {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}
