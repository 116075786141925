@import '../../../../stylesheet/variables.scss';
@import '../../../../stylesheet/mixins.scss';
@import '../../../../variables.module.scss';

.new-payments {
  height: calc(100vh - 16rem);
  overflow-y: scroll;
  margin-top: 1.5rem;
  border: 0.083rem solid $--bosch-white-20;
  opacity: 1;
  padding: 1rem;
  width: 100%;

  .tab_panel {
    height: auto !important;
  }

  &__disable-tab {
    cursor: not-allowed !important;
  }

  &__alert {
    top: 0;
    margin-top: 1rem;
    padding: 0.5rem;
    background-color: $--bosch-red;
    color: $--bosch-white;
  }

  @media only screen and (max-width: 768px) {
    height: auto;
    overflow-y: auto;
  }

  &__nameDiv {
    @include flexCustom(space-between, center);
    opacity: 1;
    padding: 0 0 1rem 0;
    font-weight: 700;
    cursor: pointer;

    .context {
      &__box-style {
        border-bottom: 0.1rem solid $--bosch-concrete-solid;
        font-weight: bold;
        cursor: pointer;
        border-radius: 0.2rem;
        @include flexJustifyCenter;
        flex-grow: 1;
        padding: 0 0.5rem 1rem 0;
        font-size: 1rem;
        text-align: center;
      }

      &__non-selected-tab-style {
        background: $--bosch-white;
      }

      &__tab-background-font-color {
        border-bottom: 0.2rem solid var(--bosch-blue-50);
        border-radius: 0;
        color: var(--bosch-blue-50);
      }
    }
  }

  &__wholeDiv {
    width: 100%;
    padding: 1rem 0 0 0;
    margin-bottom: 2.5rem;

    &-iconWrapper {
      width: 100%;
      background: $--bosch--light-blue-2 0% 0% no-repeat padding-box;
      border: 0.083rem solid $--bosch-blue-97;
      border-radius: 0.667rem;
      padding: 1rem;
      margin-left: unset;
      display: flex;
      gap: 0.5rem;

      .payments__recurring-payment-warning {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    &-iconWrapper.mps-warning {
      margin-top: 2rem;
      background: $--bosch-warning-light;
      border: 0.083rem solid $--bosch-warning-dark;
    }
  }

  &__groupDiv {
    padding: 0;
    padding-top: 1.5rem;

    &-wrapper {
      @include flex_col;
      gap: 1rem;
    }

    .toggle__div {
      @include flex_column;
      gap: 0.5rem;

      @media only screen and (max-width: 768px) {
        display: block;
      }

      &-container {
        @include flexAlignCenter;
        gap: 2rem;
        .toggle__div-subDiv {
          font-family: $bosch-font-family-medium;
          letter-spacing: 0px;
        }
      }

      &-attributes {
        width: 100%;

        @media only screen and (max-width: 768px) {
          width: 100%;
          margin-top: 1rem;
        }
      }
    }

    .select-field.hidden {
      display: none;
    }
  }
}
