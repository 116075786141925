@import '../../variables.module.scss';
@import '../../stylesheet/variables.scss';
@import '../../stylesheet/mixins.scss';

.my-products {
  @include flex_col;
  width: 100%;

  &_item {
    padding: 1rem 0;
    @include flexSpaceBetween;

    &_view-button {
      padding-right: 1rem;

      @media only screen and (max-width: 768px) {
        padding-right: unset;
      }
    }

    &_text {
      font-weight: 700 !important;
    }

    @media only screen and (max-width: 768px) {
      padding: 1rem;
    }
  }

  &_cards {
    display: flex;
    box-sizing: border-box;
    width: 100%;
    min-height: 14rem;
  }

  @media only screen and (max-width: 768px) {
    .api-list-view {
      min-height: auto;
    }
  }
}
