@import '../../../variables.module.scss';

.link-secondary {
  color: var(--bosch-black) !important;
}

.no-underline {
  text-decoration: none;
}

.a-link:hover {
  text-decoration: none;
}

.content-align {
  vertical-align: top;
}
