@import '../../../variables.module.scss';
@import '../../../stylesheet/mixins.scss';
@import '../../../stylesheet/variables.scss';

.first-api {
  margin: 2rem;
  width: 100%;

  &_content {
    margin-top: 3.12rem;
    @include flex_col;
    align-items: center;

    &_text-adjustment {
      text-align: center;
      margin: 1rem;
      margin-top: 1.5rem;
    }

    &_button {
      margin-top: 1rem;
      text-align: center;
    }

    &_element {
      width: 22rem;
    }

    &_sales-rep-element {
      width: 15rem;
    }
  }
}
.api-list-view {
  width: 100%;
  padding-right: 1rem;

  &_box {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, minmax(15rem, 1fr));
    gap: 1rem;

    @media only screen and (max-width: 768px) {
      grid-template-columns: 1fr;
    }

    &_card {
      @media only screen and (max-width: 768px) {
        width: 100%;
        flex: 1;
        padding: 0;
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    padding-right: unset;
    padding-bottom: 1rem;
    min-height: calc(100vh - $header-ht - 233.75px - 54px);
  }
}

.api-field {
  overflow-y: scroll;
  overflow-x: hidden;

  .searchbar-icon {
    display: none;
  }

  @media only screen and (max-width: 768px) {
    padding: 0;

    .searchbar-hide {
      display: none;
    }

    &_items_text {
      margin-left: 1rem;
    }

    .searchbar-show {
      position: absolute;
      left: 0%;
      top: 10rem;
      z-index: 9;
      width: 100%;
      padding: 0 1rem 0 1rem;

      .product__search {
        width: 100%;
      }
    }

    .searchbar-icon {
      display: block;
    }
  }

  &_search_responsive {
    display: block;
    padding: 0rem;

    @media only screen and (max-width: 768px) {
      display: none;
    }
  }

  &_items {
    width: 100%;
    @include flexSpaceBetween;
    align-items: center;
    margin-top: 2rem;

    @media only screen and (max-width: 768px) {
      margin-left: 0;
      margin-top: 1rem;
    }

    &_buttons {
      @include flexSpaceBetween;
      align-items: center;

      &_search {
        padding-right: 1rem;
      }

      &_product {
        padding-right: 3rem;
        padding-left: 1rem;

        @media only screen and (max-width: 768px) {
          padding-right: 1rem;
        }
      }
    }
  }

  &_grid {
    display: flex;
    width: 100%;
    margin: 1rem;
    margin-left: unset;

    @media only screen and (max-width: 768px) {
      margin: 0;
    }

    &_space {
      width: 100%;

      &_scroll {
        overflow-x: hidden;
        overflow-y: auto;

        &_sales-rep-height {
          max-height: calc(100vh - 263px - $footer-ht);
        }

        &_seller-height {
          max-height: calc(100vh - 163px - $footer-ht);
        }

        @media only screen and (max-width: 768px) {
          max-height: calc(100vh - $header-ht);
        }
      }
    }

    &_searchbar_open {
      @media only screen and (max-width: 768px) {
        margin-bottom: unset !important;
        @include flex_col(space-between);
        height: 100%;
        margin-top: 5rem;
      }
    }
  }

  &_line-border {
    border: 1px solid $--bosch-white-20;
    width: 100%;
  }
}
