@import '../variables.module.scss';
@import '../stylesheet/variables.scss';

p,
span,
h1,
h2,
h3,
h4,
h5,
h6,
div,
input,
textarea,
a,
button,
select {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: $bosch-font-family-general;
}

html,
body {
  font-size: 80%; //Changing the font size from 80% to 100%.
  //[MS-18694 Changing from 100% to 80%]
}

body {
  letter-spacing: 0rem;
  background: $--bosch-white;
  color: #333;
}

/* Commented for future use */
/* @media screen and (max-width: 1920px) {
  html,
  body {
    font-size: 110%;
  }
}

@media screen and (max-width: 1366px) {
  html,
  body {
    font-size: 95%;
  }
}

@media screen and (max-width: 1280px) {
  html,
  body {
    font-size: 70%;
  }
} */

.mb6 {
  margin-bottom: 0.8rem;
}

.mb12 {
  margin-bottom: 1.2rem;
}

.ms-disabled {
  cursor: not-allowed;
}

li {
  margin-bottom: 0 !important;
}

@keyframes slide {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 0 -400px;
  }
}

.home_banner {
  max-height: 610px;
}

.home-banner-content {
  padding-top: 40px;
  padding-bottom: 40px;
}

.MuiTypography-root,
.MuiTypography-h1,
.MuiTypography-h2,
.MuiTypography-h3,
.MuiTypography-h4,
.MuiTypography-h5,
.MuiTypography-h6,
.MuiBottomNavigationAction-root,
.MuiBottomNavigationAction-label,
.MuiTypography-subtitle1 {
  font-family: $bosch-font-family-general;
}

/* Custom Stylesheet */
/**
* Use this file to override Materialize files so you can update
* the core Materialize files in the future
*
* Made By MaterializeCSS.com
*/

.section {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.section.no-pad-bot {
  padding-bottom: 0;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: lightgrey;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.iconstyle {
  color: rgba(0, 168, 176, 1);
  display: block;
  background: -webkit-linear-gradient(
    90deg,
    rgba(0, 168, 176, 1) 0%,
    rgba(120, 190, 32, 1) 100%
  );
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.iconstyle1 {
  color: rgba(80, 36, 127, 1);
  display: block;
  background: -webkit-linear-gradient(
    90deg,
    rgba(80, 36, 127, 1) 0%,
    rgba(185, 2, 118, 1) 100%
  );
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
input::-ms-clear {
  display: none;
}

/* Loading bubble styles start */

.bubble-loader-styles {
  position: absolute;
}

.bubble {
  animation: bubble_expand 0.75s ease-in-out infinite;
  border-radius: 20px;
  display: inline-block;
  transform-origin: center center;
  margin: 0 3px;
  width: 10px;
  height: 10px;
}

.bubble:nth-child(1) {
  background: #57b0ff;
}

.bubble:nth-child(2) {
  animation-delay: 180ms;
  background: #0296e9;
}

.bubble:nth-child(3) {
  animation-delay: 360ms;
  background: #86d7a2;
}

.bubble:nth-child(4) {
  animation-delay: 540ms;
  background: #219557;
}

@keyframes bubble_expand {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.55);
  }
}

/* Loading bubble style ends */

pre {
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: pre-line;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  font-family: $bosch-font-family-general !important;
}

.center-align {
  text-align: center;
}

.modal-responsive {
  min-width: 50rem !important;
}

@media only screen and (max-width: 600px) {
  .modal-responsive {
    min-width: 20rem !important;
  }
}

:root {
  --primary-color: var(--major-accent__enabled__fill__default);
  --primary-bg-color: var(--major-accent__enabled__fill__default);
  --primary-text-color: var(--major-accent__enabled__front__default);
  --bosch-font-family-general: 'boschsans', sans-serif !important;
}

.ms-pointer {
  cursor: pointer;
}

.ms-horizontal-scrollable {
  overflow-x: auto;
}

.ms-disabled-pointer {
  pointer-events: none;
}
