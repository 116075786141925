@import '../../stylesheet/variables.scss';
@import '../../variables.module.scss';
@import '../../stylesheet/mixins.scss';

.quotations {
  &__header-label {
    font-size: 1.4rem;
    color: $--bosch-black;
    font-weight: 600;
    font-family: $bosch-font-family-bold;
    letter-spacing: 0;
    opacity: 1rem;
  }

  &__container {
    border: 1rem;
    border: 1px solid $--bosch-blue-50;
    padding: 1rem;
    width: 100%;
    margin: 1rem 0 0 0;
  }

  &__name-div {
    @include flexCustom(space-between, center);
    padding: 0 0 1rem 0;
    font-weight: 700;
    font-family: $bosch-font-family-bold;
    cursor: pointer;

    .context {
      &__box-style {
        border-bottom: 0.1rem solid $--bosch-concrete-solid;
        font-weight: bold;
        cursor: pointer;
        border-radius: 0.2rem;
        flex-grow: 1;
        display: flex;
        padding: 0 0 1rem 0;
      }

      &__non-selected-tab-style {
        background: $--bosch-white;
      }

      &__tab-background-font-color {
        border-bottom: 0.2rem solid $--bosch-blue-50;
        border-radius: 0;
        color: $--bosch-blue-50;
      }
    }
  }

  &__whole-div {
    padding: 1rem 0 0 0;

    &__icon-wrapper {
      background: $--bosch--light-blue-2 0% 0% no-repeat padding-box;
      border: 0.083rem solid $--bosch-blue-97;
      border-radius: 0.667rem;
      padding: 1rem;
      margin-left: unset;
      display: flex;
      gap: 0.5rem;
    }
  }

  &__tab-name {
    font-size: 0.875rem;
  }

  &__plan-info {
    margin-left: 0.5rem;
    font-size: 0.875rem;
    color: $--bosch-shuttle-gray;

    &__heading {
      font-weight: 600;
      margin: 0 0.5rem 0 0;
    }
  }

  &__group-div {
    &__wrapper {
      @include flex_col();
      gap: 1rem;
    }

    .toggle__div {
      @include flexCustom(space-between, flex-end);
      margin: 2rem 0 0 0;

      &__container {
        @include flexJustifyCenter;

        .toggle__div-subDiv {
          max-width: 15rem;
        }
      }

      &__attributes {
        width: 40%;
      }
    }
  }
}

.quotations-home-wrapper__form {
  width: 100%;
  height: 100%;
  position: relative;

  @media only screen and (max-width: 768px) {
    position: static;
  }

  &__wrapper {
    display: grid;
    grid-gap: 1.563rem;
    grid-template-columns: 1fr 1fr;
    padding: 2rem;
    height: inherit;
    overflow: auto;
    grid-auto-rows: minmax(min-content, max-content);
    padding-bottom: 7rem;

    @media only screen and (max-width: 768px) {
      display: block;
      padding: 1rem 1rem 6rem;
    }
  }

  &__input {
    grid-column: span 1;
  }

  &__document {
    grid-column: span 2;

    @media only screen and (max-width: 768px) {
      margin-bottom: 1rem;
    }
  }
}
