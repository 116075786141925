.header-style {
  margin-top: 0px !important;
  font-weight: normal !important;
}
.header-one {
  font-size: 24px;
}

.header-two {
  font-size: 20px !important;
}

.header-three {
  font-size: 16px !important;
}

.header-four {
  font-size: 14px !important;
}

.header-five {
  font-size: 12px !important;
}

.header-six {
  font-size: 10px !important;
}

.visibility-div {
  grid-column: span 2;
}
