@import '../../../stylesheet/variables.scss';

%modal-shared {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;

  @media only screen and (max-width: 768px) {
    padding: 1rem;
  }
}

%modal-style {
  display: flex;
  width: 100%;
  height: 35%;

  @media only screen and (max-width: 768px) {
    width: 100%;
    height: 35%;
  }
}

.modal {
  z-index: 4 !important;
  @extend %modal-shared;

  &__success {
    width: 43.3rem;
    height: 12.3rem;
    background: $--bosch-powder-ash 0% 0% no-repeat padding-box;

    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }

  &__cancelIcon {
    position: absolute;
    cursor: pointer;
    top: 0.8rem;
    right: 0.8rem;
    width: 1.5rem;
    height: 1.5rem;

    @media only screen and (max-width: 768px) {
      top: 1.8em;
      right: 1.8rem;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    top: 50%;
    left: 50%;
    width: 43.3rem;
    min-height: 35.3rem;
    transform: translate(-50%, -50%);
    background: $--bosch-white 0% 0% no-repeat padding-box;

    @media only screen and (max-width: 768px) {
      width: 100%;
      padding: 1rem;
    }
  }

  &__overlay {
    background-color: $--bosch-gray-background-80;
    @extend %modal-shared;
  }

  &__message {
    margin-top: 4rem;
    position: absolute;
    right: 1rem;
    bottom: 1.5rem;

    @media only screen and (max-width: 768px) {
      text-align: end;
    }
  }

  &__input {
    margin-top: 2rem;

    @media only screen and (max-width: 768px) {
      padding: 1rem;
    }
  }

  &__close {
    text-align: center;
    color: $--bosch-white;
  }
}

.alert__message {
  display: flex;
  flex-direction: column;
  line-height: 1.5rem;
  text-align: center;

  &__title {
    text-align: center;
    color: $--bosch-black;
    width: 24rem;
    margin-top: 1.5rem;

    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }

  &__subtitle {
    margin-top: 1rem;
    width: 26rem;
    text-align: center;

    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }
}
