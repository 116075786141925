/* pdu - product document upload */
.pdu {
  &__heading {
    color: #000000;
  }

  &__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1rem;

    @media screen and (max-width: 768px) {
      grid-template-columns: 100%;
      grid-column-gap: initial;
    }
  }

  &__container_text_and_file {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 1rem;
  }

  &__divider {
    margin: 1rem 0;
    display: flex;
    align-items: center;
  }

  &__width {
    min-width: 5rem;
  }

  &__label_space {
    padding: 0 1rem;
    font-size: 1rem;
  }

  &__document-wrapper {
    width: 100%;
  }
}
.category__upload {
  display: block !important;
}
