@import '../../../variables.module.scss';

.box-heading {
  margin-bottom: 10px;
}

.sub-textstyle {
  font-size: 0.8125rem;
  font-family: $bosch-font-family-general;
}

.visibility-button {
  display: flex;
  margin: 10px 0;
}

.toggle-div {
  margin-left: 16px;
}

.info-text {
  display: flex;
}

.info-style-icon {
  font-size: 18px !important;
  margin-right: 0.4rem;
}
