.bosch_strip {
  img {
    background-size: 100%;
    height: 6px;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 2;
    position: absolute;
  }
}