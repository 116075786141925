.rich-text-editor {
  label {
    padding: 0.4rem 1rem 0;
    background: #efeff0;
    display: block;
  }
  &__root {
    margin-top: 0 !important;
    &__container {
      max-height: 100px !important;
      height: 100px !important;
    }
  }
  .DraftEditor-editorContainer {
    word-break: break-all;
  }
  &__required {
    color: var(--bosch-red-50);
  }

  .public-DraftStyleDefault-orderedListItem {
    list-style-type: none !important;
  }
}
