@import '../../../stylesheet/variables.scss';

.orders {
  &__container {
    @media only screen and (max-width: 768px) {
      padding: 1rem;
    }

    &__tabCount {
      display: flex;

      &__statusCount {
        margin-left: 0.5rem;

        &__count {
          text-align: center;
          color: var(--bosch-white);
        }

        .a-badge {
          font-size: 0.9rem !important;
        }
      }
    }

    &__item {
      display: flex;
      padding: 1rem 1rem 0 1rem;
      align-items: flex-end;

      @media only screen and (max-width: 768px) {
        padding: unset;
        margin-bottom: 1.5rem;
        display: block;
      }

      &__heading {
        width: 100%;
        text-align: left;
        font-size: 1.25rem;
        color: var(--bosch-black);
      }

      &__upDown {
        padding-left: 1rem;
      }
    }

    &__tabs {
      margin-left: 0.5rem;
      width: fit-content;

      @media only screen and (max-width: 768px) {
        margin-left: unset;
        padding-left: unset;
        overflow-x: auto;
        width: 100%;
      }
    }

    &__table {
      padding: 1rem;
    }

    &__empty {
      padding: 3rem 0;
    }
  }
}

.order-history-searchbox {
  display: flex;
}

.order-history-container {
  display: 'flex';
  justify-content: 'space-between';
}
