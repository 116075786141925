@import '../../../stylesheet/mixins.scss';
@import '../../../stylesheet/variables.scss';

.date-picker {
  width: 100%;
  height: 3rem;

  .date-custom-input {
    background-color: unset;
    border: unset;
    position: relative;
    top: 1.2rem;
    color: var(--bosch-gray-40);
    width: 100%;
    text-align: left;
    padding: 0 1rem;
  }

  .calendar-icon {
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    right: 0.5rem;
    top: 1rem;
  }

  .react-datepicker {
    z-index: 1;

    @media only screen and (max-width: 768px) {
      position: relative;
      right: 0.5rem;
    }
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    width: 3.7em;
    line-height: 3.7em;
  }

  .react-datepicker__month-container {
    width: 24rem !important;

    @media only screen and (max-width: 768px) {
      width: auto !important;
    }
  }

  &__element {
    width: fit-content;
  }
}
