.m-step-indicator__step:not(:first-child):before {
  content: '' !important;
}

.m-step-indicator {
  &__steps-vertical {
    @media screen and (max-width: 768px) {
      flex-direction: column !important;
      margin-left: calc(-100% + 20%);
      margin-top: 1rem;
    }
  }

  &__label-vertical {
    @media screen and (max-width: 768px) {
      justify-self: left !important;
      text-align: left !important;
      position: relative;
      top: -1.5rem;
      left: calc(50% + 1.5rem) !important;
    }
  }

  &__step-vertical:not(:first-child):before {
    @media screen and (max-width: 768px) {
      content: '' !important;
      min-height: 4rem !important;
      height: calc(100% + 1rem) !important;
      width: 1px !important;
      left: calc(50% - 0.04rem) !important;
      top: -3.25rem !important;
    }
  }

  &__step.-active:before {
    @media screen and (max-width: 768px) {
      z-index: 1;
    }
  }
}

.highlight {
  font-weight: 800;
}
