@import '../../../variables.module.scss';
@import '../../../stylesheet/variables.scss';

$color_1: white;
$background-color_1: #007dc1;

%modal-shared {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  @media only screen and (max-width: 768px) {
    padding: 1rem;
  }
}

%modal-style {
  display: flex;
  width: 100%;
  height: 35%;

  @media only screen and (max-width: 768px) {
    width: 100%;
    height: 35%;
  }
}

@mixin dialog_image($bkgdcolor: #008ecf 0% 0% no-repeat padding-box) {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: inherit;
  height: 12.375rem;
  background: $bkgdcolor;

  &__image {
    width: 12.5rem;
    object-fit: cover;
    overflow: hidden;
  }

  &__alert {
    position: relative;
    width: 2.3125rem;
    height: 2.3125rem;
    bottom: 7rem;
    left: 4rem;
  }
}

.sku-dialog_modal {
  @extend %modal-shared;
  background-color: rgba(129, 126, 126, 0.8);
  z-index: 4;

  &__success {
    @include dialog_image;

    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }

  &__error {
    @include dialog_image($bkgdcolor: #c8d3cd 0% 0% no-repeat padding-box);
  }

  &__visibility_modal {
    @include dialog_image($bkgdcolor: $--bosch-blue-90 0% 0% no-repeat padding-box);
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 34rem;
    max-width: 44rem;
    overflow-y: auto;
    transform: translate(-50%, -50%);
    background: #ffffff 0% 0% no-repeat padding-box;

    @media only screen and (max-width: 768px) {
      .modal__information__msg {
        position: unset;
      }
    }
  }

  &__message {
    width: inherit;
    height: 3rem;
    display: flex;
    justify-content: flex-end;
    column-gap: 1rem;
    position: absolute;
    right: 2rem;
    bottom: 2rem;

    @media only screen and (max-width: 768px) {
      text-align: end;
      margin-top: 1rem;
    }
  }

  &__input {
    width: 100% !important;
    margin-top: 2rem;

    @media only screen and (max-width: 768px) {
      padding: 1rem;
    }
  }

  &__close {
    text-align: center;
    color: #ffffff;
  }
}

.dialog_alert__message {
  display: flex;
  flex-direction: column;
  line-height: 1.5rem;
  text-align: left;
  padding: 0 2rem;

  @media only screen and (max-width: 768px) {
    margin-left: unset;
    padding: 1rem;
  }

  &__title {
    font-family: $bosch-font-family-general;
    color: #000000;
    margin-top: 1.5rem;

    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }

  &__subtitle {
    margin-top: 1rem;
    width: 35rem;
    font-family: $bosch-font-family-general;

    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }
}

.dialog_remark {
  &__message {
    display: flex;
    position: relative;
    line-height: 1.5rem;
    text-align: left;
    margin-left: 0 !important;
    padding-left: 2rem;
    top: 0 !important;
    padding-top: 1rem;
    background-color: $--bosch-white !important;

    &__title {
      font-family: $bosch-font-family-bold;
      margin-top: 0.75rem;
      max-width: 35rem;

      @media only screen and (max-width: 768px) {
        width: 100%;
        max-width: 17rem;
      }
    }
  }

  &__close {
    position: absolute;
    right: 1.25rem;
  }
}
