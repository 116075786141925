@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flexEnd {
  display: flex;
  justify-content: flex-end;
}

@mixin flexStart {
  display: flex;
  justify-content: flex-start;
}

@mixin flexJustifyCenter {
  display: flex;
  justify-content: center;
}

@mixin flexAlignCenter {
  display: flex;
  align-items: center;
}

@mixin textAlignCenter {
  text-align: center;
}

@mixin flexSpaceBetween {
  display: flex;
  justify-content: space-between;
}

@mixin flexSpaceAround {
  display: flex;
  justify-content: space-around;
}

@mixin flex_row {
  display: flex;
  flex-direction: row;
}

@mixin flexCustom($justify, $alignitems) {
  display: flex;
  justify-content: $justify;
  align-items: $alignitems;
}

@mixin flex_col($justify: flex-start, $align: flex-start) {
  display: flex;
  flex-direction: column;
  justify-content: $justify;
}

@mixin text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin marginBottom-gap-25 {
  margin-bottom: 2.5rem;
}

@mixin marginBottom-gap-12 {
  margin-bottom: 1.2rem;
}

@mixin marginTop-gap-15 {
  margin-bottom: 1.5rem;
}

@mixin marginBottom-gap-1 {
  margin-bottom: 1rem;
}

@mixin marginLeft-gap-2 {
  margin-left: 2rem;
}

@mixin Width_height_15 {
  width: 1.5rem;
  height: 1.5rem;
}

@mixin Width_height_2 {
  width: 2rem;
  height: 2rem;
}

@mixin Width_height_125 {
  width: 1.25rem;
  height: 1.25rem;
}

@mixin width_15 {
  width: 1.5rem;
}

@mixin tableheadingStyle($width) {
  line-height: 1.25rem !important;
  width: $width;
}

@mixin flex_column {
  display: flex;
  flex-direction: column;
}
