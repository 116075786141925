$font-regular: 'boschsans';
$font-light: 'boschsans';
$font-bold: 'boschsans';
$font-medium: 'boschsans';
$font-color: #bfc0c2;

@mixin flex_row($justify: flex-start, $align: flex-start) {
  display: flex;
  // flex-direction: row;
  justify-content: $justify;
  align-items: $align;
}

@mixin flex_col($justify: flex-start, $align: flex-start) {
  display: flex;
  flex-direction: column;
  justify-content: $justify;
  align-items: $align;
}

.upload {
  &__list_prev {
    width: 13rem;
    height: 12rem;
    object-fit: cover;

    &_container {
      @include flex_row($justify: space-between, $align: center);
      height: 2rem;
      width: 100%;
      overflow: hidden;

      @media only screen and (max-width: 768px) {
        // width: 20.5rem;
        width: 100%;
      }
    }

    &_content {
      color: #000000;
      font-family: $font-regular;
      font-size: 0.75rem;
      letter-spacing: 0;
      flex: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-left: 0.25rem;
      @media only screen and (max-width: 768px) {
        margin-left: 0;
      }
    }

    &_file_icon {
      width: 1.25rem;
      height: 1.875rem;
      flex: 0 0 1.25rem;
      margin: 0 0.25rem;
      @media only screen and (max-width: 768px) {
        margin-right: 0;
      }
    }
  }

  &__list_del {
    // width: 1.5rem;
    height: 1.5rem;
    // margin: 1rem;
    margin-left: 0.5rem;
    flex: 0 0 1.5rem;
    cursor: pointer;

    &_icon {
      width: 1.5rem;
      height: 1.375rem;
      background-color: transparent;
    }

    &_cont {
      @include flex_row($justify: center, $align: center);
      width: 3rem;
      height: 2.875rem;
      background-color: #ffffff;
      position: absolute;
      top: 0;
      right: -2px !important;
      margin: 0;
      padding: 0 1rem;
      cursor: pointer;
    }
  }
}
