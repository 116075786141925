@import '../../../variables.module.scss';
@import '../../../stylesheet/mixins.scss';
@import '../../../stylesheet/variables.scss';

.helper-text {
  color: $--bosch-red;
  margin-top: unset;
}

.api-statistics {
  padding: 2rem;
}

.response-code-color {
  cursor: pointer;
  color: var(--bosch-blue-50);
}

.field-center-align {
  @include textAlignCenter;
}

.menu-icon {
  @include flexEnd;
  margin-right: 1rem;
}

.heading-tag {
  font-weight: 700 !important;
}

.text-tag {
  font-weight: 500 !important;
  padding: 1rem 0 2rem 0;
}

.background-white {
  background: var(--bosch-white);
}

.label-margin {
  margin-bottom: 0.1rem;
}

.breadcrumb-statistics {
  padding: 1rem;
}

.datefield-alignment {
  @include flexCenter;
}

.no-data-msg {
  @include textAlignCenter;
}

.datefield-textbox {
  width: 12.25rem;
  background-color: var(--bosch-white);
  border-radius: 0.25rem;
}

.request-count {
  position: absolute;
  transform: rotate(270deg);
  left: 19.37rem;
  margin-top: 13%;
}

.statistic-header {
  padding: 0.75rem;
  display: flex;
  flex-wrap: wrap;
  background: $--bosch-concrete-solid;
  height: 6.25rem;
  margin: 1.2rem 0.32rem 1.2rem 0.32rem;

  &_style {
    @include flexSpaceBetween;
    width: 100%;
    align-items: center;

    &_content {
      @include flexSpaceBetween;
      align-items: center;

      &_select {
        @include flexAlignCenter;
      }
    }
  }
}

.date-form {
  margin-left: 1.57rem !important;
  @include flex_col;
  margin-bottom: 1rem;
}

.simple-menu {
  height: 2.7rem;
  width: 2.8rem;
}

.multiple-select {
  @include flex_row;
  margin-bottom: 2rem;

  &_field {
    width: 19rem;
    padding: 2rem 2rem 0 0;

    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.search-btn {
  margin-left: 1.25rem;
  padding: 0.12rem;
}

.menu-xlv {
  margin-right: 0.94rem;
}

.graph-container {
  @include flex_row;
  flex-wrap: wrap;

  &_card {
    width: 47%;
    background-color: $--bosch-floral-blue;
    height: 35rem;
    border: 0.06rem solid $--bosch-black-background-12;
    margin: 1rem;

    &_items {
      @include flex_col;
      align-items: center;
      padding: 1rem;

      &_graph {
        width: 100%;
        @include flexCenter;
      }
    }

    @media only screen and (max-width: 768px) {
      width: 100%;
      margin: 1rem 0 !important;
    }
  }
}

.api-data {
  margin-bottom: 12rem;
  font-weight: 700 !important;
}

.high-chart {
  width: 100%;
  height: 26rem;
}

.flex-calender {
  @include flex_row;
  padding-right: 1.2rem;
}

.export-pdf {
  background-color: var(--bosch-blue-50);
  color: var(--bosch-white);
  margin-bottom: 6px;
}

.searching {
  margin: 15px 0;
  background-color: $--bosch-light-gray;
  color: var(--bosch-white);
}

.export-pdf:hover {
  background-color: $--bosch-blue-68;
  cursor: pointer;
}

.search-icon {
  @include flexCustom(center, center);
  font-size: 1.7rem !important;
}

.statistics-line {
  border: none;
  height: 0.1rem;
  background-color: $--bosch--black-boxshadow;
  width: 100%;
}

.dropdown-field {
  width: 15rem;
}

.table-header {
  max-width: 100%;
  overflow-x: auto;
}

.margin-bottom__1 {
  margin-bottom: 1rem;
}

.api-static-table {
  table {
    th {
      text-transform: uppercase;
      white-space: break-spaces;
      line-height: 1.4;
    }
  }

  .value-style {
    color: $--bosch-black;
    cursor: default;
  }

  .no-data-available {
    text-align: center;
  }
}

.disable-click {
  pointer-events: none;
}

@media only screen and (max-width: 768px) {
  .statistic-header {
    height: 9.25rem;
  }

  .datefield-textbox {
    width: auto;
  }

  .date-form {
    margin-left: 0 !important;
  }
}
