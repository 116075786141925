@import '../../../stylesheet/variables.scss';
@import '../../../stylesheet/mixins.scss';

.step__stepper {
  padding: 0 !important;
  position: relative;
  display: flex;
}

.step_detail {
  padding: 2rem;
  position: relative;
}

.swagger__div {
  @include flexEnd;
}

.home_container {
  background-color: $--bosch-gray-71;
  padding: 0.625rem 1.4rem;

  @media only screen and (max-width: 768px) {
    padding: 0.625rem 0;
    background-color: $--bosch-white;
  }
}

.home-wrapper {
  display: flex;
  max-height: calc(100vh - 13rem);

  @media only screen and (max-width: 768px) {
    display: block;
  }

  &-navigation {
    @include flexCustom(flex-end, center);
    width: 100%;
  }
}

.home-wrapper-notification {
  max-height: calc(100vh - 17rem);

  @media only screen and (max-width: 768px) {
    height: calc(100vh - 23rem);
  }
}

.step-indicator-api {
  width: 30rem;
  z-index: auto;

  @media only screen and (max-width: 768px) {
    width: 100%;
    position: relative;
  }

  .step-indicator__stepper {
    pointer-events: none;

    @media only screen and (max-width: 768px) {
      display: none;
    }
  }
}

.step-indicator__details__header {
  text-align: left;
}

.home-wrapper__main-container {
  width: calc(100% - 16rem);
  border-radius: 0 0.3125rem 0.3125rem 0;
  background: var(--bosch-white);
  position: relative;

  @media only screen and (max-width: 768px) {
    width: unset;
    height: calc(100vh - 19rem);
  }
}

.home-wrapper__form__wrapper {
  padding: 2rem 2rem 6.1rem 2rem;
  height: calc(100vh - 16rem);
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: thin;

  @media only screen and (max-width: 768px) {
    height: calc(100% - 10rem);
    padding-bottom: unset;
  }

  .preview-wrapper {
    display: none;

    @media only screen and (max-width: 768px) {
      margin: 2rem 0;
      display: block;
    }
  }
}

.home-wrapper__form__actions_api_flow {
  position: absolute;
  width: calc(100% - 0.8rem);
  bottom: 0;
  z-index: 1;
  box-shadow: 0 -0.1875rem 0.375rem $--bosch--black-boxshadow;
  background-color: var(--bosch-white);
  justify-content: space-between;
  @include flexAlignCenter;
  padding: 0 1rem;

  @media only screen and (max-width: 768px) {
    width: 100%;
    position: unset;
  }
}

.breadcrumb__getstarted {
  position: relative;
  top: -1rem;
}

.bread-crumb__container__api {
  width: 20.5rem;
  margin-top: 8px;
  @media only screen and (max-width: 768px) {
    margin-top: 2rem;
  }

  a {
    color: $--bosch-blue-50 !important;
  }

  .disabled {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 8rem;
  }
}

.active {
  .step-indicator__active-pointer {
    display: block;
  }
}

.step-indicator__active-pointer {
  display: none;
}

.step-indicator__count {
  @include flexAlignCenter;
}

.step-indicator__details__responsive {
  display: none;

  @media only screen and (max-width: 768px) {
    display: block;
    width: 100%;
  }

  .step-indicator__details__activestep {
    display: none;

    @media only screen and (max-width: 768px) {
      @include flexSpaceBetween;
      font-size: 1.2rem;
      align-items: center;
      color: var(--bosch-white);
      padding: 0 1rem;

      h5 {
        font-weight: 300;
      }
    }

    .activestep-stepper-responsive {
      display: none;

      @media only screen and (max-width: 768px) {
        display: block;
        max-width: 15rem;
      }

      .activestep-stepper-customtooltip:hover:before {
        left: 0.125rem;
        bottom: 0;
      }
    }

    .activestep-stepper {
      @media only screen and (max-width: 768px) {
        display: none;
      }
    }
  }
}
