@import '../../../stylesheet/variables.scss';
@import '../../../variables.module.scss';

.custom-radio_button {
  margin: 1rem;
  input[type='radio'] {
    display: none;
  }
  &__label {
    position: relative;
    height: 7.6875rem;
    width: 13.375rem;
    @media screen and (max-width: 768px) {
      width: 90vw;
      margin: 0 auto;
      max-width: 30rem;
    }
    border: 0.125rem solid $--bosch-gray-70;
    border-radius: 1rem;
    display: flex;
    cursor: pointer;
  }
  &__text {
    font-size: 0.9rem;
    position: absolute;
    display: flex;
    justify-content: center;
    text-align: center;
    width: 13.375rem;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
    word-wrap: break-word;
    align-items: center;
    height: 100%;
    margin: auto;
    padding: 0.5rem;
    padding-right: 2rem;
    padding-left: 2rem;
  }
  &__label:before {
    position: absolute;
    height: 1.5rem;
    width: 1.5rem;
    border: 0.1875rem solid $--bosch-gray-70;
    content: '';
    border-radius: 50%;
    top: 0.5625rem;
    right: 0.7188rem;
  }
  input:checked ~ .custom-radio_button__label {
    border: 0.125rem solid $--bosch-blue-50;
  }

  input[type='radio']:checked ~ .custom-radio_button__label:before {
    border: 0.125rem solid $--bosch-white;
    background-color: $--bosch-blue-50;
  }
}
