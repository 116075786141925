@import '../../variables.module.scss';
@import '../../stylesheet/mixins.scss';
@import '../../stylesheet/variables.scss';

.my-api {
  width: 100%;
  @include flex_col;

  &_text {
    padding: 2rem 0 1rem 0;
    @include flexSpaceBetween;

    &_view-button {
      padding-right: 1rem;

      @media only screen and (max-width: 768px) {
        padding-right: unset;
      }
    }

    &_bold {
      font-weight: 700 !important;
    }

    @media only screen and (max-width: 768px) {
      padding: 2rem 1rem 1rem 1rem;
    }
  }

  &_list {
    width: 100%;
  }
}

.first-api {
  margin: 2rem;
  width: 100%;

  &_content {
    margin-top: 3.125rem;
    @include flex_col;
    align-items: center;

    &_text-adjustment {
      @include textAlignCenter;
      margin: 1rem;
      margin-top: 1.5rem;
    }

    &_button {
      margin-top: 1rem;
      @include textAlignCenter;

      & .o-context-menu.-visible .m-popover {
        bottom: 0;
      }
    }

    &_element {
      width: 22rem;
    }

    &_sales-rep-element {
      width: 15rem;
    }
  }
}

.sales-rep-back-container {
  height: 50px;
  padding-top: 20px;
}

.sales-rep-back-nav-icon {
  display: flex;
  cursor: pointer;
}

.sales-rep-back-nav-text {
  display: inline-block;
  padding-bottom: 10px;
  padding-left: 5px;
}

.sales-rep-header {
  height: 80px;
  background-color: rgb(240, 195, 111);
}

.sales-rep-header-text {
  font-weight: 600;
  padding: 30px 20px;
}

.sales-rep-infoicon {
  display: inline-block;
}

.sales-rep-managing-account {
  padding-left: 3px;
  display: inline-block;
  padding-bottom: 10px;
}
.products {
  height: 100%;

  .searchbar-icon {
    display: none;
  }

  @media only screen and (max-width: 768px) {
    padding: 0;

    .searchbar-hide {
      display: none;
    }

    .searchbar-show {
      position: absolute;
      left: 0%;
      top: 10rem;
      z-index: 9;
      width: 100%;
      padding: 1rem 1rem 0 1rem;
      background-color: $--bosch-concrete-solid;

      .product__search {
        width: 100%;
      }
    }

    .searchbar-icon {
      display: block;
    }

    &_list-category {
      right: calc(100% - 3rem);
    }
  }

  &_flex {
    @include flexCustom(space-between, center);
    margin-top: 2rem;
    flex: 0 0 4rem;

    &_item {
      padding-right: 0 !important;
    }

    &_items {
      @include flexSpaceAround;
      align-items: center;
    }

    @media only screen and (max-width: 768px) {
      margin: 2rem 0 0 1rem;
    }
  }

  &_grid {
    width: 100%;

    @media only screen and (max-width: 768px) {
      padding: 0;
    }

    &_searchbar_open {
      @media only screen and (max-width: 768px) {
        margin-bottom: unset !important;
        @include flex_col(space-between);
        height: 100%;
        margin-top: 2.5rem;
      }
    }
  }

  &_line {
    width: 100%;
    border: 1px solid $--bosch-white-20;
  }

  &_list-category {
    overflow: auto;
    border: 0.6px solid lightgray;
    border-width: 0.1px;
    box-shadow: 0rem 0 1rem $--bosch-shuttle-gray;
    right: 0rem !important;

    & > * {
      min-width: 13.7rem !important;
    }
    & > * > * {
      min-width: 13.7rem !important;
    }
    & > * > * > * {
      width: 13.7rem !important;
    }
  }

  &_list-category-auto-height {
    height: 13rem;
  }

  &_infinite-scroll {
    overflow: hidden !important;
  }
}

.search-bar {
  padding-right: 1rem;
}

.add-button-card {
  padding-right: 1rem;
  padding-left: 1rem;
}

.button-aligning {
  float: right;
}

.data-field_cards_scroll {
  display: grid;
  overflow-x: hidden;
  overflow-y: auto;
}

.height-seller-component {
  height: calc(100% - 85px);
}

.height-sales-rep-component {
  height: calc(100% - 185px);
}
