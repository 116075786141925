.o-context-menu.-visible .m-popover {
  display: block;
  z-index: 5;
}

.a-menu-item-disabled {
  cursor: not-allowed;
  pointer-events: none;
  padding: unset;

  &::before {
    display: none;
  }
}
