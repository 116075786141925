@import '../../../variables.module.scss';
@import '../../../stylesheet/variables.scss';
@import '../../../stylesheet/mixins.scss';

.plan {
  &__body {
    position: fixed;
    z-index: 3;
    inset: 0;
    background-color: $--bosch-gray-background-80;
  }

  &__plan-name {
    @media only screen and (max-width: 768px) {
      max-width: 15rem;
      &:hover:before {
        bottom: unset !important;
        left: unset !important;
      }
    }
  }

  &__dialog {
    width: 55%;
    position: absolute;
    background-color: var(--bosch-white);
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    cursor: default;

    @media only screen and (max-width: 768px) {
      width: 95%;
    }

    &__close {
      position: absolute;
      right: 0.5rem;
      top: 0.5rem;
    }
  }

  &__container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__header {
    display: flex;
    box-sizing: border-box;
    margin: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $--bosch-blue-50;

    @media only screen and (max-width: 768px) {
      justify-content: flex-start;
    }
  }

  &__title {
    font-family: $bosch-font-family-light;
    margin: 0.5rem 0;
    color: $--bosch-white;

    @media only screen and (max-width: 768px) {
      width: -webkit-fill-available;
      width: -moz-available;
      padding: 0 0.5rem;
      @include textAlignCenter;
    }
  }
  &__price {
    font-family: $bosch-font-family-bold;
    margin: 0.5rem 0;
    color: $--bosch-white;
  }

  &__content {
    padding: 0.75rem 1rem 0.75rem 1.25rem;
    display: block;
    overflow-y: auto;
    max-height: calc(95vh - 7.5rem);
    width: 100%;
    text-align: start;

    &__container {
      display: flex;
      flex-direction: column;

      &__title {
        font-family: $bosch-font-family-bold;
        font-weight: bold;
      }

      &__description {
        box-sizing: border-box;
        margin: 0;
        flex-direction: row;
        flex-basis: 100%;
        -webkit-box-flex: 0;
        flex-grow: 0;
        max-width: 100%;

        &__details {
          font-family: $bosch-font-family-general;
          margin-top: 0.625rem;
          word-break: break-all;
          cursor: default;
          margin-left: 0.3rem;
        }
      }
    }
  }
  &__table {
    &_container {
      @include flex_column;
      box-shadow: none;
      margin: 1rem 0;
      margin-bottom: 1.5rem;
      gap: 2rem;

      &-price {
        font-family: $bosch-font-family-general;
        font-weight: bold;
        padding-bottom: 0.5rem;
      }

      &_style {
        display: table;
        width: 100%;
        border-collapse: collapse;
        border: 0.0625rem solid $--bosch-black-background-04;
        padding: 0.325rem;

        &_head {
          display: table-header-group;
        }

        &_body {
          display: table-row-group;
        }

        &_row {
          color: inherit;
          display: table-row;
          vertical-align: middle;
          border-bottom: 1px solid $--bosch-gray-background-80;
        }

        &_content {
          font-family: $bosch-font-family-general;
          display: table-cell;
          vertical-align: inherit;
          border-bottom: 0.0625rem solid $--bosch-alto;
          text-align: left;
          padding: 0.5rem 1rem;
          background-color: $--bosch-gray-10;
          padding-left: 0.75rem;
        }

        &_details {
          font-family: $bosch-font-family-general;
          letter-spacing: 0.01071em;
          display: table-cell;
          vertical-align: inherit;
          border-bottom: 0.0625rem solid $--bosch-alto;
          text-align: left;
          color: $--bosch-black-background-87;
          padding: 0.2rem 0.2rem 0.2rem 0.7rem;
          overflow: visible;

          @media only screen and (max-width: 768px) {
            word-break: break-word;
          }
        }
      }
    }
  }

  &__details {
    box-sizing: border-box;
    display: flex;
    // flex-flow: row wrap;
    gap: 1rem;
    width: 100%;

    @media only screen and (max-width: 768px) {
      display: block;
    }

    &_section {
      display: grid;
      box-sizing: border-box;
      margin: 0;
      flex-direction: row;
      flex-basis: 100%;
      -webkit-box-flex: 0;
      flex-grow: 0;
      max-width: 100%;

      @media only screen and (max-width: 768px) {
        flex-basis: 50%;
        -webkit-box-flex: 0;
        flex-grow: 0;
      }

      &_card {
        margin: 0 0 1rem;
        background-color: var(--bosch-white);
        color: $--bosch-black-background-87;
        transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        overflow: hidden;
        border-radius: 0;
        border: 0.0625rem solid #d3d3d3;
        box-shadow: none;

        &_image {
          display: block;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center center;
          width: 100%;
          object-fit: cover;
          border-style: none;
        }

        &_division {
          padding: 1rem;

          li:first-of-type {
            counter-reset: item;
          }
          &_list {
            display: flex;
            -webkit-box-pack: start;
            justify-content: flex-start;
            -webkit-box-align: center;
            align-items: center;
            position: relative;
            text-decoration: none;
            width: 100%;
            box-sizing: border-box;
            text-align: left;
            padding: 0.05rem 0.4rem;
            list-style: none;
          }

          &_image {
            min-width: 2rem;
            flex-shrink: 0;
            display: inline-flex;
            gap: 0.5rem;

            .benefit_description {
              max-width: 21rem;
            }
          }
          &_img {
            width: 1.6rem;
          }

          &_description {
            @include flexCustom(flex-start, flex-start);
            flex-direction: column;
            flex: 1 1 auto;
            margin: 0.25rem 0;
            font-family: $bosch-font-family-light;
            gap: 0.5rem;

            .description__customtooltip:hover:before {
              word-wrap: break-word;
              max-width: calc(100% - 2rem) !important;

              @media only screen and (max-width: 768px) {
                left: 1.125rem !important;
              }
            }

            .description__customtooltip:hover::before {
              bottom: unset !important;
            }
          }

          &_benefits {
            @include flexCustom(flex-start, flex-start);
            flex: 1 1 auto;
            margin: 0.25rem 0;
            font-family: $bosch-font-family-light;
            gap: 0.5rem;
          }
        }
        &_division:last-child {
          padding-bottom: 1.5rem;
        }
        &_title {
          font-family: $bosch-font-family-bold;
          font-weight: bold;
        }
      }
    }

    &__addl-config {
      &__title {
        font-weight: bold;
        font-size: 1rem;
      }
      &__desc {
        font-size: 1rem;
        padding-bottom: 0.8rem;
      }
    }
  }
}

.plan-details-title {
  color: $--bosch-black;
}
