@import '../../stylesheet/variables.scss';

.a-button--primary {
  outline: 1px solid $primary_color;

  &:disabled {
    outline: 1px $--bosch-silver-sand;
    border-bottom-style: solid;
  }
}

