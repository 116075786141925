.welcome-banner {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 10px;
  overflow: hidden;
  @media only screen and (max-width: 900px) {
    grid-template-columns: 1fr;
  }
}

.welcome-banner-strap {
  height: 1px;
  background-color: #c5c5c5;
}

.welcome-message {
  display: flex;
  align-items: center;
  padding-left: 150px;
  padding-right: 30px;
}

.image-container {
  height: calc(100vh - 110px);
  display: flex !important;
  align-items: center;
}
.welcome-image {
  width: 100% !important;
  margin-left: -30px;
}

.welcome-image-background {
  width: 100%;
  height: calc(80vh + 340px);
  background-color: #007bc0;
  display: flex;
  align-items: center;
}
@media only screen and (min-width: 600px) and (max-width: 900px) {
  .welcome-image-background {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    width: 100vw;
    border-radius: 35% 35% 0 0;
    background-color: #007bc0;
    max-width: 100%;
    margin-top: 30rem;
  }
  .welcome-message {
    margin-bottom: 10% !important;
    padding: 0 !important;
  }
  .welcome-image {
    width: 80% !important;
    margin: 0 auto;
  }
  .image-container {
    height: 50%;
    min-height: 600px;
  }
}
@media only screen and (max-width: 600px) {
  .welcome-message {
    margin-bottom: 5% !important;
    padding: 0 !important;
  }

  .image-container {
    height: 28rem;
    position: relative;
    top: 10%;
    min-height: 450px;
  }

  .welcome-image-background {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 28rem;
    width: 65em;
    background-color: #007bc0;
    border-radius: 50% 50% 0 0;
  }

  .welcome-image {
    width: 45% !important;
    height: 100% !important;
    margin: 0 auto !important;
  }
}
