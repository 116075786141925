@import '../../stylesheet/mixins.scss';
@import '../../stylesheet/variables.scss';

.setting {
  &__container {
    max-width: 45% !important;
  }

  &__box-container {
    padding-left: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;

    &__box {
      display: flex;
      align-items: center;
      margin-top: 1rem;

      &__access-key {
        position: relative;

        &__input {
          width: 31rem !important;
        }

        &__icon {
          border: 0;
          position: absolute;
          cursor: pointer;
          right: 8rem;
          background-color: $--bosch-gray-71;
          width: 3rem;
          height: calc(3rem - 0.0625rem);
          padding-top: 0.125rem;
          display: inline-flex;
          align-items: center;
          justify-content: center;
        }
      }

      &__generate {
        margin-left: 1rem;
      }

      &__copy-img {
        width: 3rem;
        margin-left: 1rem;
        cursor: pointer;
      }
    }

    &__infocontent {
      margin-top: 0.5rem;
      width: 31rem;
    }
  }
}

.img-size {
  width: 2rem;
  cursor: pointer;
}
