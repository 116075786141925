// Component Height
$header-ht: 64px;
$footer-ht: 5rem;

// Component Color
$primary_color: var(--major-accent__enabled__fill__default);
$--bosch-white: var(--bosch-white);
$--bosch-black: var(--bosch-black);
$--bosch-blue-50: var(--bosch-blue-50);
$--bosch-gray-70: var(--bosch-gray-70);
$--bosch-gray-71: #e8e8e8;
$--bosch-silver-sand: #bfc0c2;
$--bosch-concrete-solid: #f2f2f2;
$--bosch-shuttle-gray: #525f6b;
$--bosch-powder-ash: #c8d3cd;
$--bosch--black-boxshadow: rgba(0, 0, 0, 0.12);
$--bosch--light-orange: rgb(255, 227, 212);
$--bosch-gray-background-80: rgba(129, 126, 126, 0.8);
$--bosch-black-background-04: rgba(0, 0, 0, 0.04);
$--bosch-black-background-08: rgba(0, 0, 0, 0.08);
$--bosch-black-background-09: rgba(0, 0, 0, 0.09);
$--bosch-black-background-43: rgba(0, 0, 0, 0.43);
$--bosch-black-background-87: rgba(0, 0, 0, 0.87);
$--bosch-black-background-54: rgba(0, 0, 0, 0.54);
$--bosch-black-background-20: rgba(0, 0, 0, 0.2);
$--bosch-black-background-10: rgba(0, 0, 0, 0.1);
$--bosch-black-background-12: rgba(0, 0, 0, 0.12);
$--bosch-alto: rgb(224, 224, 224);
$--bosch-solitude: #e9f7ff;
$--bosch-red: #ff0000;
$--bosch-grey: #808080;
$--bosch-orange: #fb2828;
$--bosch--light-blue: #a5d3d3;
$--bosch--light-blue-2: #cfecfe;
$--bosch--light-grey: #c9c9c9;
$--bosch-white-10: #efeff0;
$--bosch--black-boxshadow: rgba(0, 0, 0, 0.12);
$--bosch-white-20: #e0e2e5;
$--bosch-blue-90: #d1e4ff;
$--bosch-blue-96: #336699;
$--bosch-blue-97: #0a7bc0;
$--bosch-green-35: #7fbe78;
$--bosch-blue-66: #02abcc;
$--bosch-gray-75: #a3a3a3;
$--bosch-blue-68: #005691;
$--bosch-lightgrey-67: #e8e8e8;
$--bosch-green-37: #70bf54;
$--bosch--blue-53: #0a74bd;
$--bosch--gray-76: #707070;
$--bosch--gray-56: #f8f8f8;
$--bosch--gray-59: #d3d4d5;
$--bosch--gray-53: #bbb;
$--bosch--gray-73: #a5a5a5;
$--bosch--gray-75: #d1e4ef;
$--bosch--gray-85: #5a5a5a;
$--bosch--gray-83: #7f8182;
$--bosch--gray-84: #a8a8a8;
$--bosch--black-03: #333333;
$--bosch-floral-blue: #f3fbff;
$--bosch-light-gray: #d3d3d3;
$--bosch-green-36: #edf7ed;
$--bosch-gray-10: #e4e4e4;
$--bosch-red-76: #e01515;
$--bosch-blue-88: #008ecf;
$--bosch-yellow-79: #ffeda7;
$--bosch-yellow-85: var(--bosch-yellow-85);
$--bosch-success-light: #b8efc9;
$--bosch-success-main: #00884a;
$--bosch-danger-dark: #ed0007;
$--bosch-danger-light: #ff8787;
$--bosch-warning-dark: #8f7300;
$--bosch-warning-light: #ffefd1;
$--bosch-secondary-dark: #b2b9c0;
$--bosch-secondark-light: #eff1f2;
$--bosch-light-apricot: #ffe3d4;
$--bosch-badge-main: #d4efff80;
$--bosch-gray-50: #71767c;
$--bosch--gray-86: #d0d4d8;
$--bosch-green-90: #b8efc9;
$--bosch-pale-yellow: #fffbec;
$--bosch-red-50: #ffb2b2;
$--bosch-gray-90: #e0e2e5;
$--bosch-gray-80: #c1c7cc;
$--bosch-green-85: #9be4b3;
$--bosch-green-95: #e2f5e7;
$--bosch-red-95: #ffecec;
