@import '../../../../../src/stylesheet/variables.scss';

.basic-plan {
  margin-top: 2rem;
}

.new-pricing-plan {
  display: block;
  @media screen and (max-width: 768px) {
    margin-left: 0;
    padding: 0 1.6rem 0 1rem;
  }

  &__list {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
  }

  &__input {
    width: 30.950625rem;
    height: 3.6875rem;
    margin-bottom: 2.5rem;

    &-area {
      margin-bottom: 2.5625rem;
    }
  }

  &__enter-plan {
    &-text {
      margin-top: 1rem;
      font-size: 0.875rem;
    }

    &-subheading {
      margin-top: 0.5rem;
      font-size: 0.75rem;
      color: $--bosch-silver-sand;
      margin-bottom: 1.5rem;
    }
  }

  .add-company-id {
    display: flex;
    flex-direction: row;
    column-gap: 1rem;
    > .home-wrapper__form__input {
      flex-grow: 1;
    }
    > button {
      align-self: start;
      > .a-button__label {
        font-size: 2rem;
        padding: 0px;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .add-pricing-plan {
    flex-wrap: wrap;
    justify-content: center;

    &__input {
      width: calc(100% - 2rem);
      &-area {
        width: calc(100% - 2rem);
      }
    }
  }
}
