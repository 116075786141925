@import '../../../../stylesheet/variables.scss';
@import '../../../../variables.module.scss';
@import '../../../../stylesheet/mixins.scss';

.contact-sales {
  border: 1px solid $--bosch--gray-73;
  width: 16.375rem;
  height: 20.75rem;
  padding: 1rem;
  position: relative;
  &__container {
    @include flexCustom(space-between, center);
    flex-direction: column;
    height: 100%;

    .container {
      &-title {
        font-weight: bold;
        font-size: 1.25rem;
        line-height: 1.6;
        font-family: $bosch-font-family-general;
        text-align: center;
      }

      &-img {
        width: 12rem;
        height: 12rem;
      }
    }
  }
}
