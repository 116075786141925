@import '../../../stylesheet/variables.scss';

.category-tag {
  width: max-content;
  padding: 0.2rem 0.4rem;
  border-radius: 3rem;
  background: $--bosch-solitude 0% 0% no-repeat padding-box;
  font-weight: 400;
  text-transform: capitalize;
  text-align: center;
}
