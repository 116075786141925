@import '../../../variables.module.scss';
@import '../../../stylesheet/variables.scss';
@import '../../../stylesheet/mixins.scss';

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(5px);
}

.popup-content {
  background: white;
  padding: 20px;
  position: relative;
  text-align: center;
  max-width: 50rem;
  width: 80%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.popup-image {
  width: 150px;
  height: 150px;
  object-fit: cover;
}

.popup-header {
  font-size: 20px;
}
.popup-text-header {
  margin-top: 10px;
  font-size: 20px;
  font-weight: normal;
  color: black;
}

.popup-text {
  margin-top: 10px;
  font-size: 13px;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  color: black;
  text-align: center;
}

.access-account-signout-button {
  background: none;
  color: var(--bosch-black);
  cursor: pointer;
  margin-top: 1.3rem !important;
  width: 20% !important;
  @include flexCustom(center, center);
}

@media only screen and (max-width: 500px) {
  .access-account-signout-button {
    width: 40% !important;
  }
}

.logout-icon {
  padding-right: 0.45rem;
}
