.arrow {
  &__img {
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
  }

  &__pop {
    text-align: left;
  }

  &__column {
    border: 0.0625rem solid;
  }

  &__box {
    width: 0rem;
    height: 0rem;
  }
}
