@import '../../../../stylesheet/variables.scss';
@import '../../../../stylesheet/mixins.scss';

.quotation-card {
  padding: 1rem;

  &__title {
    margin: 1rem 0 1rem;
  }

  &__overview {
    border: 0.06rem solid $--bosch-gray-71;

    &__container {
      @include flexAlignCenter();
      gap: 1.5rem;
      padding: 1rem;
    }
  }

  &__category-wrapper {
    background: $--bosch-light-apricot;
    margin-top: 0.3rem;
    border-radius: 3.06rem;
    width: fit-content;
    padding: 0 0.4rem;
  }

  &__form {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr;

    @media only screen and (max-width: 768px) {
      grid-template-columns: 1fr;
    }

    grid-gap: 1.5rem 2rem;
    padding: 1rem;

    &__data {
      color: $--bosch--light-grey;
      word-break: break-word;
    }

    &__container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 1rem;
    }
  }

  &__dash {
    display: flex;
    align-items: center;

    &__line {
      border-bottom: 1px dotted;
      width: 50%;
    }

    &__or {
      text-align: left;
      padding: 0 0.5rem 0 0.5rem;
      color: $--bosch-black;
    }
  }

  &__total-cost {
    padding: 1rem;
    width: 50%;
  }

  &__document-wrapper {
    padding: 0rem 1rem 1rem;
  }

  &__documents {
    display: flex;
    overflow-x: auto;
    padding-bottom: 0.5rem;
    overflow-y: hidden;
  }

  &__document-title {
    margin-bottom: 0.5rem;
  }

  &__disclaimer {
    @include flexAlignCenter();
  }

  .notification {
    @include flexCustom(space-between, center);
    .notification__wrapper {
      @include flexAlignCenter();
    }
  }

  &__navigation-buttons {
    margin-top: 2rem;
  }

  .upload__list_spec {
    height: 4rem;
    margin: 0;
    margin-top: 0.5rem;
    align-items: center;
  }

  &__navigation-text {
    display: flex;
    padding: 0.5rem;
    justify-content: center;
    color: $--bosch-gray-50;
    margin-top: 0.5rem;
  }
}
