@import '../../../../../stylesheet/mixins.scss';
.confirm__modal__button {
  @include flexEnd;
  position: absolute;
  bottom: 1rem;
  right: 2rem;

  button {
    margin: 0 0 0 1rem;
  }
}

.confirmation__modal__revamped__body {
  .confirm-dialog {
    @include flexCenter;
    flex-flow: column;

    @media screen and (max-width: 768px) {
      height: auto;
    }
  }
}

.confirmation__modal__revamped__height {
  .dialog_modal__message {
    display: none;
  }
}

.confirm-dialog {
  padding: 2rem;
}

.confirm-dialog__content {
  padding-bottom: 1rem;
}
