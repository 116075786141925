@import '../../../../../stylesheet/mixins.scss';
@import '../../../../../stylesheet/variables.scss';

.multi_select_field {
  position: relative;

  input#multiselect::placeholder {
    color: var(--bosch-gray-40) !important;
    opacity: 1;
  }

  .optionContainer {
    margin-top: 0.3rem;
    padding: 0.5rem;
    border: 0.0625rem solid var(--bosch-gray-70);
    border-radius: 0.3rem;
    max-height: 12rem;
    position: absolute;
    width: 100%;
    z-index: 1;
    background: var(--bosch-white);
    overflow: auto;

    .option {
      &:hover {
        background: unset;
        color: var(--bosch-black);
      }

      input {
        margin-top: 0;
      }
    }
  }

  input {
    margin-bottom: 0;
    padding: 0;
    color: var(--bosch-black);
  }

  label {
    position: absolute;
    left: 1rem;
    font-size: 0.75rem;
    max-width: calc(100% - 4rem);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    z-index: 1;
    margin: 0.25rem 0rem auto;
  }

  .searchWrapper {
    padding: 0;
  }

  .multiple__div {
    background-color: unset !important;
    border-bottom: unset !important;
  }
}

.description__content {
  @include flexAlignCenter;
  justify-content: space-between;
}

.custom_description {
  display: flex;
  justify-content: flex-end;
  margin-top: 0;
  margin-bottom: 1.2rem;
  color: $--bosch-silver-sand;
}

.margin_top_bottom {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.margin_bottom {
  margin-bottom: 2.5rem;
}

.formmodal__title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;

  img {
    width: 1.4rem;
    height: 1.4rem;
    align-items: center;
    cursor: pointer;
  }
}

.modal__form_group {
  label {
    left: 1rem;
  }
}

.endpoint__form {
  position: relative;

  &__dropdown {
    position: relative;
  }

  &__description {
    @include flexAlignCenter;
  }

  .arrow-right {
    float: right;
    position: relative;
    top: -1.5rem;
    right: -0.65rem;

    img {
      width: 1.456rem;
    }
  }

  .multi_select_icon {
    width: 1.2rem;
    height: 1.2rem;
    margin-right: 0.5rem;
    margin-top: 0.5rem;
  }
}

.endpoint_form_relative {
  display: flex;

  .relative_path_icon {
    width: 1.2rem;
    height: 1.2rem;
    margin-right: 0.5rem;
    margin-top: 0.4rem;
  }
}

.path_relative__description {
  margin-bottom: 0;
}

.input__additional-info {
  margin-top: 0.4rem;
  color: $--bosch-silver-sand;
}

.disable__setting {
  pointer-events: none;
}

.action__grid__button {
  position: relative;
  top: -1.5rem;
}

.apiflow__sidebar__form {
  .navigation-buttons {
    display: none;
  }
}

.Secret.Headers {
  max-width: 43rem;

  .side-bar {
    padding-right: 2rem;

    @media only screen and (max-width: 768px) {
      padding: 0 2rem;
    }
  }
}

.Endpoint {
  max-width: 50rem;

  .side-bar-footer {
    max-width: 50rem;
  }

  .side-bar {
    padding-right: 2rem;
    padding-bottom: 2rem;

    @media only screen and (max-width: 768px) {
      padding-left: 2rem;
    }
  }

  .side-bar-header {
    position: unset !important;
  }

  .side-bar-children-components {
    padding-top: 2rem;
  }
}

.alert-danger {
  background: var(--minor-signal-error__enabled__fill__default);
  @include flexAlignCenter;
  padding: 0.5rem;
  margin-bottom: 1rem;

  img {
    width: 1.5rem;
    margin-right: 0.5rem;
  }
}

.form_input {
  margin-bottom: 1.2rem;
}

.select-options {
  max-width: 280px;
  border-radius: 0;
}

.chargeable-section-container {
  padding-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.chargeable-section-wrapper {
  .response-code-heading {
    font-size: 16px;
  }
  .response-code-caption {
    font-size: 12px;
    color: #bfc0c2;
  }
}
.http-code-table-container {
  padding-top: 1rem;
}

.info-text-no-data {
  display: flex;
  margin-top: 10px;
}
