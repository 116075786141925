@import '../../../stylesheet/variables.scss';

$font-color: #bfc0c2;

@mixin flex_row($justify: flex-start, $align: flex-start) {
  display: flex;
  justify-content: $justify;
  align-items: $align;
}

@mixin flex_col($justify: flex-start, $align: flex-start) {
  display: flex;
  flex-direction: column;
  justify-content: $justify;
  align-items: $align;
}

.upload {
  @include flex_row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
  cursor: pointer;

  &__error {
    display: block;
    grid-column-start: 1;
    margin-top: 0.5rem;
  }

  &__user {
    @include flex_col($justify: none, $align: center);
    border: 0.0625rem dashed $font-color;
    padding: 2rem;
    height: 12.7rem;
    @media only screen and (max-width: 768px) {
      &_container {
        height: 22.25rem;
      }
      width: 100%;
    }
  }
  &__image {
    @include flex_col($justify: center, $align: center);
    border: 1px dashed $font-color;
    width: 13rem;
    min-width: 13rem;
    height: 14rem;
    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }
  &__video {
    @include flex_col($justify: center, $align: center);
    border: 0.0625rem dashed $font-color;
    width: 13rem;
    height: 14rem;
  }
  &__thumb {
    border: 0.0625rem dashed $font-color;
    width: 12rem;
    height: 12rem;
    border-radius: 50%;
    flex-direction: column;

    @media only screen and (max-width: 768px) {
      margin: 0 auto;
    }
  }

  &__cover {
    width: 25rem;
    height: 12.8125rem;
    border-radius: 0;
  }

  &__attachment {
    @include flex_row($justify: center, $align: center);
    width: 9.375rem;
    height: 3rem;
    background: $--bosch-secondark-light 0% 0% no-repeat padding-box;
  }

  &__label {
    text-align: center;
  }

  &__list {
    @include flex_row;
    margin: 1rem;
  }

  &__doc_listing {
    display: flex;
    flex-direction: column;
  }

  &__media_listing {
    @include flex_row;
    overflow-x: auto;
    overflow-y: hidden;
    column-gap: 1rem;
  }

  &__img_listing {
    @include flex_col($justify: center, $align: center);
    width: 13rem;
    height: 14rem;
    position: relative;
    @media only screen and (max-width: 768px) {
      margin-left: 0;
      margin-top: 1.5rem;
    }
  }

  &__attach_view {
    position: relative;
    width: 35rem;
    height: 19.75rem;
    margin: 1rem 0;
  }

  &__list_spec {
    width: 100%;
    height: 4rem;
    margin: 0;
    margin-top: 0.5rem;
    align-items: center;
  }

  &__list_file {
    @include flex_row($justify: flex-start, $align: center);
    flex: 0 0 calc(100% - 2rem);
    height: 4rem;
    background-color: $--bosch-white-10;
    cursor: pointer;
    @media only screen and (max-width: 768px) {
      width: 100%;
    }
    &_name {
      font-size: 1rem;
    }
  }
  &__list_img {
    @include flex_row($justify: flex-start, $align: flex-end);
    width: 100%;
    height: 100%;
    margin: 0;
    cursor: pointer;
  }

  &__list_doc {
    width: 2.5rem;
    height: 2.5rem;
    margin: 0 0.75rem 0 1rem;
  }

  &__thumb_prev {
    width: inherit;
    height: inherit;
    object-fit: cover;
    overflow: hidden;
  }

  &__cover_prev {
    width: inherit;
    height: inherit;
    object-fit: cover;
    overflow: hidden;
  }

  &__attach_prev {
    width: inherit;
    height: inherit;
    object-fit: cover;
    overflow: hidden;
  }

  &__list_image {
    width: 13.5625rem;
    height: 14.1875rem;
  }

  &__notes_mob {
    display: none;
    margin: 1rem 0;
  }
  &__add_icon {
    width: 4rem;
    margin: 1rem;
    background-color: #007bc0;
    border-radius: 50%;
  }
  &__paper_clip {
    width: 1.5rem;
    height: 1.4375rem;
  }
}
