@import '../../../stylesheet/variables.scss';
.document-body {
  max-width: calc(100vw - 4rem);
  max-height: calc(100vh - 2.5rem);
  padding-top: 5rem;
  padding-bottom: 4rem;
  margin: 0 auto;
  // background: var(--bosch-white);
  @media screen and (min-width: 590px) and (max-width: 960px) {
    overflow: auto;
    padding-bottom: 10rem;
  }

  @media only screen and (max-width: 768px) {
    max-width: 100vw;
    padding-bottom: unset;
    padding-top: unset;
  }
}
.document-body-container {
  background: $--bosch-concrete-solid;
  &__bosch-strip {
    position: fixed;
  }
  .CollapsableSideBar-appBar-6 {
    z-index: 1;
  }
}
