.add-publish {
  &__container {
    width: 38% !important;
    max-width: 38rem !important;

    &__step {
      margin-top: 1.5rem;
    }

    &__card {
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin-top: 3rem;
    }

    &__error {
      margin-left: 0.8rem !important;
      margin-right: 1rem !important;
    }

    .page__body_default {
      margin-top: 1rem;
    }
  }
}
