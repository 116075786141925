@import '../../../../variables.module.scss';
@import '../../../../stylesheet/mixins.scss';
@import '../../../../stylesheet/variables.scss';

.media {
  padding: 1rem;
  width: 8.75rem;
}

.list-item-card {
  @include flexCenter;
  width: 6.45rem;
  height: 2.25rem;
  background: $--bosch-black-background-09 0% 0% no-repeat padding-box;
  border-radius: 2.5rem;
  opacity: 1;
  margin: 0.67rem 0;
  margin-right: auto !important;

  p {
    font-weight: 600 !important;
  }
}

.approved-card {
  color: var(--bosch-green-50);
  background-color: var(--bosch-green-90);
}

.rejected-card {
  color: var(--bosch-red-50);
  background-color: var(--bosch-red-80);
}

.published-card,
.submited-card {
  color: var(--bosch-blue-50);
  background: $--bosch--gray-75 0% 0% no-repeat padding-box;
}

.raised {
  box-shadow: 0 0.32rem 0.63rem $--bosch-black-background-20;
}

.card {
  display: block;
  width: 100%;
  border: 0.09rem solid #e9e9e9;
  position: relative;
  background-color: var(--bosch-white);
}

.product-logo-wrapper {
  position: absolute;
  top: 1.67rem;
  left: 16%;
  transform: translateX(-2.3125rem);
  border-radius: 50%;
  width: 3.9rem;
  height: 3.9rem;
  background: var(--bosch-white);
}

.product-logo-poster {
  display: block;
  border-radius: 50%;
  width: 3.9rem;
  height: 3.9rem;
  object-fit: contain;
  border: 0.5px solid $--bosch--gray-86;
}

.card-block-wrap {
  margin-top: 3.7rem;
  opacity: 0.4;
}

.sales-rep-icon {
  position: absolute;
  left: 85%;
  right: 1rem;
  top: 0.8rem;
}
.item-text {
  color: $--bosch--gray-73;
  font-size: 1rem;
}

.item-name {
  text-align: left;
  font-family: $bosch-font-family-general;
  letter-spacing: 0;
  color: var(--bosch-black);
  opacity: 1;
  @include text-ellipsis;
  max-width: 14.45rem;
}

.item-description {
  color: var(--bosch-black);
  overflow: hidden;
  text-overflow: ellipsis;
  height: 3rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-break: break-word !important;
  -webkit-line-clamp: 2;
}

.styled-icon {
  width: 11.45rem;
  height: 2.25rem;
  background: $--bosch--gray-75 0% 0% no-repeat padding-box;
  border-radius: 2.5rem;
  opacity: 1;
  margin: 0.67rem 0 0.67rem 0;
}

.styled-text {
  @include textAlignCenter;
  padding: 0.5rem;
  letter-spacing: 0;
  color: var(--bosch-blue-50);
  opacity: 1;
  font-weight: 600 !important;
}

.styled-icon-drafted {
  width: 5.45rem;
  height: 2.25rem;
  background: $--bosch-black-background-09 0% 0% no-repeat padding-box;
  border-radius: 2.5rem;
  opacity: 1;
  margin: 0.67rem 0 0.67rem 0;
}

.styled-drafted-text {
  @include textAlignCenter;
  padding: 0.5rem;
  letter-spacing: 0;
  opacity: 1;
  font-weight: 600 !important;
}

.item-name-wrap {
  margin: 0.2rem 0 0.4rem 0;
}

.item-description-wrap {
  margin: 0.67rem 1rem 0.67rem 0;
}

.item-published {
  text-align: left;
  font-family: $bosch-font-family-general;
  letter-spacing: 0;
  color: $--bosch--gray-73;
  opacity: 1;
  margin: 0.67rem 0 0.67rem 0;
}

.right-arrow {
  font-size: 2.2rem !important;
  color: var(--bosch-blue-50) !important;
}

.arrow-icon-published {
  @include flexSpaceBetween;
  flex-direction: row;
}

.arrow-icon-placement {
  @include flexAlignCenter;
  margin-right: 0.67rem;
  cursor: pointer;
}

.options {
  font-size: 2.4rem !important;
}

.card-api-content {
  @include flex_row;

  &_item {
    border: 1px solid $--bosch-white-20;
    margin-right: 0.5rem;
    padding: 0.13rem;
    font-size: 0.9rem;
  }
}

.popup-styling {
  position: absolute;
  top: 7rem;
}

.disable-product-card {
  .more-options-wrap {
    pointer-events: none;
    color: $--bosch--light-grey;
  }
  .arrow-icon-placement {
    pointer-events: none;
    .a-icon {
      color: $--bosch--light-grey !important;
    }
  }
}
