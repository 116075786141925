$sideBarWidth: 63rem !default;
$footerHeight: 4.375rem !default;
$screenWidth: 100vw !default;

.side-bar-background {
  //To grey out area behind sidebar
  width: 100%;
  height: 100%;
  max-height: 100vh;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba($color: #000000, $alpha: 0.3);
  transition-delay: 2000ms;
  transition-timing-function: ease-in-out;
  z-index: 3;
}

.side-bar-container {
  background-color: #ffffff;
  height: calc(100% - $footerHeight);
  position: absolute;
  width: 100%;
  max-width: $sideBarWidth;
  right: 0;
  top: 0;
  overflow-y: auto;
  padding: 0;
  padding-top: 1rem;

  .side-bar {
    padding-left: 2rem;
    max-width: $sideBarWidth - 3rem;

    @media screen and (max-width: 768px) {
      padding-left: 0.5rem;
    }

    &-children-components {
      padding-top: 4rem;
    }
    &-header {
      font-size: 1.25rem;
      font-weight: 700;
      position: fixed;
      right: 0;
      width: 100%;
      max-width: $sideBarWidth;
      background-color: white;
      // Reduced margin and increased padding so
      //width does not overflow in responsive design
      margin-top: -1rem;
      padding-top: 1rem;
      margin-left: -2rem;
      padding-left: 2rem;
      z-index: 2;

      @media screen and (max-width: 768px) {
        padding-left: unset;
      }

      &__label {
        margin-left: 1rem;
      }

      &__close-icon {
        position: absolute;
        right: 1rem;
        top: 1rem;
        cursor: pointer;
        width: 1.5rem;
        height: 1.5rem;
      }
    }

    &-header-divider {
      margin-left: -2rem;
      width: calc(100% + 6rem);
      margin-top: 1rem;
      border-top: 0px solid #e0e2e5;
    }

    &-footer {
      position: fixed;
      background-color: white;
      width: 100%;
      max-width: $sideBarWidth;
      height: $footerHeight;
      padding: 0rem;
      bottom: 0;
      right: 0;
      box-shadow: 0px -3px 6px #0000001a;

      &__buttons {
        position: absolute;
        right: 1rem;
        bottom: 0.5rem;
      }

      &__button {
        margin-left: 1rem;
        margin-right: 1rem;
      }
    }
  }
}
