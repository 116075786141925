@import '../../../../stylesheet/mixins.scss';
@import '../../../../stylesheet/variables.scss';
@import '../../../../variables.module.scss';

.table__api__revamp__pagination {
  @include flexSpaceBetween;

  &__button {
    @include flexAlignCenter;
  }

  button {
    border: unset;
    cursor: pointer;
    background-color: unset;
  }

  @media only screen and (max-width: 768px) {
    margin-top: 1rem;
  }
}

.table__api__revamp__pagination__lefticon {
  width: 1.6rem;
  display: flex;
}

.table__api__revamp__pagination__righticon {
  width: 1.9rem;
  display: flex;
}

.api-table {
  overflow-x: initial !important;

  table {
    table-layout: auto;
  }

  @media only screen and (max-width: 768px) {
    overflow-x: auto !important;
  }
}

.m-table__container {
  @media only screen and (max-width: 768px) {
    overflow-x: scroll;
    display: table;
    table-layout: auto;
  }

  td {
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 12rem;

    .api-table_tooltip {
      line-height: 1.5;

      &:hover:before {
        text-overflow: initial;
        white-space: normal;
        overflow: auto;
        bottom: 0;
        height: auto;
        left: 1.5rem;
        min-height: 2rem;
        max-height: 6rem;
        overflow-y: revert;
        max-width: 100% !important;
      }
    }

    a {
      cursor: pointer;
    }
  }

  th {
    white-space: nowrap;
  }
}

.disable__navigation {
  pointer-events: none;
  opacity: 0.5;
}

table {
  table-layout: fixed;
}

th.Parameters {
  width: 12rem;
}

.text-input-dropdown {
  cursor: pointer;
  border: 1px solid #eee;
  padding-top: 0.5rem;
  padding-right: 1.8rem;
  padding-left: 0.5rem;
  background-position: right;
  background-repeat: no-repeat;
  background-color: #eee;
  background-size: 18px;
  width: 100%;

  @media only screen and (max-width: 768px) {
    padding-top: 0;
    background-size: 1.2rem;
  }
}
.text-input-dropdown:focus-visible {
  outline: none;
  border: none;
}
.dropdown-option-container {
  display: flex;
  flex-direction: column;
  background: #e0e2e5;
  padding: 0.5rem;
  padding-left: 16px;
  cursor: pointer;
  position: absolute;
  overflow-y: scroll;
  width: 60%;
  max-height: 124px;
  margin-bottom: 1rem;
  right: 140px;
  text-align: left;
  label {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.8;
    cursor: pointer;
    input {
      vertical-align: middle;
      margin-right: 0.5rem;
    }
  }
  :hover {
    background-color: #ccc;
  }
}
.action-button-container {
  display: flex;
  align-items: center;
}

.select-field-border {
  border: none;
}
.class {
  width: 15%;
}

th.response.code {
  text-align: center;
  width: 65%;
}

.response-code-container {
  background-color: #eee;
}
.no-data-text {
  text-align: center;
}
.response-code-content {
  white-space: pre-wrap;
  word-break: break-word;
}
.save-icon {
  position: relative;
}

.icon-color {
  filter: invert(0%) sepia(95%) saturate(21%) hue-rotate(2deg) brightness(101%)
    contrast(107%);
}

.icon-disabled-color {
  filter: invert(64%) sepia(12%) saturate(198%) hue-rotate(172deg) brightness(87%)
    contrast(93%);
}
