@import '../../../../stylesheet/mixins.scss';
@import '../../../../stylesheet/variables.scss';

.column {
  &__map {
    width: 80%;

    @media only screen and (max-width: 768px) {
      width: 100%;
      margin-top: 1.5rem;
    }
  }

  &__download__file {
    display: flex;
  }

  &__video__card__section {
    border: 0.0625rem dashed $--bosch-silver-sand;
    text-align: center;
    @include flexAlignCenter;
    width: 13rem;
    min-width: 13rem;
    justify-content: center;
    height: 18rem;
  }

  &__video__upload {
    width: 80%;
  }

  &__documentation {
    margin-top: 1.5rem;
  }

  &__map__files {
    @include flexSpaceBetween;
    background-color: var(--bosch-gray-95);
    margin-left: 1rem;
    margin-bottom: 1.5rem;
    @include flexAlignCenter;

    @media only screen and (max-width: 768px) {
      width: 100%;
      margin-left: 0;
    }
  }

  &__text {
    padding: 0.625rem;
    @include flexAlignCenter;
  }

  &__supported-formats {
    font-size: 0.75rem !important;
    text-align: center !important;
  }

  &__added-images {
    height: 3.5rem;
    width: 3.5rem;
    background-color: var(--bosch-blue-50);
    border-radius: 50%;
  }

  &__card-image {
    height: 2rem;
    width: 2rem;
    cursor: pointer;

    @media only screen and (max-width: 768px) {
      height: 1.5rem;
      width: 1.5rem;
    }
  }

  &__download-image {
    height: 2.5rem;
    width: 1.875rem;
  }

  &__add-icon-color {
    color: var(--bosch-gray-72);
    border: 0.1rem solid var(--neutral__enabled__fill__default);
    border-radius: 50%;
    width: 1.65rem;
  }

  &__document-image {
    height: 2.5rem;
    width: 2.5rem;
  }

  &__position {
    @include flexAlignCenter;
    justify-content: center;
    margin-bottom: 1rem;
  }

  &__icon {
    padding: 0.625rem;
    @include flexAlignCenter;

    @media only screen and (max-width: 768px) {
      justify-content: center;
    }
  }

  &__image_icon {
    position: absolute;
    right: 1rem;
    bottom: 2rem;
  }

  &__swagger {
    margin: 0.625rem;
    color: var(--bosch-black);

    p {
      font-size: 1.1rem;
      display: flex;
      overflow-wrap: anywhere;
    }
  }

  &__secondary {
    color: var(--bosch-gray-72);
    font-size: 0.75rem;
  }
}

.margin_top_bottom {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.upload__thumbnail__section {
  border: 0.0625rem dashed $--bosch-silver-sand;
  text-align: center;
  width: 15rem;
  height: 21rem;

  @media only screen and (max-width: 768px) {
    margin: 0 auto;
  }
}

.form-step {
  display: flex;
  flex-flow: column;
}

.upload__cover__image {
  width: 100%;
  position: relative;
  .cover-img-section {
    height: 9.8125rem !important;
    width: 25rem !important;

    @media only screen and (max-width: 768px) {
      width: 21rem !important;
    }
  }
}

.column__documentations {
  width: 19%;
  border: 0.0625rem dashed $--bosch-silver-sand;
  @include flexAlignCenter;
  text-align: center;
  min-width: 13rem;
  height: 18rem;
  justify-content: center;

  @media only screen and (max-width: 768px) {
    width: 50%;
  }
}

.api_browser_cover_image {
  border: 0.0625rem dashed $--bosch-silver-sand;
  padding: 3.125rem 0.875rem;
  position: relative;
  width: 80%;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
}

.cover-image-container {
  padding: 1.125rem 0.875rem !important;
}

.delete-container {
  width: 100%;
  padding-left: 11.5rem !important;
}

.video__upload__details {
  @include flexAlignCenter;
  justify-content: space-between;
  margin-left: 1rem;
  background-color: var(--bosch-gray-95);
  margin-bottom: 1.5rem;

  @media only screen and (max-width: 768px) {
    margin-left: 0;
    width: 100%;
    display: block;
    text-align: center;
    margin-top: 1rem;

    video {
      width: 100px;
      margin-right: 0.5rem;
    }
  }
}

@media only screen and (max-width: 768px) {
  .column__video__upload {
    width: 100%;
  }
}

.upload-document-wrapper {
  @include flexAlignCenter;
  margin-top: 1.5rem;
  width: 80%;

  @media only screen and (max-width: 768px) {
    flex-flow: column;
    width: 100%;
  }
}

.file_link_style {
  text-decoration: none;
  color: var(--bosch-black);

  &:hover {
    text-decoration: none;
    color: var(--bosch-black);
  }
}

.api_thumbnail-dropzone {
  margin-top: 3.5625rem;
}

.upload-image {
  display: block !important;
}

.supported-formats-cover-image {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 80%;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
}

.cover-image-label {
  position: relative;
  top: 1.5rem;

  @media only screen and (max-width: 768px) {
    left: 1rem;
    top: 1.8rem;
  }
}

.browse_label {
  padding-right: 0.25rem;
}
