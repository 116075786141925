@import '../../../stylesheet/variables.scss';

.add-pricing-card {
  &__plan-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px dashed $--bosch-silver-sand;
    width: 16.375rem;
    height: 20.9375rem;
    cursor: pointer;
  }

  &__image-icon {
    width: 4rem;
    margin: 1rem;
    background-color: var(--bosch-blue-50);
    border-radius: 50%;
  }
  &__title {
    font-size: 1rem;
  }
  &__description {
    font-size: 0.875rem;
    color: $--bosch-shuttle-gray;
    margin-top: 0.6rem;
  }
}
