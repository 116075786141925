@import '../../../stylesheet/variables.scss';
@import '../../../stylesheet/mixins.scss';

.orders {
  margin-top: 2rem;
  height: 100%;
  overflow-y: scroll;

  &__container {
    &__tabCount {
      display: flex;

      &__statusCount {
        margin-left: 0.5rem;

        &__count {
          text-align: center;
          color: var(--bosch-white);
        }
        .a-badge {
          font-size: 0.9rem !important;
        }
      }
    }

    &__item {
      display: flex;
      padding: 1rem;
      justify-content: space-between;

      &__search {
        @include flexCustom(space-between, center);
        gap: 1rem;

        @media only screen and (max-width: 768px) {
          padding-top: 1rem;
          width: 100%;
          justify-content: space-around;
        }
      }

      &__titleBlock {
        flex-grow: 1;
      }

      &__heading {
        width: 100%;
        text-align: left;
        font-size: 1.25rem;
        color: var(--bosch-black);
      }

      &__upDown {
        padding-left: 1rem;

        .context-menu {
          .box {
            width: max-content;
            height: 3rem;
            padding-top: 0;

            .context-menu__box {
              width: 3rem;
              height: 3rem;
            }
          }
        }
      }
    }

    &__tabs {
      padding-left: 0.5rem;
      width: fit-content;
      overflow: hidden;
      max-width: 100%;

      .frontend-kit-example_tabbed-content {
        &::-webkit-scrollbar {
          display: none;
        }

        width: 100%;
        overflow: auto;

        .a-tab-navigation__wrapper {
          .a-tab-navigation {
            padding-left: 0;
            flex-wrap: nowrap;
          }
        }
      }
    }

    &__table {
      padding: 1rem;
      max-width: 100%;

      @media screen and (max-width: 768px) {
        padding: unset;
      }

      th {
        white-space: unset !important;
      }
      .orders__container__table__block {
        width: 100%;

        &::-webkit-scrollbar {
          display: none;
        }

        .m-table {
          thead {
            th:nth-child(8) {
              text-align: right;
            }
          }

          tbody {
            td:nth-child(6),
            td:nth-child(7) {
              text-align: center;
            }

            td:nth-child(8) {
              text-align: right;
            }
          }
        }
      }
    }
  }

  .product-category__content {
    width: 9rem;
  }
}

.status__content {
  text-align: center;
}

.orderid__content,
.productid__content {
  word-break: break-word;
  line-height: 1.25rem !important;
  min-width: 11rem;
  width: 11rem;
}

.product-category__content {
  text-align: center;
}

.content_style {
  width: fit-content;
  padding: 0.4rem 1rem;
  border-radius: 3rem;
  background: $--bosch-solitude;
  max-width: 8rem;
}

.product-name__content {
  word-break: break-word;
  line-height: 1.25rem !important;
  min-width: 11rem;
  width: 11rem;
}
.createdby__content {
  word-break: break-word;
  line-height: 1.25rem !important;
  min-width: 9rem;
  width: 9rem;
}
.date__content {
  word-break: unset;
  text-align: center;
  width: 11.5rem;
}

.quantity__content {
  text-align: center;
  width: 7rem;
}

.availability__content {
  text-align: center;
  width: 11rem;
}

.action__content {
  text-align: right;
}

.view__content {
  font-weight: 700 !important;
  text-align: left;
  width: 10rem;
}

.asset-type__content {
  @include flexJustifyCenter();
}

.scroll {
  word-break: break-word;
}

.internal-seller-product-id__content {
  word-break: break-word;
  line-height: 1.25rem !important;
  min-width: 11rem;
  width: 22rem;
}

.internal-seller-product-name__content {
  word-break: break-word;
  line-height: 1.25rem !important;
  min-width: 10rem;
  width: 18rem;
}

.internal-seller-order-date__content {
  word-break: unset;
}

.view-more-details {
  color: $primary_color !important;
  cursor: auto !important;
}
