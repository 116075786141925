@import '../../../stylesheet/variables.scss';
@import '../../../stylesheet/mixins.scss';

.quotation-listing {
  height: 100%;
  padding: 1rem 1rem 3rem 1rem;
  overflow-y: scroll;
  background: $--bosch-concrete-solid;

  @media screen and (max-width: 768px) {
    padding: 1rem;
  }

  &__container {
    @include flexCustom(space-between, center);
    padding: 0.5rem;

    &__title {
      text-align: left;
      font-weight: bold;
      color: $--bosch-black;
    }

    &__block {
      @include flexSpaceBetween();
      z-index: 0;

      @media only screen and (max-width: 768px) {
        z-index: unset;
      }
    }
  }

  &__tabs {
    padding-left: 0.5rem;
    width: fit-content;
    overflow: hidden;
    max-width: 100%;

    .frontend-kit-example_tabbed-content {
      &::-webkit-scrollbar {
        display: none;
      }

      width: 100%;
      overflow: auto;

      .a-tab-navigation__wrapper {
        .a-tab-navigation {
          padding-left: 0;
          flex-wrap: nowrap;
        }
      }
    }
  }

  &__table {
    max-width: 100%;
    padding: 0.5rem;
  }

  &__view-more {
    &__title {
      color: $--bosch-blue-50;
    }
  }

  .service-name__content,
  .buyer-name__content,
  .productid__content {
    @include tableheadingStyle(12rem);
  }

  .submitted-date__content {
    @include tableheadingStyle(13rem);
  }

  .status__content {
    @include tableheadingStyle(9rem);
  }

  .expiredby__content {
    width: 7rem;
    text-align: center;
    line-height: 1.25rem;
  }

  .view__content {
    width: 8rem;
    cursor: pointer;
    text-align: left;
  }
}
