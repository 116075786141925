@import '../../../stylesheet/variables.scss';
@import '../../../stylesheet/mixins.scss';
@import '../../../variables.module.scss';

.pricing-plan {
  &__right {
    width: 19rem;
    padding: 0;

    &-text {
      text-align: center;
      width: 15.75rem;
      margin: 0 auto;
      font-size: 1.2rem;
      line-height: 1;
    }

    &-subheading {
      font-size: 0.8rem;
      color: $--bosch-silver-sand;
      margin-top: 0.5rem;
    }

    &-heading {
      font-size: 1rem;
      font-family: $bosch-font-family-bold;
      color: $--bosch-black;
    }

    &-plan-preview {
      @include flexJustifyCenter();
      margin-left: 0;
      margin-top: 0.6rem;
      background-color: $--bosch-white;

      .preview__card {
        border-radius: unset !important;
        height: 27.25rem;
        padding-bottom: 0.1rem;
        width: 19rem;
        box-shadow: none !important;
        border: 0.0625rem solid $--bosch-gray-70;
        margin-top: unset;
        margin-bottom: unset;
      }
    }
  }

  &-basicplan {
    &__wrapper {
      height: 90%;
      overflow-y: scroll;
      padding-right: 1rem;
      display: block;

      @media only screen and (max-width: 768px) {
        height: auto;
        overflow-y: auto;
        padding-right: unset;
      }
    }

    &__unwrapper {
      display: none;
    }
  }

  &-paymentoptions {
    &__wrapper {
      display: block;
    }

    &__unwrapper {
      display: none;
    }
  }

  &__container {
    @include flexSpaceBetween();
  }

  &-wholeDiv {
    height: 100%;
    width: 65%;
    padding-top: 4rem;

    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }

  &__preview {
    margin-top: 2rem;
  }

  &-previewrapper {
    width: 35%;
    @include flexCustom(center, flex-start);
    background-color: $--bosch-white-20;
    padding-top: 4rem;

    @media only screen and (max-width: 768px) {
      width: 100%;
      padding-bottom: 2rem;
    }
  }
}

.sidebar {
  &__children {
    display: flex;
    gap: 2rem;
    height: 100%;
    padding: 0 !important;

    @media only screen and (max-width: 768px) {
      display: block;
    }
  }

  &__custom {
    max-width: 70rem !important;

    &_body {
      max-width: 100% !important;
      height: 100%;
    }
  }
}
