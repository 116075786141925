@import '../src/stylesheet/variables.scss';

// Fonts
$bosch-font-family-general: 'boschsans', sans-serif !important;
$bosch-font-family-light: 'boschsans light', sans-serif !important;
$bosch-font-family-bold: 'boschsans bold', sans-serif !important;
$bosch-font-family-medium: 'Bosch Sans Medium', sans-serif !important;

// Colors
$primary-color: var(--major-accent__enabled__fill__default);

/*
 * Color Map (used for color classes and direct usage in components)
 */
$colors: (
  'badgeMainBg': $--bosch-badge-main,
  'statusSubmitted': $--bosch-success-light,
  'statusInDraft': $--bosch-secondark-light,
  'statusRejected': $--bosch-danger-light,
  'statusRequested': $--bosch-solitude,
  'primaryColor': var(--major-accent__enabled__fill__default)
);

:export {
  // Fonts
  generalBoschFont: $bosch-font-family-general !important;
  boldBoschFont: $bosch-font-family-bold !important;
  lightBoschFont: $bosch-font-family-light !important;
  mediumBoschFont: $bosch-font-family-medium !important;

  // Colors
  @each $name, $value in $colors {
    #{unquote($name)}: $value;
  }
}
