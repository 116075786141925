@import '../../../stylesheet/variables.scss';

.pagination-container {
  display: flex;
  float: right;
  height: 4.0625rem;
  background: $--bosch-white;
  list-style-type: none;
  margin: 0;
  padding: 0;
  align-items: center;

  @media screen and (max-width: 768px) {
    float: unset;
    margin-top: 1rem;
    width: fit-content;
  }

  .pagination-item {
    padding: 0 12px;
    height: 32px;
    text-align: center;
    margin: 4px;
    color: $--bosch-black-background-87;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    letter-spacing: 0.01071em;
    border-radius: 16px;
    line-height: 1.43;
    font-size: 13px;
    min-width: 32px;
    justify-content: center;

    &::before {
      content: unset !important;
    }

    &.dots:hover {
      background-color: transparent;
      cursor: default;
    }

    &:hover {
      background-color: $--bosch-black-background-04;
      cursor: pointer;
    }

    &.selected {
      background-color: $--bosch-blue-50;
    }

    .arrow {
      &::before {
        position: relative;
        content: '';
        display: inline-block;
        width: 0.4em;
        height: 0.4em;
        border-right: 0.12em solid $--bosch-black-background-87;
        border-top: 0.12em solid $--bosch-black-background-87;
      }

      &.left {
        transform: rotate(-135deg) translate(-50%);
      }

      &.right {
        transform: rotate(45deg);
      }
    }

    &.disabled {
      pointer-events: none;

      .arrow::before {
        border-right: 0.12em solid $--bosch-black-background-43;
        border-top: 0.12em solid $--bosch-black-background-43;
      }

      &:hover {
        background-color: transparent;
        cursor: default;
      }
    }
  }
}
