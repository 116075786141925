@import '../../../../stylesheet/variables.scss';

.add-comment {
  background-color: $--bosch-gray-background-80;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  padding: 1rem;

  &__container {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: relative;
    max-width: 35.75rem;
    width: 100%;
    z-index: 4;
    overflow-y: auto;
    max-height: 100%;
    background: $--bosch-white 0% 0% no-repeat padding-box;

    &__secondary-button {
      justify-content: space-between !important;
    }
  }
}

.uploadpopup {
  background-color: $--bosch-gray-background-80;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;

  &__container {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: relative;
    max-width: 28.75rem;
    width: 100%;
    z-index: 4;
    overflow-y: auto;
    max-height: 100%;
    background: $--bosch-white 0% 0% no-repeat padding-box;
  }

  .button__rejection {
    &__secondary {
      background-color: $--bosch-white;
      color: $--bosch-red !important;
      border: 1px solid $--bosch-red !important;

      &:active {
        background-color: $--bosch-white;
        color: $--bosch-red !important;
        border: 1px solid $--bosch-red !important;
      }

      &:disabled {
        background-color: $--bosch-white;
        color: $--bosch-gray-background-80 !important;
        border-color: $--bosch-gray-background-80 !important;
      }
    }
  }
}

.pod-remark-area {
  margin-top: 10px;
}

.transit-popup {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
