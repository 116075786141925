.doc {
  display: block;
  margin: 1rem 0;
  color: #525f6b;
  grid-column-start: 1;
  grid-column-end: 3;

  @media only screen and (max-width: 768px) {
    grid-column-start: initial;
    grid-column-end: initial;
    grid-row-start: 2;
  }

  &__notes {
    margin: 0rem;
    font-size: 0.875rem;
  }
}
