.horizontal__container {
  display: grid !important;
  column-gap: 1rem;
  grid-template-columns: 20rem auto;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    /* overflow: hidden;
                */
    /* margin: 0 auto; */
    /* place-items: center; */
  }
}
.horizontal__container-reset {
  grid-template-columns: auto;
}
