@import '../../../stylesheet/variables.scss';
@import '../../../stylesheet/mixins.scss';

.input {
  &__addinal-info {
    margin-top: 0.4rem;
    color: $--bosch-silver-sand;
  }
  &__details {
    @include flexCustom(space-between, baseline);
  }
  &__required {
    color: var(--bosch-red-50);
  }

  &__max-char {
    white-space: nowrap;
  }
}
input::placeholder {
  color: var(--bosch-gray-40) !important;
  opacity: 1;

  @media only screen and (max-width: 768px) {
    font-size: 0.75rem;
  }
}
.no_arrow {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
}

.error-notification-wrapper {
  padding: 0.4rem !important;
  display: flex !important;

  &-notification-label {
    max-width: 3rem;
  }
}
