@import '../../../../../variables.module.scss';
@import '../../../../../stylesheet/mixins.scss';
@import '../../../../../stylesheet/variables.scss';

.pricing-plan-label {
  @include flexJustifyCenter;
}

.pricing-plan-form {
  display: flex;
  margin-bottom: 1rem;
  width: calc(100% - 2rem);

  .w__50 {
    width: 100%;
    @include flex_col;
    gap: 0.5rem;

    &-title {
      display: flex;
    }

    &-input-group-width {
      @include flex_col;
      gap: 0.5rem;

      .input-group-width__error-msg {
        color: $--bosch-red;
      }
    }

    &-mandatory-symbol {
      color: var(--bosch-red-50);
    }

    .custom_description {
      margin-bottom: unset !important;
    }
  }

  &__left-div {
    flex-grow: 1;
    max-width: 18.75rem;
  }

  &__right-div {
    flex-grow: 1;
    margin-left: 1.25rem;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
}
.input-currency-width {
  @include flex_col;
  gap: 0.6rem;

  .input-group-width__error-msg {
    color: $--bosch-red;
  }
}
.currency {
  @include flex_col;
  font-weight: 800 !important;
}
.modal__pricing__indicator {
  @include flexSpaceBetween;

  .indicator {
    &__description {
      font-size: 0.9rem;
      font-family: $bosch-font-family-general;
    }
  }
}

.slab-wise-form {
  @include flexSpaceBetween;
  margin-bottom: 0.9375rem;

  &__left-dropdwn,
  &__right-dropdwn {
    flex-grow: 1;
    max-width: 21.875rem;
  }
}

.burst-control {
  margin: 0.625rem 0 !important;
}

.modal-close-button {
  padding-right: 0 !important;
}

.modal-close-button:hover {
  background: none !important;
}

.advanced-settings {
  box-shadow: none !important;
  margin-bottom: 0.625rem !important;
}

.advanced-settings-accordion-icon {
  color: $primary-color;
  font-size: 1.75rem;
}

.advanced-settings-details {
  margin-bottom: 0.9375rem !important;
}

.advanced-settings::before {
  height: 0 !important;
}

.advanced-settings-label {
  padding: 0 !important;
  @include flexSpaceBetween;

  img {
    @include Width_height_15;
  }
}

.advanced-settings-label-name {
  margin-bottom: 1.5rem !important;
}

span.Slab.based.Pricing .a-radio-button label:before {
  background-color: var(--bosch-blue-50);
}

.advanced-settings-field {
  display: flex;
  column-gap: 1.25rem;

  @media only screen and (max-width: 768px) {
    flex-wrap: wrap;
  }
}

.advanced-settings-field-label {
  min-width: 17.5rem;
}

.advanced-settings-field-switch {
  margin-top: 0.5625rem;
}

.advanced-settings-field-form {
  display: flex;
  column-gap: 1.25rem;
}

.advanced-settings-input-field {
  width: 7.5rem;
}

.plan__item {
  position: relative;

  .delete_icon {
    position: absolute;
    right: 0;
    top: 0.4375rem;
  }
}

.side-bar {
  &__pricing__plan {
    max-width: 71rem !important;
    width: 71rem;

    &_body {
      width: inherit;
      max-width: inherit !important;
      height: 100%;

      @media only screen and (max-width: 768px) {
        padding: 0 1rem !important;
        height: unset;
      }
    }

    @media only screen and (max-width: 768px) {
      max-width: 100%;
      width: 100%;
    }
  }

  &__pricing__plan-children {
    padding-top: 2rem !important;
    height: 100%;
  }
}

.modal__pricing__plan {
  background-color: var(--bosch-white);
  width: 100%;
  overflow-y: hidden;
  padding: 0 0 0 0;
  height: 100%;

  .close_icon_modal {
    background-color: unset;
    border: unset;
    cursor: pointer;
  }

  .pricing__plan__wrapper {
    @include flexCustom(space-between, flex-start);
    background-color: $--bosch-white;
    height: 100%;

    .wrapper__class {
      background-color: $--bosch-white;
      width: 70%;
      overflow-y: scroll;
      height: 100%;

      @media only screen and (max-width: 768px) {
        width: 100%;
        overflow-y: unset;
        margin-top: 1rem;
      }
    }

    .marketplace_view {
      width: 35%;
      background-color: $--bosch-white-20;
      @include flexCustom(flex-start, center);
      flex-direction: column;
      height: 100%;
      padding: 2rem 0 0 0;

      &-preview-label {
        font-family: $bosch-font-family-medium;
        opacity: 0.9;
        color: $--bosch--black-03 !important;
        margin-bottom: 0.8rem;
      }

      &_p {
        color: var(--bosch-gray-80);
        line-break: auto;
        max-width: 19rem;
        margin: 1rem 0 0 0;
      }

      @media only screen and (max-width: 768px) {
        width: 100%;
        padding: 2rem 0;
      }
    }

    @media only screen and (max-width: 768px) {
      flex-direction: column;
      gap: 1rem;
    }
  }
}

.mb__15 {
  margin-bottom: 1.5rem;

  &__fieldDescription {
    color: $--bosch-silver-sand;
  }
}

.pricing-type span {
  margin-right: 1rem;
}

.pricing__plan_indicator {
  margin: 0 2rem 0 0;
  border: 0.0625rem solid $--bosch--light-grey;
  padding: 1rem !important;
  height: calc(100vh - 15rem) !important;
  overflow-y: scroll;
  overflow-x: clip;

  &-advanced-settings {
    border: 0.0625rem solid $--bosch--light-grey;
    padding: 1rem !important;
    height: unset;
    overflow-y: unset;
    overflow-x: clip;

    @media only screen and (max-width: 768px) {
      overflow-x: scroll;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  .w__50 {
    width: 60%;

    &-input-group-width {
      margin-top: 1.5rem;

      & > * {
        margin: 0.5rem 0;
        font-size: 1rem;
        font-family: $bosch-font-family-medium;
        color: $--bosch-silver-sand;
      }

      .input-group-width {
        &__info-style {
          font-family: $bosch-font-family-general;
          font-size: 0.8rem;
          opacity: 0.7;
        }

        &__error-msg {
          color: $--bosch-red;
        }
      }
    }

    &-planName {
      & > * {
        margin: 0.5rem 0;
        font-size: 1.1rem;
        font-family: $bosch-font-family-medium;
        color: $--bosch--black-03;
      }
    }
  }

  .heading-style {
    margin: 1.2rem 0 0 0;
  }

  .marketplace_view {
    @include flexAlignCenter;
    flex-flow: column;
  }

  .marketplace_view_p {
    width: 75%;
    margin: 0 auto;
    text-align: center;
    @include marginBottom-gap-1;
  }

  .mb__1 {
    @include marginBottom-gap-1;
  }

  .search__more {
    position: relative;
    top: 2rem;
  }

  .pricing-type {
    &__wrapper {
      @include flexCustom(flex-start, center);
      gap: 1rem;

      &__button {
        @include flexCustom(flex-start, center);
        gap: 1rem;
      }
    }

    &__description {
      color: $--bosch-silver-sand;
      margin: 0.5rem 0 0 0;
    }
  }

  @media only screen and (max-width: 768px) {
    margin: unset;
    height: unset;
    overflow-y: unset;
  }
}

.search__more {
  @include flexSpaceBetween;
  position: relative;
  top: 0.6875rem;
}

.add_pricing_plan {
  @include flexEnd;
}

.tab_panel {
  margin: 0 2rem 0 0;
  border: 0.0625rem solid $--bosch--light-grey;
  height: calc(100vh - 16rem) !important;

  &_context {
    display: flex;

    .context {
      &__box-style {
        border-bottom: 0.1rem solid $--bosch-concrete-solid;
        font-weight: bold;
        cursor: pointer;
        border-radius: 0.2rem;
        flex-grow: 1;
        display: flex;
        justify-content: center;
        padding: 1rem 1.3rem;

        @media only screen and (max-width: 768px) {
          white-space: nowrap;
        }
      }

      &__non-selected-tab-style {
        background: $--bosch-white;
        color: $--bosch--gray-84;
      }

      &__tab-background-font-color {
        border-bottom: 0.2rem solid var(--bosch-blue-50);
        border-radius: 0;
        color: var(--bosch-blue-50);
      }
    }

    @media only screen and (max-width: 768px) {
      overflow: hidden;
      overflow-x: scroll;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  &_body {
    padding: 0 1.5rem 0.625rem !important;
    @include flex_col;
    gap: 2rem;

    .w__50-input-group-width {
      @include flex_col;
      gap: 1rem;

      .payment-option_description {
        font-size: 0.8rem;
        color: $--bosch-silver-sand;
        opacity: 1;
      }
    }

    .panel {
      &__info-container {
        @include flexAlignCenter;
        gap: 1rem;
        margin: 2rem 0 0.7rem 0;
        background: $--bosch--light-blue-2 0% 0% no-repeat padding-box;
        border: 0.1rem solid $--bosch-blue-97;
        border-radius: 0.8rem;
        padding: 1rem;

        .info-icon {
          width: 2rem;
          height: 2rem;
        }
      }

      &__pricing-plan-info-icon {
        color: $--bosch-black;
        margin-right: 0.7rem;
      }

      &__payment-option-heading {
        font-family: $bosch-font-family-bold;
        font-size: 0.75rem;
        color: $--bosch-shuttle-gray;
        opacity: 1;

        &-paymentOptionDescription {
          font-family: $bosch-font-family-bold;
          font-size: 0.75rem;
          color: $--bosch-shuttle-gray;
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    margin: unset;
  }
}

.payment-option_description {
  font-size: 0.8rem;
  color: $--bosch-silver-sand;
  opacity: 1;
}

.pointerCursor {
  cursor: pointer;
}

.marginleft__2 {
  @include marginLeft-gap-2;
}

.radio_button_pricing_plan {
  label {
    &::before {
      background-color: var(--major-accent__enabled__fill__default);
    }
  }
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 4.125rem;
  height: 1.8rem;
}

.toggle-switch input[type='checkbox'] {
  display: none;
}

.toggle-switch .switch {
  position: absolute;
  cursor: pointer;
  background-color: $--bosch--light-grey;
  border-radius: 1.5625rem;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: background-color 0.2s ease;
}

.mt__1 {
  margin-top: 0.7rem;
}

.mb_1 {
  margin-bottom: 1rem;
}

.pricing__plan__indicator {
  ul {
    pointer-events: none;
  }
}

.toggle-switch .switch::before {
  position: absolute;
  content: '';
  left: 0.1rem;
  top: 0.25rem;
  width: 1.3125rem;
  height: 1.3125rem;
  background-color: var(--bosch-white) !important;
  border-radius: 50%;
  transition: transform 0.3s ease;
}

.toggle-switch input[type='checkbox']:checked + .switch::before {
  transform: translateX(2.5625rem);
  background-color: var(--major-accent__enabled__fill__default);
}

.toggle-switch input[type='checkbox']:checked + .switch {
  background-color: var(--minor-accent__enabled__front__default);
}
.pricing-plan-list.tab_panel .tab_panel {
  height: auto !important;
}
