@import '../../../../stylesheet/mixins.scss';
@import '../../../../stylesheet/variables.scss';

.search {
  width: 100%;
  padding: 1.875rem;

  @media only screen and (max-width: 768px) {
    padding: 0.875rem;
  }

  &__content {
    justify-content: space-between;
    background-color: var(--bosch-white);
    padding: 0 0.625rem;
    @include flexAlignCenter;
    width: 100%;
    min-height: 4rem;
  }
}

.mt__15 {
  @include marginTop-gap-15;
}

.MuiListItem-root:before {
  display: none;
}

.multiSelectContainer {
  li {
    @include flexAlignCenter;
    padding: 0;
    &::before {
      display: none;
    }
  }
}

.inline-edit-table {
  .MuiPaper-elevation2 {
    box-shadow: none !important;
  }
  .MuiTableCell-root {
    border-bottom: 1px solid $--bosch-alto;
  }
}

.multiple__div {
  .arrow-right {
    position: absolute;
    top: 0.8rem;
    right: 0.5rem;
  }

  .searchWrapper {
    height: 3.2rem;
    width: 100%;
    background-color: var(--bosch-gray-90);
    padding: 0.4rem 1rem;
    border-bottom: 0.0625rem solid var(--neutral__enabled__front__default);

    .chip {
      position: relative;
      top: 4.5rem;
      margin-right: 1rem !important;
      padding: 0.125rem 0.4375rem !important;
      font-size: 0.75rem !important;
      background-color: var(--neutral__enabled__fill__default);
      color: var(--bosch-black);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;

      span {
        margin-right: 0.9rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 10rem;
        display: block;
      }

      svg {
        width: 0.875rem;
        right: 0.125rem;
        top: 0;
        position: absolute;
      }
    }
  }
}

.icons {
  &__action {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    margin-top: 0.375rem;
  }

  &__edit_icon {
    height: 1.4375rem;
    width: 1.6875rem;
  }

  &__delete_icon {
    height: 1.375rem;
    width: 1.375rem;
    margin-left: 2rem;
  }
}

.download__swagger {
  @include flexEnd;
  position: relative;
  right: 0rem;
  top: -2rem;
  color: var(--minor-accent__enabled__front__default);
  opacity: 0.5;

  img {
    width: 1.2rem;
    height: 1.2rem;
    position: relative;
    top: 0.2rem;
  }
}

.mandatory__symbol {
  color: $--bosch-red;
}

.configure-parameter__height {
  max-width: 70rem !important;
  height: 34rem !important;

  .configure-parameter__msg {
    display: none;
  }

  .configure-parameter__body {
    padding: 0 2rem;
  }
}

.MuiButtonBase-root-137:before {
  display: none;
}

.MuiTableCell-root-147 {
  font-size: 14px !important;
  padding: 15px 6px !important;
}

.api-step-indicator {
  .m-step-indicator__node {
    cursor: pointer;
  }
}

.api_endpoints_custom {
  width: 100% !important;
}
