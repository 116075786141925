@import '../../../variables.module.scss';
@import '../../../stylesheet/variables.scss';
@import '../../../stylesheet/mixins.scss';

%modal-shared {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;

  @media only screen and (max-width: 768px) {
    position: absolute;
    padding: 1rem;
  }
}

%modal-style {
  display: flex;
  width: 100%;
  height: 35%;

  @media only screen and (max-width: 768px) {
    width: 100%;
    height: 35%;
  }
}

@mixin dialog_image($bkgdcolor: $--bosch-blue-88 0% 0% no-repeat padding-box) {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: inherit;
  height: 12.375rem;
  background: $bkgdcolor;

  &__image {
    width: 12.5rem;
    object-fit: cover;
    overflow: hidden;
  }

  &__alert {
    position: relative;
    width: 2.3125rem;
    height: 2.3125rem;
    bottom: 7rem;
    left: 4rem;
  }
}

.dialog_modal {
  @extend %modal-shared;
  background-color: rgba(129, 126, 126, 0.4) !important;
  z-index: 4;

  &__success {
    @include dialog_image;

    @media only screen and (max-width: 768px) {
      position: relative;
      width: 100%;
    }
  }

  &__error {
    @include dialog_image(
      $bkgdcolor: $--bosch-powder-ash 0% 0% no-repeat padding-box
    );
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 28rem;
    max-width: 44rem;
    overflow-y: auto;
    transform: translate(-50%, -50%);
    background: $--bosch-white 0% 0% no-repeat padding-box;

    @media only screen and (max-width: 768px) {
      height: fit-content !important;
      max-height: 80% !important;
    }

    &__visibility_modal {
      height: calc(100% - 25%) !important;
    }

    @media only screen and (max-width: 768px) {
      &__visibility_modal {
        height: auto !important;

        .dialog_modal__message {
          position: relative;
          bottom: 1rem;
        }
      }
    }
  }

  &__message {
    width: inherit;
    height: 3rem;
    display: flex;
    justify-content: flex-end;
    column-gap: 1rem;
    position: absolute;
    right: 2rem;
    bottom: 2rem;

    @media only screen and (max-width: 768px) {
      text-align: end;
      position: relative !important;
      bottom: 1rem !important;
    }
  }

  &__input {
    width: 100% !important;
    margin-top: 2rem;

    @media only screen and (max-width: 768px) {
      padding: 1rem;
    }
  }

  &__close {
    text-align: center;
    color: $--bosch-white;

    &_icon {
      position: absolute;
      right: 1.25rem;
      top: 1rem;
    }
    &_icon_default {
      color: $--bosch-white !important;
    }
    &_icon_visibility_modal {
      color: inherit !important;
    }
  }
}

.dialog_alert__message {
  display: flex;
  flex-direction: column;
  line-height: 1.5rem;
  text-align: left;
  padding: 0 2rem;

  &__visibility_settings {
    margin-top: 30px;
  }

  &__title {
    font-family: $bosch-font-family-bold;
    color: $--bosch-black;
    margin-top: 1.5rem;
    font-size: 1.5rem;
    font-weight: 600;

    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }

  &__subtitle {
    margin-top: 1rem;
    width: 26rem;
    font-family: $bosch-font-family-light;

    @media only screen and (max-width: 768px) {
      width: 100%;
      margin-top: 0.5rem !important;
    }
  }
}

.dialog_remark {
  &__chargeable {
    background-color: $--bosch-white !important;
  }
  &__message {
    display: flex;
    position: relative;
    line-height: 1.5rem;
    text-align: left;
    margin-left: 2rem;
    top: 1.25rem;

    &__title {
      font-family: $bosch-font-family-bold;
      color: $--bosch-black;
      margin-top: 0.75rem;
      display: flex;

      @media only screen and (max-width: 768px) {
        width: 100%;
      }
    }

    &__icon {
      width: 2rem;
      margin-right: 1rem;
    }
  }

  &__close {
    position: absolute;
    right: 1.25rem;
  }
}

.dialog_remark_general {
  &__close {
    position: absolute;
    right: 1.25rem;
  }
  &__chargeable_close {
    color: $--bosch-black !important;
  }
}
