@import '../../../variables.module.scss';
@import '../../../stylesheet/variables.scss';
@import '../../../stylesheet/mixins.scss';

.custom-modal {
  height: 34rem;
  max-width: 50rem;

  &_msg {
    position: relative;
    margin: 1rem 0 2rem 0;
    right: 2rem;
    bottom: 0;

    @media only screen and (max-width: 768px) {
      margin: 2rem 0 1rem 0;
    }
  }
}

.reject-modal {
  &__content {
    margin-left: 3rem;
    word-wrap: break-word;
    margin-right: 3rem;
  }
}

.modal {
  &__height {
    height: 38rem;

    @media only screen and (max-width: 768px) {
      height: 50vh;
    }
  }

  &__msg {
    position: relative;
    margin-top: 2rem;
  }
}

.card-content {
  padding: 1.87rem 0 0 1.25rem;
}

.more-options-wrap {
  @include flexAlignCenter;
  cursor: pointer;
}

.popup-styling {
  position: absolute;
  top: 7rem;
}

.disable-product-card {
  .more-options-wrap {
    pointer-events: none;
    color: $--bosch--light-grey;
  }

  .arrow-icon-placement {
    pointer-events: none;

    .a-icon {
      color: $--bosch--light-grey !important;
    }
  }
}

.comment {
  max-height: 11rem;
  overflow-y: auto;
}

.sales-rep-icon {
  position: absolute;
  left: 85%;
  right: 1rem;
  top: 0.8rem;
}
