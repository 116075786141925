.chart-grid {
    &__custom-cell {
        display: flex;
        gap: 1rem;
        align-items: center;

        &__image {
        width: 2.6rem;
        height: 100%;
        }

        &__text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 1rem !important;
        font-weight: 400 !important;
        }
    }
}