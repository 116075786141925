@import '../../../stylesheet/variables.scss';
@import '../../../stylesheet/mixins.scss';

.usp-dashboard {
  &__header {
    background-color: var(--bosch-gray-95);
    display: flex;
    justify-content: space-between;
    padding: 1.5rem 1.7rem 0.9rem 1.4rem;
  }

  @supports (-moz-appearance: none) {
    &__header {
      padding: 1.5rem 2.5rem 0.9rem 1.5rem;
    }
  }


  &__dropdown .single-select-dropdown {
    margin-top: 0.6rem;
    margin-right: 0.6rem;
    width: 15.6rem;
  }

  &__dropdown .single-select-dropdown__options {
    width: 15.6rem;
    z-index: 1;
  }

  &__heading-text {
    text-decoration: solid;
    text-align: left;
    font-weight: 700;
    font-size: 1.5rem;
    padding-top: 0.43rem;
  }

  &__card-container {
    background-color: var(--bosch-gray-95);
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 1.25rem;
    padding: 2% 2% 7% 2%;
  }

  @supports (-moz-appearance: none) {
    &__card-container {
      padding: 2% 1% 7% 2%;
    }
  }

  &__chart-card {
    width: calc(50% - 10px);
    .ag-header-cell-comp-wrapper {
      justify-content: center;
      cursor: pointer;
    }
  }

  &__chart-card .mps-chart {
    width: 37rem !important;
    box-shadow: none !important;
    padding: 0.8rem 0.5rem !important;
  }

  &__chart-card .mps-echarts {
    height: 16rem !important;
    padding-top: 1rem !important;
  }

  &__chart-card .mps-chart-filter {
    width: 10.5rem !important;

    .mps-user-input-select-field .MuiFilledInput-input {
      padding-top: 4px !important;
    }
  }

  &__a-list--dot li:before,
  ul>li:before {
    content: none;
  }

}

.top-selling__custom-header__content {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.ag-theme-quartz.MuiBox-root {
  height: 195px !important;
  z-index: 1;
}

.ag-body-vertical-scroll.ag-scrollbar-invisible {
  z-index: 2;
}

.ag-theme-quartz {
  --ag-background-color: '#000' !important;
}

.mps-header-label .MuiTypography-root {
  font-size: 1.2rem;
}

.mps-dropdown ul>li:before {
  content: none;
}

.mps-dropdown {
  scrollbar-width: thin;
}

.ag-icon {
  font-family: var(--ag-icon-font-family) !important;
}

.ag-header-cell-text {
  font-weight: 600 !important;
  font-size: 1rem !important;
}

.ag-cell {
  font-size: 1rem !important;
  font-weight: 400 !important;
}

.ag-body-vertical-scroll {
  width: 3px !important;
  min-width: 3px !important;
  max-width: 3px !important;
}

@supports (-moz-appearance: none) {
  .ag-body-vertical-scroll {
    width: 16px !important;
    min-width: 16px !important;
    max-width: 16px !important;
  }
}

.top-selling {

  &__custom-cell {
    display: flex;
    gap: 1rem;
    align-items: center;

    &__image {
      width: 2.6rem;
      height: 100%;
    }

    &__text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 1rem !important;
      font-weight: 400 !important;
    }
  }

  &__custom-header {
    display: flex;
    justify-content: center;
    gap: 0.5rem;


    &__text {
      display: flex;
      font-weight: 600;
      font-size: 1rem !important;
    }

    &__info {
      display: flex;
      align-items: center;
    }

    &__icon {
      width: 1rem !important;
      height: 1rem !important;
      margin-left: 4px;
      cursor: pointer;
    }
  }
}

.placeholder .MuiCard-root {
  border-radius: none !important;
}

.mps-chart-placeholder {
  width: 37rem;
  margin: auto;
  height: max-content;
  box-shadow: none !important;
  border-radius: 0 !important;

  >.MuiCardContent-root {
    padding: 0.7rem 1.6rem 0rem 0.6rem !important;


    >.header {
      height: 0.8rem;
      position: relative !important;
      display: flex;
      align-content: center;
      margin-bottom: 3rem;
      justify-content: space-between;
      z-index: 0;
      border-radius: 0 !important;


      .header__content.MuiSkeleton-root {
        height: 4rem !important;
        width: 9.7rem !important;
        border-radius: 0 !important;


        @media only screen and (max-width: 600px) {
          width: 9.7rem;
          height: 6rem;
        }
      }

      >.dropdown {
        display: flex;
        flex-direction: row;

        >.MuiSkeleton-root:first-of-type {
          width: 10.7rem;
          height: 4rem;
          margin-left: 2.4rem;
          border-radius: 0 !important;

          @media only screen and (max-width: 600px) {
            margin-left: 0;
          }
        }

        >.MuiSkeleton-root:last-of-type {
          width: 10.7rem;
          height: 4rem;
          margin-left: 1.1rem;
          border-radius: 0 !important;

          @media only screen and (max-width: 600px) {
            width: 9.7rem;
          }
        }
      }

      @media only screen and (max-width: 600px) {
        text-align: center;
        flex-direction: column;
        align-items: center;
        height: auto;
      }
    }
  }

  >.MuiCardContent-root>.body {
    margin-bottom: 0.7rem;

    >.MuiSkeleton-root {
      width: 100%;
      height: 13.8rem;
    }

    @media only screen and (max-width: 600px) {
      height: 13rem;
    }
  }

  >.MuiCardContent-root>.footer {
    display: flex;
    justify-content: flex-end;
    position: relative !important;
    gap: 1rem;

    >.MuiSkeleton-root {
      height: 3rem;
      width: 10rem;
      border-radius: 0 !important;
    }
  }

  @media only screen and (max-width: 600px) {
    height: auto;
    width: 22rem;
  }
}

.mps-tooltip {
  background-color: white !important;
  color: rgba(0, 0, 0, 0.87) !important;
  font-size: 1rem !important;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);
  white-space: pre-wrap;
}

@media only screen and (min-width: 300px) and (max-width: 768px) {
  .usp-dashboard {
    &__container {
      font-weight: 600;
      font-size: 1rem;
    }

    &__dropdown .single-select-dropdown {
      margin-right: 0rem;
      margin-left: 1.87rem;
      width: 12.18rem;
      margin-top: 0.4rem;
    }

    &__dropdown .single-select-dropdown__options {
      width: 12.18rem;

      .single-select-dropdown__search {
        width: 12.1rem;
      }
    }

    &__chart-card {
      width: 100% !important;
    }

    &__chart-card .mps-chart {
      width: 27rem !important;
    }

    &__chart-card .mps-echarts {
      height: 32rem !important;
    }
  }

  .mps-chart-placeholder {
    width: 30rem !important;
  }
}

@media only screen and (min-width: 390px) and (max-width: 844px) {
  .usp-dashboard {
    &__chart-card .mps-chart {
      width: 30rem !important;
    }
  }
}

@media only screen and (min-width: 430px) and (max-width: 932px) {
  .usp-dashboard {
    &__chart-card .mps-chart {
      width: 30rem !important;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .usp-dashboard {
    &__chart-card .mps-chart {
      width: 57rem !important;
    }
  }
}

@media only screen and (min-width: 820px) and (max-width: 1180px) {
  .usp-dashboard {
    &__chart-card .mps-chart {
      width: 43rem !important;
    }

    &__card-container {
      display: grid;
    }
  }
}

@media only screen and (min-width: 1300px) and (max-width: 1450px) {
  .usp-dashboard__chart-card .mps-chart {
    width: 41rem !important;
  }

  .usp-dashboard {
    &__header {
      padding: 1.56rem 1.87rem 0.93rem 1.25rem;
    }
  }
}

@media only screen and (min-width: 1450px) and (max-width: 1600px) {
  .usp-dashboard__chart-card .mps-chart {
    width: 46rem !important;
  }

  .usp-dashboard {
    &__header {
      padding: 1.56rem 2.87rem 0.93rem 2rem;
    }
  }
  .mps-chart-placeholder{
    width: 46rem !important;
  }
}