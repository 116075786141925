@import '../../../variables.module.scss';

.container-padding {
  padding-top: 24px;
  padding-bottom: 24px;
  text-align: left;
  @media only screen and (max-width: 900px) {
    padding-left: 30px;
    padding-right: 30px;
    text-align: center;
  }
  @media only screen and (max-width: 600px) {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.grid-container {
  display: flex;
  flex-direction: column;
  row-gap: 25px;
}

.welcome-message {
  font-family: $bosch-font-family-general;
  font-size: 2rem !important;
  font-weight: 600;
}

.welcome-body {
  font-family: $bosch-font-family-general;
  font-size: 1rem !important;
}

.welcome-subtitle {
  font-family: $bosch-font-family-general;
  font-size: 0.8rem !important;
}

.login-us {
  font-family: $bosch-font-family-general;
  font-size: 1.2rem !important;
}

.seller-sales-rep-container,
.seller-rep-container {
  display: flex;
  background-color: #fff !important;
}
.seller-sales-rep {
  position: relative;
  display: flex;
  height: 60px;
  width: 200px;
  cursor: pointer;
  border: none;
  border: 2px solid #e7e7e7 !important;
  background-color: #fff !important;
}
.radio-button {
  color: $--bosch-blue-50;
  top: -24px !important;
  left: 13.8rem !important;
  &::before {
    background-color: --bosch-gray-70;
    border-radius: 1rem;
  }
}

.seller-sales-rep-image {
  margin-top: 10px;
  height: 40px;
}

.select-checked-icon {
  background-color: #007bc0;
}

.seller-sales-rep-text {
  padding: 10px;
  margin-top: 10px;
  font-weight: bold;
}

.sales-rep-text {
  margin-top: 0px !important;
}

.padding-btw-buttons {
  padding-left: 15px;
}

.btn-style {
  width: 80px !important;
  padding: 10px !important;
  font-size: 1rem !important;
  font-family: $bosch-font-family-general;
  background-color: #007bc0 !important;
}
@media only screen and (max-width: 400px) {
  .seller-sales-rep-container,
  .seller-rep-container {
    margin-left: 1rem !important;
  }
}
@media only screen and (max-width: 500px) {
  .seller-sales-rep-container {
    margin-left: 3rem !important;
  }
  .seller-rep-container {
    margin-left: 1rem !important;
  }
}

@media only screen and (max-width: 600px) {
  .welcome-message {
    font-size: 1.2rem !important;
  }

  .welcome-body {
    font-size: 1rem !important;
  }
  .seller-sales-rep-container {
    margin-left: 2rem !important;
  }
  .seller-rep-container {
    margin-left: 1rem !important;
  }
}

@media only screen and (min-width: 360px) and (max-width: 740px) {
  .seller-sales-rep-container {
    margin-left: 0rem !important;
    display: contents !important;
  }
  .seller-rep-container {
    margin-left: 7rem !important;
  }
}
@media only screen and (min-width: 375px) and (max-width: 780px) {
  .seller-sales-rep-container {
    margin-left: 1rem !important;
    display: contents !important;
  }
  .seller-rep-container {
    margin-left: 7rem !important;
  }
  .seller-container {
    margin-left: 6rem !important;
  }
  .sales-rep-container {
    margin-left: 5rem !important;
  }
}
@media only screen and (min-width: 400px) and (max-width: 950px) {
  .seller-sales-rep-container {
    margin-left: 3rem !important;
    display: contents !important;
  }
  .seller-rep-container {
    margin-left: 9rem !important;
  }
}
@media only screen and (min-width: 540px) and (max-width: 720px) {
  .seller-sales-rep-container {
    margin-left: 3rem !important;
    display: flex !important;
  }
  .seller-rep-container {
    margin-left: 9rem !important;
  }
  .seller-container {
    margin-left: 1rem !important;
  }
  .sales-rep-container {
    margin-left: 1rem !important;
  }
}
