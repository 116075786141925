.ag-theme-quartz {
  --ag-background-color: #f8f8f8;
}
.mps-ag-grid {
  .ag-icon {
    font-family: var(--ag-icon-font-family) !important;
  }
  .ag-root-wrapper {
    border: none;
    border-radius: 0;
    .ag-header {
      font-size: 1em;
      border-bottom: 1px solid black;
    }
    .ag-header-cell {
      font-weight: bold !important;
    }
    .ag-body {
      font-size: 1rem;
    }
  }
  .ag-header-cell-label {
    justify-content: left !important;
  }
  .ag-cell {
    text-align: left !important;
  }
}
