@import '../../../variables.module.scss';

.bosch-strip {
  background-size: 100%;
  height: 6px;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1;
}
.bosch-logo {
  width: 7rem !important;
  padding-top: 1px !important;
}

.container {
  height: 50px;
}
.header-login {
  &__container {
    display: flex;
    padding: 0.8rem 0 0.3rem 10rem;
    justify-content: space-between;
    background-color: var(--bosch-white);
  }
  &__strip-small {
    height: 0.7rem !important;
  }
}

@media only screen and (max-width: 1200px) {
  .header-login {
    &__container {
      padding-left: 5rem;
    }
  }
}

@media only screen and (max-width: 600px) {
  .bosch-logo {
    width: 7rem !important;
    padding-top: 1px !important;
  }
  .header-login {
    &__container {
      padding-left: 3rem;
    }
  }
}
