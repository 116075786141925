@import '../../../stylesheet/variables.scss';

.form-editor {
  display: flex;
  align-items: center;
  padding: 1.5rem 1rem;
  background: $--bosch-concrete-solid 0% 0% no-repeat padding-box;

  &__edit-btn {
    margin: 0 0 1.3rem 1rem;
    cursor: pointer;
  }

  &__children {
    width: 100%;
  }
}
