@import '../../../stylesheet/variables.scss';
@import '../../../variables.module.scss';
@import '../../../stylesheet/mixins.scss';

.card-box {
  margin-bottom: 1.5rem;
  border: 1px solid $--bosch-blue-50;
  width: 100%;
  height: auto !important;
  display: flex;
  padding: 1rem;
  gap: 2rem;
  align-items: center;
  border-radius: 1rem;
  justify-content: space-between;

  &__single-select {
    width: 30%;
  }

  &__container {
    display: flex;
    flex: 1;
    justify-content: flex-start;
    align-items: center;
    gap: 2rem;
    &__image-icon {
      width: 6rem;
      height: 6rem !important;
      padding: 0.1rem;
      background-color: $--bosch-blue-50;
      border-radius: 50%;
      aspect-ratio: 1;

      &__text {
        display: flex;
        padding: 0.5rem;
        margin-top: 1.6rem;
        justify-content: center;
        color: $--bosch-white;
      }
    }

    &__link-container {
      &__heading {
        display: flex;
        &__link {
          font-size: medium;
          color: $--bosch-blue-50;
        }
        &__img {
          margin-left: 7px;
          width: 25px;
        }
      }

      &__category-container {
        display: flex;
        gap: 0.28rem;
        flex-wrap: wrap;

        &__category {
          border: 1px solid;
          width: max-content;
          margin-top: 0.3rem;
          display: flex;
          padding: 0.3rem;
          justify-content: center;

          &__name {
            font-size: 0.66rem !important;
            font-weight: bold;
          }
        }
      }
    }
  }

  &__pin {
    &__img {
      width: 2rem;
      opacity: 0.3;

      &__disable {
        color: $--bosch-white;
      }
    }
  }

  &__box {
    padding-right: 2rem;

    .check {
      color: $--bosch-blue-50;
      &::before {
        background-color: --bosch-gray-70;
        border-radius: 1rem;
      }
    }
  }
  &__tenant {
    display: flex;
    gap: 2.5rem;
  }
}

.disabled-card {
  border: 1.5px solid $--bosch-gray-70;
}

.enabled-card {
  border: 1.5px solid $--bosch-blue-50;
}

.enabled {
  opacity: 1;
}
