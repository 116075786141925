@import '../../../stylesheet/variables.scss';
@import '../../../variables.module.scss';
@import '../../../stylesheet/mixins.scss';

.individual-onboarding {
  background: $--bosch-gray-71 0% 0% no-repeat padding-box;
  padding: 1rem 3rem 1rem 3rem;

  &__breadcrumb {
    margin-bottom: 1rem;
  }

  &__container {
    background: $--bosch-white 0% 0% no-repeat padding-box;
    width: 100%;

    &__form {
      padding: 2rem;
      min-height: 63vh;
      max-height: 65vh;
      overflow: auto;

      &__header-label {
        font-size: 1.4rem;
        color: $--bosch-black;
        font-weight: 800;
        font-family: $bosch-font-family-bold;
        margin-bottom: 1.5rem;
      }

      &__home-wrapper__form {
        position: relative;

        &__wrapper {
          display: grid;
          grid-gap: 1.5rem;
          grid-template-columns: 1fr 1fr;
          height: inherit;
          overflow: auto;
          grid-auto-rows: minmax(min-content, max-content);
        }
      }
    }

    &__navigation {
      margin-top: 0rem;
    }
  }

  &__footer {
    padding: 3rem 3rem;
  }

  .horizontal__container {
    grid-template-columns: 13.2rem auto;
  }
}
