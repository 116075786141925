@import '../../stylesheet/variables.scss';
@import '../../variables.module.scss';
@import '../../stylesheet/mixins.scss';

.tenant {
  background: $--bosch-gray-71 0% 0% no-repeat padding-box;
  padding: 1rem 6rem 1rem 6rem;

  &__breadcrumb {
    margin-bottom: 1rem;
  }

  &__container {
    background: $--bosch-white 0% 0% no-repeat padding-box;
    width: 100%;
    padding: 1.5rem;

    &__img-container {
      background: $--bosch-white 0% 0% no-repeat padding-box;
      width: 7rem;
      display: flex;
      justify-content: center;
      border-radius: 50%;
      padding: 1rem;

      &__img {
        width: 4rem;
      }
    }

    &__heading {
      display: flex;
      align-items: center;

      &__img {
        width: 3rem;
      }

      &__title {
        font-size: medium;
        font-weight: bold;
        margin-left: 0.5rem;
      }
    }

    &__product {
      margin-top: 1rem;
      font-weight: 900;
      font-size: small;
    }

    &__detail {
      background: $--bosch-gray-71 0% 0% no-repeat padding-box;
      width: 100%;
      padding: 1.5rem;
      margin-top: 1rem;

      &__desc {
        display: flex;
        align-items: center;
        gap: 2rem;

        &__img {
          width: 3rem;
        }

        &__name {
          font-weight: 600;
          font-size: small;
        }

        &__part {
          font-weight: 600;
          font-size: small;
        }
      }
    }

    &__section {
      font-size: medium;
      font-weight: 650;
      margin-top: 2rem;
      margin-bottom: 1rem;
    }

    &__group-container {
      margin-bottom: 7rem;

      &__group {
        font-size: medium;
        font-weight: 650;
        margin-top: 2rem;
        margin-bottom: 0.5rem;
      }

      &__publish-interest {
        margin-bottom: 0.5rem;
      }
    }
  }
  &__footer {
    padding: 3rem 3rem;
  }
}
