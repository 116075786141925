@import '../../../../stylesheet/mixins.scss';
@import '../../../../stylesheet/variables.scss';

.head__param__settingapi {
  padding: 0.5rem 0.875rem;
  border-radius: 0.375rem;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}

.head__param__div {
  display: flex;
}

.setting__list {
  padding-right: 0.75rem;
}

.setting__type {
  div {
    border-radius: 0.625rem;
    background-color: var(--bosch-turquoise-80);
    padding: 0 0.75rem;
  }
}

.align__left {
  @include flexStart;
}

.primary__button {
  background-color: var(--bosch-blue-50) !important;
  color: var(--bosch-white) !important;
  text-transform: uppercase;
  padding: 0.625rem 1.125rem !important;
}

.align__center {
  position: relative;
  top: 3.5rem;
  left: 2rem;
}

.PrivateRadioButtonIcon-root-537 {
  opacity: 0;
}

.add-button {
  background: var(--bosch-white) !important;
  color: var(--bosch-blue-50) !important;
  border: 0.0625rem solid var(--bosch-blue-50) !important;
  padding: 0.125rem 1rem !important;
  display: flex !important;
  min-width: 5.25rem !important;
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.copy__icon__disable {
  pointer-events: none;
  opacity: 0.5;
}

#icon__copy {
  background: unset;
  border: unset;
  cursor: pointer;
  margin-right: 1rem;
  @include flexAlignCenter;
  padding: 0.5rem;

  &:focus,
  &:active {
    animation: btn-color 1s forwards linear;
  }

  &:hover {
    background-color: $--bosch-black-background-08;
    border-radius: 50%;
    padding: 0.5rem;
    @include flexAlignCenter;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  svg {
    width: 1.8rem;
    height: 1.8rem;
  }
}

.copy__icon__hover__transaction {
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 50%;
  padding: 0.5rem;
  @include flexAlignCenter;
}

.generate_button {
  display: flex;
  align-items: center;
}

.margin_top__2 {
  margin-top: 2rem !important;
}

.margin_top__1 {
  margin-top: 1rem;
}

.settings__form {
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media only screen and (max-width: 768px) {
    display: flex;
    flex-flow: column;

    .settings__form--2 {
      order: 2;
    }

    .settings__form--1 {
      order: 1;
    }

    .settings__form--3 {
      order: 3;
    }
  }

  .a-dropdown--disabled {
    select {
      color: $--bosch-black !important;
      opacity: 1;
    }

    label {
      color: $--bosch-black !important;
    }
  }
}

.setting__information {
  display: flex;

  &__icon {
    width: 1.2rem;
    height: 1.2rem;
    margin-right: 0.5rem;
    margin-top: 0.4rem !important;

    img {
      width: 1rem;
      height: 1rem;
    }
  }
}

.header__parameter__section {
  @include flexCustom(space-between, center);

  @media only screen and (max-width: 768px) {
    flex-flow: column;
    align-items: baseline;
  }
}

.description__input {
  margin-bottom: 1.5rem !important;
  margin-right: 1rem;
}

.mss_secret_key {
  input {
    caret-color: transparent;
    cursor: default;

    &:focus,
    &:hover {
      background-color: var(--neutral__enabled__fill__default) !important;
    }
  }
}

@keyframes btn-color {
  0% {
    background: var(--bosch-gray);
  }

  99% {
    background: var(--bosch-gray-70);
  }

  100% {
    background: var(--bosch-gray-75);
  }
}
