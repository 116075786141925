@import '../../../variables.module.scss';
@import '../../../stylesheet/variables.scss';

.context-menu {
  &__box {
    width: 3rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border: 0.0625rem solid var(--bosch-blue-50);
  }
}

.filter {
  &__img {
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
  }

  &__pop {
    font-size: 1rem;
    font-family: $bosch-font-family-medium;
    text-align: left;
  }

  &__column {
    border: 0.0625rem solid;
  }
}

.m-popover {
  &__left {
    right: calc(100% - 3rem);
    height: auto;
    max-height: 12.2rem;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 1;
    box-shadow: 0rem 0 1rem $--bosch-shuttle-gray;
  }
}
