@import '../../../stylesheet/mixins.scss';
@import '../../../stylesheet/variables.scss';

$sideBarWidth: 45rem;

.generatesku-id {
  &__sidebar {
    max-width: $sideBarWidth !important;

    &_body {
      max-width: $sideBarWidth - 2rem !important;
    }
  }

  &__container {
    @include flex_column;
    row-gap: 2rem;
  }

  &__text-info-container {
    padding: 1rem 2rem;
    background-color: $--bosch-blue-50;
    color: $--bosch-white;
    word-break: break-word;
  }

  &__title-wrapper {
    display: flex;
    gap: 0.2rem;
    line-break: anywhere;
  }
}

.skuid {
  input {
    font-weight: 800;
    padding: 0 1rem !important;
    font-size: 1rem;
  }
}

.sku-table-container {
  overflow-x: auto;

  &-customtooltip:hover:before {
    max-width: unset !important;

    @media only screen and (max-width: 768px) {
      padding: 8px !important;
      left: 8px !important;
      word-wrap: normal !important;
      bottom: 16px !important;
    }
  }
}

table {
  width: 100%;
}

.plan-name,
.bosch-share,
.seller-share {
  width: 6rem;
}

.sku-id {
  width: 11rem;
}

.tenant-marketplace {
  padding: 1rem 2rem;

  &__reason-wrapper {
    @include flexCenter;
    gap: 0.5rem;
  }

  &__info-icon {
    width: 1.5rem;
    position: relative;
    top: 0.2rem;
  }

  &__tooltip:hover:before {
    min-width: 19rem;
    left: -19rem !important;
  }
}

.marketplace-table-header {
  color: $--bosch-grey;
}

.marketplace-table-wrapper-header {
  display: flex;
  height: 2rem;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $--bosch-powder-ash;
  margin-bottom: 0.5rem;
}

.marketplace-table-wrapper-col {
  display: flex;
  flex-direction: column;
  min-height: 2rem;
  border-bottom: 1px solid $--bosch-powder-ash;
}

.marketplace-table-wapper {
  display: flex;
  max-height: 4rem;
  justify-content: space-between;
  align-items: center;
  position: relative;
  border-bottom: 1px solid $--bosch-powder-ash;

  &__flex {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0.5rem 0;

    &:not(:first-child) {
      margin: 0;
    }
  }

  &__reason {
    align-self: flex-start;
    padding: 0.5rem 0;
  }
}

.tenant-status-view {
  gap: 0.5rem;
  display: flex;
  align-items: center;
}

.tenant-status-image {
  width: 1.5rem;
  height: 1.5rem;
}

.product_reject-status {
  width: 1.25rem;
  height: 1.25rem;
  background: $--bosch-red 0% 0% no-repeat padding-box;
  border-radius: 50%;
}
