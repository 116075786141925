@import '../../../variables.module.scss';

.cursor-pointer {
  cursor: pointer;
  font-size: inherit;
}

.back-button {
  color: #005691;
}

.icon-style {
  vertical-align: bottom;
}

.bg-image-style {
  height: 350px;
  width: 100%;
}

.card-style {
  height: 300px;
  border-radius: 0px !important;
  background: rgba(0, 0, 0, 0.03) !important;
}

.condition-card-style {
  height: 10rem;
  border-radius: 0px !important;
  background: rgba(0, 0, 0, 0.03) !important;
  border: 1px solid lightgray;
}

.card-image {
  height: 150px;
}

.card-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-wrap: anywhere;
  word-break: break-all;
}

.div-style {
  position: absolute;
  background-color: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2rem;
  max-height: 41rem;
  overflow: auto;
  max-width: 40rem;
  min-width: 40rem;
}

.box-style {
  border: 1px solid lightgray;
  font-family: $bosch-font-family-medium;
  font-weight: 100;
  cursor: pointer;
  border-radius: 2px;
}

.radio-group-style {
  width: 400px;
}
.font-regular {
  font-family: $bosch-font-family-general;
}

.font-medium {
  font-family: $bosch-font-family-medium;
}

.condition-group-heading {
  font-family: $bosch-font-family-general;
  font-size: 1.6rem;
  font-weight: 100;
}

.group-img-style {
  width: 2.5rem;
}

.icon-spacing {
  margin-right: 0.5rem;
}
