@import '../../../variables.module.scss';
@import '../../../stylesheet/mixins.scss';
@import '../../../stylesheet/variables.scss';

.display-results {
  @include flexCenter;
  align-items: center;
  flex-direction: column;
  margin-top: 3.12rem;
  width: 100%;
  font: caption;
  &_icon {
    height: 18.75rem;
    width: auto;
  }

  @media only screen and (max-width: 768px) {
    padding: 0 1rem;
  }
}
