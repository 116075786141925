@import '../../../../stylesheet/variables.scss';
@import '../../../../stylesheet/mixins.scss';

.step-indicator-comments {
  width: 100%;
  border-radius: 0.313rem 0 0 0.313rem;
  z-index: 1;
  display: flex;
  flex-direction: column;
  height: max-content;

  &__m-container {
    display: none;

    @media only screen and (max-width: 768px) {
      display: block;
    }
  }

  @media only screen and (max-width: 768px) {
    width: auto;
    border-radius: unset;

    &__container {
      display: none;
    }
  }

  &__details {
    padding: 1.2rem;

    @media only screen and (max-width: 768px) {
      height: 3.6rem;
      padding: 1rem;
    }

    &__description {
      margin-top: 1rem;
      font-size: 0.875rem;
      color: var(--bosch-white);

      @media only screen and (max-width: 768px) {
        margin-top: unset;
        display: none;
      }
    }
  }

  &__stepper:last-child {
    .comments-node-divider {
      border-left: none;
    }
  }

  &__v-stepper {
    position: relative;
    transform: translateY(50%);
  }

  &__content__comments {
    &__title {
      color: $--bosch-white;
      font-size: 1rem;
      word-break: break-all;
    }

    &__description {
      color: $--bosch-black;
      font-size: 0.75rem;
      padding: 0;
      margin: 0;
      margin-top: 0.5rem;
      word-break: break-all;
    }
  }

  .active {
    opacity: 1;
  }

  .inprogres {
    .step-indicator__content {
      opacity: 0.8;
    }
  }

  .completed {
    opacity: 1;
  }

  &__active-pointer {
    height: 2rem;
    border-top: 1rem solid transparent;
    border-right: 1rem solid $--bosch-white;
    border-bottom: 1rem solid transparent;
    position: absolute;
    right: -1rem;
  }
}

@media only screen and (max-width: 768px) {
  .step-indicator {
    &__m-content {
      &__commentswrapper {
        &__container {
          @include flexCustom(space-between, center);
          margin-top: 1.1rem;
        }
      }

      &__form-count {
        color: var(--bosch-white);
        font-size: 1.1rem;
        font-weight: 800;
      }

      &__form-title {
        color: var(--bosch-white);
        font-size: 1rem;
      }

      &__description {
        color: var(--bosch-white);
        opacity: 0.8;
        font-size: 0.75rem;
      }
    }

    .a-progress-indicator {
      width: 100%;
      background: $--bosch--light-blue 0% 0% no-repeat padding-box;

      margin: 0.5rem 0;
    }

    &__m-progress-bar {
      .a-progress-indicator__inner-bar {
        background-color: var(--bosch-white);
      }
    }
  }
}

.comments-title {
  font-size: 2.25rem;
  display: flex;
}

.comments-node {
  width: 1.3rem;
  height: 1.3rem;
  background-color: var(--neutral__disabled__front__default);
  border-radius: 50%;
  text-align: center;
  color: $--bosch-black;
  font-size: 1.2rem;
  @include flexCenter;
}

.active-node {
  background-color: var(--major-accent__enabled__fill__default);
}

.comments-node-divider {
  left: 0.6rem;
  height: calc(100% + 4px);
  position: absolute;
  border-left: 0.188rem solid var(--neutral__disabled__front__default);
}

.comment-description {
  color: $--bosch-black;
  background-color: $--bosch-white;
  margin-left: 1.25rem;
  width: 100%;
}

.comment-content {
  padding: 0.5rem 0.5rem;
  width: 100%;
}

.comments-step-indicator-wrapper {
  position: relative;
  width: 90%;
  display: flex;
  align-items: center;
  background-color: $--bosch-white;
  margin-left: 1.25rem;
}

.comment-timeline {
  margin-left: 0.5rem;

  @media only screen and (max-width: 600px) {
    font-size: 0.7rem;
  }
}

.logistic-details {
  width: 50%;
  padding-right: 1.5rem;
}

.comments-content-indicator {
  width: 90%;
  display: flex;
  padding: 0.5rem 0;
  background-color: $--bosch-white;
}

.comment-content-container {
  display: flex;
  align-items: center;
  min-width: 100%;
}

.status-container {
  width: 100%;
}

.status-message {
  width: 100%;
  word-wrap: break-word;
  padding-right: 2rem;

  @media only screen and (max-width: 600px) {
    font-size: 0.9rem !important;
  }
}

.pod-heading {
  padding: 0.2rem;

  @media only screen and (max-width: 600px) {
    font-size: 0.7rem !important;
  }
}

.remark-container {
  display: flex;
  min-width: 80%;
  justify-content: space-between;

  @media only screen and (max-width: 600px) {
    font-size: 0.7rem !important;
  }
}

.comments-indicator {
  margin: 1.5rem 0;
  padding: 0 0 0 1rem !important;
  display: flex;

  @media only screen and (max-width: 768px) {
    padding: 0 1rem !important;
  }
}

.upload-pod {
  font-weight: bold;
  margin-top: 0.5rem;
}

.reject-pod {
  font-weight: 500;
  margin-top: 0.5rem;
}

.commented-by-container {
  @media only screen and (max-width: 600px) {
    font-size: 0.7rem;
  }
}

.align-center {
  @include flexAlignCenter;
}
