.groupingCard {
  grid-column: span 2;
  margin-bottom: 1rem;

  &__container {
    background: #f8f8f8 0% 0% no-repeat padding-box;
    margin-bottom: 1.5rem;

    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }

  &__title {
    font-size: 1.25rem;
    margin-bottom: 2.2rem;
  }

  &__content-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1rem;
    position: relative;

    .home-wrapper__form__input,
    .home-wrapper__form__document {
      margin-bottom: 1rem;

      @media only screen and (max-width: 768px) {
        margin-bottom: 0.5rem;
      }
    }

    @media only screen and (max-width: 768px) {
      flex-direction: column;
      padding: 1rem 0;
      width: 100%;
    }
  }

  &__index-container {
    margin-right: 1.5rem;
    min-width: 1.5rem;

    @media only screen and (max-width: 768px) {
      margin: 0 1.2rem;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    margin-right: 4rem;

    @media only screen and (max-width: 768px) {
      width: 100%;
      padding: 1rem 1rem 0 1rem;
    }
  }

  &__image-container {
    width: 10%;
    display: flex;
    justify-content: space-between;

    @media only screen and (max-width: 768px) {
      position: absolute;
      right: 3rem;
      margin-left: 88%;
    }
  }

  &__image {
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
  }
}
