@import '../../../stylesheet/variables.scss';
@import '../../../stylesheet/mixins.scss';

.navigation-buttons {
  @include flexCustom(space-between, center);
  padding: 0 2rem;
  min-height: 5rem;
  box-shadow: 0px -3px 6px #0000001a;
  align-items: center;
  width: 100%;
  background-color: $--bosch-white;

  &__auto-save-text {
    color: var(--bosch-gray-50);

    @media only screen and (max-width: 768px) {
      text-align: center;
      margin-top: 1rem;
    }
  }
  &__right-wrapper {
    gap: 1rem;
    @include flexCustom(space-between, center);
  }
  @media only screen and (max-width: 768px) {
    padding: 0 1rem;
  }
}
