@import '../../../stylesheet/variables.scss';
@import '../../../stylesheet/mixins.scss';

.step-indicator {
  width: 27rem;
  background-color: rgba($color: rgb(0, 125, 193), $alpha: 0.9);
  border-radius: 5px 0 0 5px;
  z-index: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;

  &__m-container {
    display: none;
    @media only screen and (max-width: 768px) {
      display: block;
    }
  }

  @media only screen and (max-width: 768px) {
    width: auto;
    border-radius: unset;
  }

  &__details {
    padding: 1.2rem;

    @media only screen and (max-width: 768px) {
      height: unset;
      padding: 1rem;
    }

    &__header {
      font-size: 1.25rem;
      color: var(--bosch-white);
    }

    &__description {
      margin-top: 1rem;
      font-size: 0.875rem;
      color: var(--bosch-white);
      @media only screen and (max-width: 768px) {
        margin-top: unset;
      }
    }
  }

  &__stepper {
    padding: 1rem;
    cursor: pointer;
    display: flex;
  }

  &__stepper:last-child {
    .step-indicator__devider {
      border-left: none;
    }
  }

  &__v-stepper {
    position: relative;
  }
  &__count {
    width: 3.813rem;
    height: 3.813rem;
    background-color: $--bosch-white;
    border-radius: 50%;
    text-align: center;
    color: $--bosch-black;
    font-size: 1.2rem;
    @include flexCenter;
  }

  &__devider {
    left: 1.9rem;
    height: 100%;
    position: absolute;
    border-left: 3px solid $--bosch-white;
  }
  &__wrapper {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
  }
  &__content {
    width: 100%;
    display: flex;
    padding-left: 1.5rem;

    &__title {
      color: $--bosch-white;
      font-size: 1rem;
      word-break: break-all;
    }
    &__description {
      color: $--bosch-white;
      font-size: 0.75rem;
      padding: 0;
      margin: 0;
      margin-top: 0.5rem;
    }
  }
  .active {
    opacity: 1;
    background-color: $primary_color;
  }
  .inprogres {
    .step-indicator__content {
      opacity: 0.8;
    }
  }
  .completed {
    opacity: 1;
  }

  &__active-pointer {
    height: 2rem;
    border-top: 1rem solid transparent;
    border-right: 1rem solid $--bosch-white;
    border-bottom: 1rem solid transparent;
    position: absolute;
    right: -1rem;
  }
}

@media only screen and (max-width: 768px) {
  .step-indicator {
    &__m-content {
      &__wrapper {
        &__container {
          @include flexCustom(space-between, center);
          margin-top: 0.6rem;
        }
      }
      &__form-count {
        color: var(--bosch-white);
        font-size: 1.1rem;
        font-weight: 800;
      }
      &__form-title {
        color: var(--bosch-white);
        font-size: 1rem;
      }
      &__description {
        color: var(--bosch-white);
        opacity: 0.8;
        font-size: 0.75rem;
      }
    }
    .a-progress-indicator {
      width: 100%;
      background: #a5d3d3 0% 0% no-repeat padding-box;
      margin: 1rem 0;
    }
    &__m-progress-bar {
      .a-progress-indicator__inner-bar {
        background-color: var(--bosch-white);
      }
    }
  }
}
