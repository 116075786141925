@import '../../stylesheet/mixins.scss';
.swagger {
  &-container {
    margin: 0;
    margin-left: 3rem;
    margin-top: 5rem;
    margin-bottom: auto;
    @media only screen and (max-width: 768px) {
      margin: 0 1rem 1rem 1rem;
    }

    &-wrapper {
      @include flex_col();
      gap: 1.5rem;

      .heading {
        font-size: 2rem;
        font-weight: 600;
      }
      .sub-heading {
        color: var(--bosch-black);
      }
      .product-id-heading {
        font-weight: 600;
        font-size: small;
      }
    }
  }
}
.submit_btn {
  height: 3rem;
  width: 7rem;
  background: var(--bosch-blue-50);
  color: var(--bosch-white);
  cursor: pointer;
  border: none;
}
.submit_btn:disabled {
  background-color: var(--bosch-gray-70);
  color: var(--bosch-white);
  cursor: not-allowed;
}
