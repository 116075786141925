@import '../../../stylesheet/variables.scss';

.multi-select {
  position: relative;
  &__options {
    margin-top: 0.3rem;
    padding: 0.5rem;
    border: 0.0625rem solid $--bosch-gray-70;
    border-radius: 0.3rem;
    max-height: 12rem;
    position: absolute;
    width: 100%;
    z-index: 3;
    background: var(--bosch-white);
    overflow: auto;
  }
  &__option {
    list-style-type: none;
    padding: 0.5rem 0.5rem 1.5rem 0.5rem;
    margin: 0;
    cursor: pointer;
    &__label {
      margin-left: 0.5rem;
    }
  }
  &__selected-values {
    margin-top: 1rem;
  }
  &__chip {
    margin-bottom: 0.5rem;
  }
  // &__chip{

  //   display: inline-flex;
  //   align-items: center;
  //   justify-content: space-evenly;
  //   height: 2rem;
  //   width: auto;
  //   cursor: pointer;
  //   border: 0.0625rem solid $--bosch-gray-70;
  //   padding: 10px 14px;
  //   margin-right: 1rem;
  //   margin-bottom: 0.5rem;
  // }
  // .a-chip__label{
  //   margin : unset;
  // }
  &__required {
    color: var(--bosch-red-50);
  }

  &__max-char {
    white-space: nowrap;
  }
}
