@import '../../../variables.module.scss';
@import '../../../stylesheet/variables.scss';
@import '../../../stylesheet/mixins.scss';

.api-type {
  font-weight: bolder;
  margin-top: 1rem !important;
  margin-bottom: 1.5rem !important;
}

.MuiListItem-root:before {
  display: none;
}

.add-api-close-icon {
  border-radius: 0.875rem;
  padding: 0.125rem;
  color: var(--bosch-white);
  z-index: 1;
  margin-right: -0.5rem;
  cursor: pointer;
  display: flex;
  position: absolute;
  bottom: 0.8rem;
  left: 1rem;

  @media only screen and (max-width: 768px) {
    bottom: calc(100% - 16rem);
    position: unset;
  }

  img {
    width: 1rem;
    height: 1rem;
  }
}

.heading-style {
  font-family: $bosch-font-family-general;
  font-size: 1.4rem;
  margin-bottom: 0.625rem !important;
}

.subheading-style {
  font-family: $bosch-font-family-general;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}

.navigation {
  background: var(--bosch-black);
}

.navigation-button {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
  padding-left: 1rem;
}

.preview {
  &-spacing {
    margin-right: 1rem;
    @include flexAlignCenter;

    .margin_left {
      margin-left: 1rem;
    }
  }

  &_button-wrapper {
    @media only screen and (max-width: 768px) {
      display: none;
    }
  }
}

.breadcrumb {
  text-decoration: none;
  color: var(--minor-accent__enabled__front__default);
  margin: 0.5rem;
  font-family: $bosch-font-family-general;
  font-size: 0.9rem;
  font-weight: 400;
  padding-top: 3px;
}

.note-style {
  font-family: $bosch-font-family-general;
  font-size: 0.95rem;
  color: var(--bosch-black);
  margin-bottom: 1rem;
  font-weight: 500;
}

.error-text {
  color: var(--bosch-red);
}

.add-button {
  min-width: 4rem;
}

.step-description {
  font-size: 0.75rem;
}

.inline-edit-save-button-style {
  display: flex;
  float: right;
  margin-top: 1rem;
  padding-bottom: 2rem;
}

.margin-right-spacing {
  margin-right: 1rem;
}

.upload-doc-container {
  margin-bottom: 1.875rem;
}

.thumbnail-section {
  margin: 0.625 auto;
  border-radius: 50%;
  width: 7.8125rem !important;
  height: 7.8125rem;
}

.cover-img {
  max-width: 21.25rem !important;
  max-height: 7.8125rem !important;
  margin-top: 0.9375rem;
  margin-bottom: 0.3125rem;
}

.cover-img-section {
  object-fit: fill !important;
  width: 18.75 !important;
  height: 7.8125rem !important;
}

.delete-icon-color {
  color: var(--bosch-red);
}

.add-icon-color {
  color: var($--bosch-grey);
  font-size: 1.65em !important;
}

.add-icon {
  margin: -1.875rem 0 0 10rem;
  height: 3.125rem;
}

.delete-icon {
  margin: -3.125rem 0 0 10rem;
  height: 4.375rem;
}

.cover-img-delete-icon {
  margin-top: -0.625rem;
}

.dropzone {
  cursor: auto;
}

.dropzone:focus {
  outline: 0;
}

.info-icon-style {
  font-size: 1.125rem !important;
  margin-left: 0.5rem !important;
  margin-bottom: -0.125rem !important;
}

.upload-image {
  display: flex;
  column-gap: 4.6875rem;
}

.supported-formats {
  font-size: 0.75rem !important;
  font-family: $bosch-font-family-general;
  text-align: center !important;
  margin-top: 0.625rem;
}

.document-cover-image {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 100%;
}

.browse-cover-img {
  flex-grow: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: fit-content;
  min-width: fit-content;
}

.cover-img-dropzone {
  max-width: 31.25rem;
}

.cover-img-resolution {
  align-self: flex-start;
  margin-top: 0.625rem;
  font-size: 0.75rem;

  @media only screen and (max-width: 768px) {
    margin-top: 1rem;
  }
}

.cover-img-resolution-span {
  color: var($--bosch-grey);
  background-color: var(--neutral__enabled__fill__default);
  padding: 0.1875rem 0.5rem;
  margin: 0 0.3125rem;
}

.cover-img-resolution-cross {
  font-size: 0.75rem;
}

.thumbnail-dropzone {
  margin-top: 1.5625rem;
}
