@import '../../../src/variables.module.scss';
@import '../../stylesheet/mixins.scss';
@import '../../stylesheet/variables.scss';

.dashboard-container {
  background-repeat: no-repeat;
  background-position: center;
  background-size: 550px;
  height: 80vh;
  display: flex;
  justify-content: center;

  @media only screen and (max-width: 768px) {
    @include flex_col(space-between);
    height: 100%;
    background-position: 25% 35%;
    background-size: 100%;
  }
}

.dashboard-height {
  height: 89vh;
  background-color: #f2f2f2;
}

.image-dashboard {
  padding-left: 1.7vw;
  height: 16vh;
}

.dashboard-text {
  padding-left: 12px;
  padding-top: 1vh;
  font-family: $bosch-font-family-general;
  color: $primary-color;
  font-size: 21px;
}


.content-container {
  @include flexAlignCenter;

  @media only screen and (max-width: 768px) {
    height: 100%;
  }
}

.image-container {
  background-color: $--bosch-concrete-solid;
}