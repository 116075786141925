@import '../../../stylesheet/variables.scss';

$gray-tryout-text: #525f6b;

.pricing-card {
  width: 16.375rem;
  height: 20.9rem;
  border: 0.125rem solid #e6e6e6;
  // Change gray to Bosch border gray
  position: relative;
  &__display-flex {
    display: flex;
  }
  &__not-supported-msg {
    font-size: 0.75rem;
    color: #ff0000;
  }
  &__top {
    background: #007bc0;
    min-height: 7.2rem;
    height: max-content;
    color: white;
    width: 100%;

    &-exclusive {
      margin: -0.2rem;
      width: 5rem;
      height: 5rem;
      position: absolute;
      z-index: 1;
    }

    &-customtooltip:hover:before {
      left: 5% !important;
      bottom: unset !important;
    }

    .bold-style {
      font-weight: bold;
      font-size: 1.2rem;
    }

    &-items {
      display: inline-block;
      font-size: 1rem;
      margin: 0.1rem 0;
      width: 100%;
      text-align: center;
    }
    .pricing-card__price {
      font-weight: bold;
    }
    .pricing-card__name {
      margin-top: 1rem;
      text-transform: uppercase;
      word-wrap: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 15rem;
      padding: 0;
      padding-left: 1.3rem;
    }
  }

  &__list {
    ul,
    .list-item {
      padding: 0;
      margin: 0;
    }

    .list-item {
      margin-left: 1rem;
      font-size: 0.75rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 15rem;
      &__checkmark {
        position: relative;
        top: 0.4rem;
      }
    }

    [data-tooltip]:hover:before {
      word-wrap: break-word;
      max-width: calc(100% - 2rem) !important;
      left: 1.125rem !important;
    }
    &__user-group-box {
      background: var(--bosch-gray-80);
      height: 30px;
    }
    &__user-group-icon {
      margin: 3px 10px 0px;
      float: left;
    }
    &__user-group-desc {
      padding-top: 6px;
      font-weight: bold;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__tryout {
    margin: 0.5rem 1.5rem;
    word-wrap: break-word;
    color: $gray-tryout-text;
    font-size: 0.75rem;

    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    white-space: pre-wrap;
  }
  &__bottom {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0.5rem;
    &-button {
      margin: 0 0.5rem;
      &__icon {
        width: 1.5rem;
      }
      &__disabled {
        color: var(--bosch-gray-80);
        opacity: 0.2;
      }
    }
    &__disabled {
      color: var(--bosch-gray-80);
      opacity: 0.2;
    }
    .button {
      margin: 0 0.25rem;
      overflow: hidden;
      background-color: transparent;
      border: 0;
      cursor: pointer;
    }
  }

  &__status-badge {
    position: absolute;
    top: -0.5rem;
    right: 0.5rem;
    padding: 0 0.5rem;
  }

  .drafted-card {
    color: $--bosch-black;
    background-color: $--bosch-white-20;
  }

  .submitted-card {
    color: $--bosch-blue-50;
    background-color: $--bosch--gray-75;
  }

  .published-card {
    color: $--bosch-success-main;
    background-color: $--bosch-green-90;
  }

  .rejected-card {
    color: $--bosch-danger-dark;
    background-color: $--bosch-red-50;
  }

  .toggle-button-div {
    margin-top: 0.7rem;
  }
  .a-toggle__box {
    top: -0.55rem;
    width: 2.5rem;
  }
  .a-toggle__box:before {
    height: 1.3rem;
  }
  .a-toggle__box:after {
    left: 0.375rem;
    top: 0.275rem;
  }
  .a-toggle input[type='checkbox']:checked + .a-toggle__box:after {
    left: 1.375rem;
  }
}
