@import '../../../../../variables.module.scss';
@import '../../../../../stylesheet/variables.scss';
@import '../../../../../stylesheet/mixins.scss';

.table-container {
  margin-top: 2rem !important;
}

.unit-value {
  font-family: $bosch-font-family-medium;
  font-size: 0.8125rem !important;
}

.table-data {
  font-family: $bosch-font-family-medium;
}

.table-head-text {
  font-family: $bosch-font-family-medium;
  color: var(--bosch-white) !important;
}

.checkmark-slab-table {
  width: 1.5rem;
  position: relative;
  top: 0.5rem;
}

.currency__th {
  span {
    padding-left: 0.4rem;
  }
}

.delete-tier-button {
  min-width: 1.875rem !important;
  border-radius: 0 !important;
  cursor: pointer;

  img {
    width: 1.5rem;
  }
}

.delete-tier-item {
  align-self: flex-end !important;
}

.show-pricing-dialog {
  margin-left: 0.5rem !important;
}

.checkbox-label-container {
  font-size: 0.875rem !important;
  font-family: $bosch-font-family-bold;
  color: var(--bosch-blue-50) !important;
}

.unlimited-checked {
  color: var(--bosch-blue-50) !important;
  padding-left: 0 !important;
}

.checkbox-background {
  background-color: transparent !important;
}

.unlimited-disabled {
  color: var(--bosch-black) !important;
  cursor: default !important;
  pointer-events: none !important;
}

.checkbox-form-container {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.alert-banner {
  border-radius: 0 !important;
  margin-bottom: 0.25rem !important;
  position: relative;
  top: -0.563rem;
  background-color: $--bosch-green-36;
}

.validation-container {
  background-color: $--bosch-green-36;
}

.price-field {
  width: 7.5rem !important;

  * {
    font-family: $bosch-font-family-light;
  }

  input {
    font-family: $bosch-font-family-medium !important;
  }
}

.rate-limit-label {
  font-family: $bosch-font-family-medium;
  font-size: 0.875rem !important;
}

.quota-control {
  margin-left: 2rem !important;
}

.chargable-unit-control {
  width: 50% !important;
}

.table-surface__bg-error {
  margin-top: 1rem;
  padding: 0.5rem;
  background-color: $--bosch-red;
  color: $--bosch-white;
}

.table-surface {
  margin-top: 1rem !important;
  border-left: 0.0625rem solid var(--bosch-gray-80) !important;
  border-right: 0.0625rem solid var(--bosch-gray-80) !important;

  @media only screen and (max-width: 768px) {
    overflow-x: scroll;
    border-left: unset !important;
    border-right: unset !important;
    margin-bottom: 1rem;

    &::-webkit-scrollbar {
      height: 0.4rem;
    }
  }

  & .a-notification {
    display: block;
  }
}

.table-head {
  background: var(--bosch-gray-95) !important;
  opacity: 1;
}

.quota-value {
  font-family: $bosch-font-family-general;
  font-style: italic !important;
  opacity: 0.7 !important;
}

.table-seperator {
  border-right: 0.0625rem solid var(--bosch-gray-80) !important;
  color: var(--bosch-black) !important;

  @media only screen and (max-width: 768px) {
    width: 8rem;
    word-wrap: break-word;
  }

  .a-text-field label {
    max-width: calc(100% - 3rem);
  }

  &-customtooltip:hover:before {
    max-width: unset !important;
    bottom: 100% !important;
    left: 0 !important;
  }
}

.bold-heading {
  font-weight: bold !important;
  font-family: $bosch-font-family-bold;
}

.add-tier-button {
  color: var(--bosch-blue-50) !important;
  border-color: var(--bosch-blue-50) !important;
  border-radius: 0 !important;
  font-size: 1rem !important;
  font-family: $bosch-font-family-general;
  text-transform: capitalize !important;
}

.add-tier-button:disabled {
  color: var(--bosch-blue-50) !important;
  border-color: var(--bosch-blue-50) !important;
  border-radius: 0 !important;
  font-size: 1rem !important;
  font-family: $bosch-font-family-general;
  text-transform: capitalize !important;
  opacity: 0.4 !important;
}

.success-message {
  font-family: $bosch-font-family-general;
  font-size: 0.8rem !important;
}

.list-icon {
  min-width: 1.5rem !important;
  color: var(--bosch-green-10) !important;
}

.list-item-container {
  padding: 0 !important;
}

.price-info-icon {
  color: var(--bosch-blue-50) !important;
  margin-right: 0.5rem !important;
}

.from-column {
  max-width: 11.25rem !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden !important;
}

.currency-text {
  font-family: $bosch-font-family-light;
  font-size: 0.875rem !important;
}

.maximum-tiers-count-text {
  color: var(--bosch-red-10) !important;
}

.maximum-limit-count-text {
  color: var(--bosch-red-10) !important;
  padding-bottom: 0.25rem !important;
}

.delete-tier-icon {
  width: 0.7em !important;
  height: 0.9em !important;
  color: var(--bosch-white) !important;
}

.validation-list {
  color: var(--bosch-red-10);
  padding-left: 1rem !important;
  padding-bottom: 0 !important;
}

.gst-description {
  font-family: $bosch-font-family-light;
}

.gst-container {
  margin: 0.5rem 0 0.5rem 0 !important;
  display: flex !important;
  align-items: center;

  &-icon {
    @include flexAlignCenter;

    .a-icon {
      font-size: 1.2rem;
    }
  }
}

.slab-table {
  border: 0.063rem solid var(--bosch-gray-70);
  margin-bottom: 0.5rem;
  border-radius: 0 !important;

  th {
    padding: 1rem;
    color: var(--bosch-white);
  }

  tbody {
    th {
      color: var(--bosch-black);
    }
  }
}

.unlimited_name {
  font-size: 1rem;
  display: flex;
}
