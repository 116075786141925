@import '../../../stylesheet/variables.scss';

.dropdown_single {
  @media only screen and (max-width: 768px) {
    font-size: 0.75rem;
  }

  .a-dropdown label {
    pointer-events: none;
  }

  .a-dropdown select {
    border-bottom: 1px solid $--bosch-black;
    text-indent: 0px;
    height: 3.3rem;
  }

  &__errorMessage {
    color: $--bosch-red;
  }

  &__required {
    color: $--bosch-danger-dark;
  }
}
