@import '../../../../stylesheet/variables.scss';
@import '../../../../variables.module.scss';
@import '../../../../stylesheet/mixins.scss';

@mixin responsiveFullWidth() {
  @media screen and (max-width: 1200px) {
    max-width: 100%;
    width: 100%;
    margin-top: 1rem;
  }
}

.consumption-status {
  @media screen and (max-width: 600px) {
    width: auto;
    overflow: auto;
  }
}

.contract-info {
  padding-top: 1rem;
}

.consumption-details-container {
  background-color: $--bosch-concrete-solid;
  padding: 1rem;
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }
}

.section-block {
  width: auto;
  @include responsiveFullWidth();
}

.usage-div-block {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1.5rem;
}

.text-input {
  width: 50%;
  @media screen and (max-width: 1200px) {
    width: 100%;
  }
}

.unit-currency-block {
  @media screen and (max-width: 1200px) {
    width: 100%;
  }
}

#input-block {
  font-size: 0.68rem;
}

.usage-block-1 {
  width: 50%;
}

.img-block {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.img-size {
  width: 1.5rem;
}

.heading-section {
  font-weight: bold;
}

.date-time-block {
  min-width: 100% !important;
  background-color: $--bosch-gray-90;
}

.date-time-block > label {
  color: $--bosch-black !important;
  font-family: $bosch-font-family-general;

  span {
    color: $--bosch-red;
  }
}

.date-time-block > div::after {
  border: none;
  border-bottom: 1px solid $--bosch-black;
  border-radius: 0;
  border-width: 1px !important;
}

.calendar-section {
  width: 30%;
  @include responsiveFullWidth();
}

.date-section {
  width: 100% !important;
  margin-top: -8px !important;
}

.calendar-icon {
  width: 1.5rem;
  height: 1.5rem;
  right: 0.5rem;
  transform: scale(1.3);
  cursor: pointer;
}

.remarks-section {
  width: 25%;
  @include responsiveFullWidth();
}

.remarks-block {
  margin-top: 1.5rem;
}

.button-summary {
  &__footer {
    background-color: $--bosch-concrete-solid;
    width: 100%;
    bottom: 0;
    right: 0;
    padding-right: 2rem;
    @media screen and (max-width: 1200px) {
      padding-right: 0 !important;
    }
  }

  &__navigation-buttons {
    @include flexCustom(flex-end, center);
    min-height: 5rem;
    align-items: center;
    width: 100%;

    &__right-wrapper {
      column-gap: 1rem;
      @include flexCustom(space-between, center);
    }

    @media only screen and (max-width: 1200px) {
      padding: 0 1rem;
    }
  }
}

.notification-card {
  margin-top: 1rem;
}

.notification-card .notification__wrapper {
  display: flex;
  gap: 1rem;
  align-items: flex-start;
}

.alert-icon {
  width: 2.5rem;
}

.success-card {
  background-color: $--bosch-green-95 !important;
  border: 1px solid $--bosch-green-85 !important;
}

.error-card {
  background-color: $--bosch-red-95 !important;
  border: 1px solid $--bosch-red !important;
}
