@import '../stylesheet/frontend-kit/';

.a-dropdown select {
  outline: none;
  text-indent: 0 !important;
}

/** * until github.com/facebook/create-react-app/issues/11771 * gets fixed, let's hide the problem and not address it */
body > iframe[style*='2147483647']:not([id='webpack-dev-server-client-overlay']) {
  display: none;
}

.CollapsableSideBar-appBar-7 {
  width: 100% !important;
}

.CollapsableSideBar-appBarShift-8 {
  margin-left: 20rem !important;
}

.m-table th {
  font-weight: 700 !important;
}

.text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

//sm - small device
//lg - large device
@media only screen and (max-width: 768px) {
  .d-sm-none {
    display: none;
  }
  .d-sm-block {
    display: block;
  }
}
@media only screen and (min-width: 768px) {
  .d-lg-none {
    display: none;
  }
  .d-lg-block {
    display: block;
  }
}
