.category {
  &__container {
    margin-top: 2rem;
    padding: 1rem;

    &__title {
      text-align: left;
      font-size: larger;
      font-weight: 900;
      margin-bottom: 1rem;
    }

    &__check {
      margin-bottom: 1rem;
      display: flex;

      &_tnc {
        margin-left: 0.5rem;
      }
    }
  }
}
