.m-search-form {
  @media only screen and (max-width: 768px) {
    width: 100%;
  }

  .product__search {
    width: 25rem;

    @media only screen and (max-width: 768px) {
      width: 100%;
    }

    input[type='search']::-webkit-search-cancel-button {
      display: none;
    }
  }
}
