@import '../variables.module.scss';

@font-face {
  font-family: $bosch-font-family-bold;
  font-weight: 700;
  src:
    url(../assets/bosch/BoschSans-Bold.woff2) format('woff2'),
    url(../assets/bosch/BoschSans-Bold.woff) format('woff');
  font-display: swap;
}

@font-face {
  font-family: $bosch-font-family-light;
  src:
    url(../assets/bosch/BoschSans-Light.woff2) format('woff2'),
    url(../assets/bosch/BoschSans-Light.woff) format('woff');
  font-display: swap;
}

@font-face {
  font-family: $bosch-font-family-medium;
  src:
    url(../assets/bosch/BoschSans-Medium.woff2) format('woff2'),
    url(../assets/bosch/BoschSans-Medium.woff) format('woff');
  font-display: swap;
}
