@import '../../stylesheet/variables.scss';
@import '../../stylesheet/mixins.scss';

$homeWrapper: calc(100vh - 79px);

.home {
  display: flex;
  flex-direction: column;
  background: $--bosch-concrete-solid 0% 0% no-repeat padding-box;
  height: 100%;

  &__breadcrumb {
    height: 3.1rem;
    display: flex;
    align-items: center;

    @media only screen and (max-width: 768px) {
      background: var(--bosch-white);
      padding: 0 1rem;
    }
  }

  @media only screen and (max-width: 768px) {
    display: block;
  }

  &__container {
    flex: 1;
    max-width: 128.6rem;
    height: $homeWrapper;
    overflow: hidden;

    &__pricing-notification {
      margin-bottom: 1rem;

      .pricing__notification-img {
        height: 2rem;
      }

      .pricing__notification__content {
        @include flexCustom(space-between, center);
        width: 94%;

        @media only screen and (max-width: 768px) {
          display: block;
        }
      }

      .pricing-status__container {
        width: 82%;
      }

      .pricing__notification-img-wrapper {
        padding: 0.7rem;
        background-color: $--bosch-green-90;
        border-radius: 50%;
        width: fit-content;
      }

      @media only screen and (max-width: 768px) {
        .pricing-status__custom-tooltip {
          &:hover:before {
            bottom: -6rem;
          }
        }
      }
    }

    &__waiting-approval {
      @include flexCustom(space-between, center);
      gap: 0.5rem;

      @media only screen and (max-width: 768px) {
        justify-content: flex-start;
      }
    }

    &__status-title {
      color: $--bosch-success-main;
    }

    &__status {
      color: $--bosch-blue-50;
    }

    &__info-modal {
      &__height {
        .dialog_modal__error {
          background: var(--bosch-yellow-90);
        }
      }
    }

    @media only screen and (max-width: 768px) {
      margin: unset;
      width: 100%;
      height: 100%;
    }

    @media only screen and (max-width: 768px) {
      &__success-modal__height {
        .dialog_modal__message {
          position: relative;
          bottom: 1rem;
        }
      }
    }
  }

  .home-wrapper {
    display: flex;
    min-height: calc(100vh - 14rem);

    @media only screen and (max-width: 768px) {
      display: block;
      max-height: 100%;
      min-height: 100%;

      .step-indicator__container {
        display: none;
      }
    }

    &__main-container {
      width: calc(100% - 27rem);
      border-radius: 0px 5px 5px 0px;
      background: $--bosch-white;

      @media only screen and (max-width: 768px) {
        width: unset;
        height: calc(100vh - 23rem);
      }
    }

    .home-wrapper__form {
      width: 100%;
      height: 100%;
      position: relative;
      @media only screen and (max-width: 768px) {
        position: static;
      }

      &__wrapper {
        display: grid;
        grid-gap: 1.563rem;
        grid-template-columns: 1fr 1fr;
        padding: 2rem;
        height: inherit;
        overflow: auto;
        grid-auto-rows: minmax(min-content, max-content);
        padding-bottom: 7rem;

        .pricing {
          margin-top: 1.5rem;

          .pricing__notification-img {
            width: 1.4rem;
          }
        }

        @media only screen and (max-width: 768px) {
          display: block;
          padding: 1rem 1rem 6rem;
        }
      }

      &__short-description {
        .a-text-area {
          height: 12.75rem;
          textarea {
            height: 100%;
          }
        }
      }

      &__container {
        position: relative;
        height: 100%;
        overflow: auto;
        padding-bottom: 50px;
      }

      &__input {
        grid-column: span 1;
      }

      &__tenant {
        display: flex;
        flex-direction: row;
        width: 100%;
        overflow-x: scroll;
      }

      &__date {
        background-color: var(--neutral__enabled__fill__default);
        border: 0;
        border-bottom: 0.0625rem solid var(--neutral__enabled__front__default);
        color: var(--neutral__enabled__front__default);
        height: 3rem;
        width: 100%;
        label {
          left: 0;
        }
      }

      &__input-disable {
        opacity: 0.6;
        pointer-events: none;
      }

      @media only screen and (max-width: 768px) {
        &__input:not(:last-child) {
          margin-bottom: 1rem;
        }
      }

      &__document {
        grid-column: span 2;
        @media only screen and (max-width: 768px) {
          margin-bottom: 1rem;
        }
      }

      &__media {
        display: flex;
        gap: 1rem;
        overflow-x: auto;
      }

      &__plan_card {
        display: flex;
        column-gap: 1rem;
        row-gap: 1.7rem;
        flex-wrap: wrap;
        &__description {
          font-size: 0.875rem;
          color: $--bosch-shuttle-gray;
          margin-top: 1rem;
        }

        @media only screen and (max-width: 768px) {
          justify-content: center;
        }
      }

      &__plan_error {
        margin-top: 0.6rem;
      }

      &__actions {
        position: absolute;
        width: calc(100% - 0.8rem);
        bottom: 0;
        z-index: 2;
      }

      .step__form__group-indicator {
        grid-column: span 2;
        margin-bottom: 1rem;
      }

      &__data {
        height: inherit;
      }

      &__create-plan-info {
        margin: 0.4rem 0 1.5rem 0;
        font-size: 0.9rem;
      }

      &__notification-info {
        font-size: 0.9rem;
      }
    }
  }

  .home-wrapper-notification {
    max-height: calc(100vh - 22rem);
    min-height: calc(100vh - 19rem);

    @media only screen and (max-width: 768px) {
      .home-wrapper__main-container {
        height: calc(100vh - 28rem) !important;
      }
    }
  }

  .home-wrapper-badge {
    max-height: calc(100vh - 18rem);
    min-height: calc(100vh - 18rem);
  }

  .home-wrapper-final {
    max-height: calc(100vh - 23rem);
    min-height: calc(100vh - 23rem);
  }

  .a-list--dot li:before,
  ul > li:before {
    height: 0;
    top: 0;
    width: 0;
  }
}

.align-items {
  align-items: center !important;
}

.form__view {
  word-break: break-word;

  .public-DraftStyleDefault-orderedListItem {
    list-style-type: none !important;
  }
}

.terms-and-conditions {
  display: grid;
  grid-column: span 2;
}

.color-light-grey {
  color: var(--bosch-gray-50);
}

.itemscondition-childcontainer {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-gap: 0.6rem;

  @media only screen and (max-width: 768px) {
    grid-template-columns: unset;
    margin-bottom: 1rem;
  }

  h4 {
    display: none;
  }
}

.selection-divider {
  width: 0.13rem;
  height: 12.7rem;
  max-height: max-content;
  background-color: $--bosch--gray-73;
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 768px) {
    width: unset;
    height: 10%;
  }
}

.divider-heading {
  color: $--bosch--gray-73;
  background-color: $--bosch-white;
}

.checkbox-confirmation {
  padding: 2rem;
  grid-column: span 2;
  margin-right: 1.7rem;
  background-color: $--bosch-white;
}

.visibility-div {
  grid-column: span 2;
}

.published-content-banner {
  height: 3rem;
  background: $--bosch-pale-yellow;
  margin-bottom: 1rem;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  border: 1px solid $--bosch-orange;

  @media only screen and (max-width: 768px) {
    height: auto;
  }

  &--icon {
    width: 2rem;
    margin-right: 1rem;
  }

  &--info-icon {
    width: 2rem;
    margin-left: 1rem;
    display: flex;
  }

  &--tooltip-icon {
    width: 1.5rem;
  }
}
