@import '../../../../stylesheet/variables.scss';
@import '../../../../stylesheet/mixins.scss';

.term-and-condition-doc {
  @include flexSpaceBetween;

  &__container {
    width: 100%;
  }

  &__bg-gray {
    background-color: $--bosch-concrete-solid;
  }

  .upload__list_file {
    background-color: unset;
  }
}
