@import '../../../stylesheet/variables.scss';
@import '../../../stylesheet/mixins.scss';

.pricing {
  &__notification-img {
    width: 2.5rem;
  }

  &__notification-wrapper {
    @include flexAlignCenter();
    gap: 1.5rem;
    width: 100%;

    &__header {
      margin-bottom: 0.2rem;
    }
  }

  &__notification {
    background-color: $--bosch-pale-yellow !important;
    border: 1px solid $--bosch-orange;
    padding: 0.4rem 1rem !important;
    width: 100%;

    &__content {
      width: 100%;
    }
  }
}
