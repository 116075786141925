@import '../variables.module.scss';
@import '../stylesheet/variables.scss';
@import '../stylesheet/mixins.scss';

.bosch-strip {
  background-size: 100%;
  height: 1.2rem;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1;
}

.logo {
  width: 9em;
  display: inline-block;
  margin-left: 3.2rem;
  cursor: pointer;
}

.terms-of-use-modal-content-style {
  font-family: $bosch-font-family-general;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(
    100vh - 7.96rem
  ); /* adjusting height according to the ant-tab height and bosch strip height to prevent hiding of scroll bar*/
  background-color: var(--bosch-white);
}

.info-section {
  margin-left: 4.5rem;
  margin-top: 4rem;
}

.text-style {
  margin-top: 0.94rem;
  width: 100%;
  text-align: justify;
  padding-right: 3rem;
}

.max-width-style {
  max-width: 100%;
}

#link {
  color: inherit;
}

.brand-name {
  font-size: 1.3rem;
  color: var(--bosch-black);
  margin-right: 2rem;
  cursor: pointer;
}

.react-pdf__Page__textContent span::selection {
  background: transparent;
  color: transparent;
}

.react-pdf__Page__textContent span {
  opacity: 0.1;
}

.annotationLayer .linkAnnotation > a:hover {
  background: transparent;
  color: transparent;
  box-shadow: none;
}

.react-pdf__Document {
  line-height: initial;
}

.react-pdf__Page__annotations {
  height: 0;
}

.react-pdf__Page__canvas {
  width: -webkit-fill-available;
  width: -moz-available;
}

.label-heading {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.label-content {
  font-size: 0.8rem;
}

.header-name {
  @include flexCustom(space-between, center);
  padding: 1rem;
  background-color: var(--bosch-white);
}

.brand-name a {
  color: var(--bosch-black);
  font-size: 1.25rem;
  font-weight: bold;
  text-decoration: none;
}

.footer-logo {
  display: 'flex';
  justify-content: 'flex-end';
}

.tabs {
  border-bottom: 0.15rem solid $--bosch-gray-71;
  margin: 0 2rem 0 2rem;
  font-size: 1.1rem;

  @media only screen and (max-width: 768px) {
    margin: 0;
  }
}

.tab {
  display: inline-block;
  padding: 1rem;
  cursor: pointer;
  color: var(--bosch-gray-55);
  font-weight: normal;
  width: 14rem;
  margin-bottom: -0.1rem;
}

.tab.active {
  border-bottom: 0.2rem solid var(--bosch-blue-60);
}

.tab:hover {
  color: var(--bosch-blue-60);
}

.tablist {
  @include flexSpaceBetween;
  @include textAlignCenter;
  width: 100%;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.tablink {
  text-decoration: none;
}
