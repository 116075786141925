@import '../../../../variables.module.scss';
@import '../../../../stylesheet/mixins.scss';
@import '../../../../stylesheet/variables.scss';

.breakdown-container {
  padding: 3rem 2rem;
  background: $--bosch-concrete-solid;
  min-height: 50rem;
  .back-button-wrapper {
    @include flex_row;
    cursor: pointer;
    margin-bottom: 2rem;
    .back-button-svg {
      margin-right: 0.5rem;
      font-weight: bold;
      font-size: 1.5rem;
    }
    .back-button-label {
      font-size: 1rem;
      letter-spacing: 0.5px;
      font-weight: 600;
    }
  }
  .detailed-time-text {
    font-size: 1.25rem;
    font-weight: 600;
    margin: 2rem 0;
  }
  .text-style {
    margin: 2.5rem 0;
    font-size: 1.25rem;
  }
  .dropdown-field {
    width: 15rem;
  }
  .table-wrapper {
    margin-top: 2rem;
    width: 35rem;
    padding-bottom: 5rem;
  }
}
