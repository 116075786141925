.background_Basic {
  background: var(--bosch-blue-50);
}

.background_Gold {
  background: var(--bosch-blue-50);
}

.background_Premium {
  background: var(--bosch-blue-50);
}

.background_Custom {
  background: var(--bosch-blue-50) !important;
}

.check_icon {
  font-size: 2.5;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hide-element {
  display: none;
}

.card__div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 0.0625rem dashed #e0e2e5;
  width: 16.375rem;
  min-width: 13rem;
  height: 20.75rem;
  cursor: pointer;
  margin-bottom: 3.125rem;
}

.card__div p {
  margin-top: 1rem;
}

.image_div {
  background-color: var(--bosch-blue-50);
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
}

.info_div img {
  width: 1.25rem;
  height: 1.25rem;
}

.info_div_wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.pl__1 {
  padding-left: 1rem;
}

.pricing_description {
  margin-bottom: 2rem;
}

.input__type input[type='checkbox'] {
  width: 1rem;
  height: 1rem;
}

.pl__input {
  padding-left: 1.05rem;
}

.input__type {
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
}

.container__pricing__card {
  width: calc(100% + 1rem);
  margin: -0.5rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1.375rem;

  @media only screen and (max-width: 768px) {
    justify-content: center;
  }
}

.pricing_grid {
  width: 16.375rem;
  height: 20rem;
  border-style: hidden;
  border-width: 0.0625rem;
  border-radius: 0;
  padding-bottom: 1rem;
  margin-bottom: 1.5rem;
  position: relative;

  @media only screen and (max-width: 768px) {
    margin-bottom: unset;
  }
}

.pricing_grid li:before {
  width: unset !important;
}

.card__grid {
  display: flex;
  width: 16.375rem;
}

@media only screen and (max-width: 768px) {
  .info_div {
    margin-top: 2rem;
  }
}

.pricing-notification-info {
  font-size: 0.9rem;
}

.api-notification-container {
  .pricing__notification-img {
    width: 1.5rem;
  }
}
