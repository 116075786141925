.action-grid {
  display: flex;
  float: right;
  margin-top: 1rem;
}

.right-outer-spacing {
  margin-right: 1rem;
}

.action__grid__end {
  position: fixed;
  bottom: -1rem;
  z-index: 1;
  right: 2rem;
}

.PrivateRadioButtonIcon-root-710 {
  display: none !important;
}

.header__param {
  background: var(--bosch-white) 0% 0% no-repeat padding-box;
  border-radius: 0.75rem;
  border: 0.125rem solid var(--bosch-gray-90);
  height: 8.5rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42%;
  font-size: 1rem;
  margin-right: 2rem;
}

.svg__icon {
  width: 1.8rem;
  height: 1.8rem;
  fill: var(--bosch-white);
  border: 0.25rem solid var(--bosch-gray-95);
  border-radius: 50%;
  position: absolute;
  top: 1rem;
  right: 1rem;
  display: block;
  overflow: hidden;
}

.active__api__setting .svg__icon {
  width: 1.8rem;
  height: 1.8rem;
  fill: var(--bosch-white);
  border: 0.063rem solid var(--bosch-blue-50);
  background-color: var(--bosch-blue-50);
  border-radius: 50%;
  position: absolute;
  top: 1rem;
  right: 1rem;
  display: block;
  overflow: hidden;
}

.active__api__setting .svg__icon svg {
  fill: var(--bosch-white);
  background-color: var(--bosch-blue-50);
  border: 0.063rem solid var(--bosch-blue-50);
  width: 1.75rem;
  position: relative;
  left: -0.25rem;
  top: -0.2rem;
  display: block;
}

.svg__icon svg {
  display: none;
}

.PrivateRadioButtonIcon-root-827 {
  display: none !important;
}

.header__param input {
  width: 100%;
  position: absolute;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.modal__selecttype__div {
  flex-wrap: nowrap !important;
  display: flex !important;
  width: 100%;
  margin-bottom: 2rem;
}

.active__api__setting {
  border: 0.125rem solid var(--bosch-blue-50);
}

.max {
  text-align: right;
  letter-spacing: 0;
  color: var(--bosch-gray-73);
  opacity: 1;
}
