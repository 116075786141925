@import '../../../variables.module.scss';

.table__wrapper {
  overflow-x: auto;
}

.table {
  padding: 1rem 0;

  &__container {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 768px) {
      display: block;
    }

    &__items {
      display: flex;
      align-items: center;
      width: auto;

      @media screen and (max-width: 768px) {
        width: auto;
      }

      &__title {
        text-align: left;
        color: var(--bosch-black);
      }
      &__drop {
        position: relative;
        left: 1rem;
        min-width: 10rem;
        padding-bottom: 0;
      }
    }
    &__pagination {
      position: relative;
      top: 0;
      right: 0;
    }
  }
  &-wrapper {
    text-align: left;
    background: var(--bosch-white);
  }
}
.render {
  width: 2.25rem;
  height: 1.125rem;
  text-align: center;
  font-style: $bosch-font-family-general;
  color: var(--bosch-white);
}

.m-table {
  @media screen and (max-width: 768px) {
    width: auto !important;
  }
}
