@import '../../../variables.module.scss';
@import '../../../stylesheet/variables.scss';
@import '../../../stylesheet/mixins.scss';

.order-summary {
  &-sidebar {
    padding: unset !important;
  }

  &-wrapper {
    padding: 2rem 1rem;
    height: 100%;
    overflow-y: scroll;

    @media only screen and (max-width: 768px) {
      padding: unset;
    }

    .order-summary {
      background-color: $--bosch-white;
      padding: 1.5rem 1.5rem 1rem 1.5rem;

      @media screen and (max-width: 768px) {
        border: 0.0625rem solid $--bosch-grey;
        margin-top: 1.5rem;
      }

      &__body {
        padding-bottom: 1rem;

        @media screen and (max-width: 768px) {
          display: flex;
          flex-direction: column-reverse;
          column-gap: 1rem;
        }
      }

      &__footer {
        width: 100%;
        bottom: 0;
        right: 0;
      }

      &__navigation-buttons {
        @include flexCustom(flex-end, center);
        min-height: 5rem;
        align-items: center;
        width: 100%;

        &__right-wrapper {
          column-gap: 1rem;
          @include flexCustom(space-between, center);
        }

        @media only screen and (max-width: 768px) {
          padding: 0 1rem;
        }
      }
    }

    .comment-section {
      background-color: $--bosch-black-background-09;
    }

    .comment-section-heading {
      padding-top: 1.5rem;
    }

    .comment-data-container {
      background-color: var($--bosch-white);
      max-height: 6.25rem;
      margin-bottom: 1.875rem;
    }

    .test-comment-component {
      margin-top: 0.75rem;
      width: 90%;
      margin-left: 2.5rem;
      display: flex;
      flex-direction: row;
    }

    .comments-progress-bar {
      width: 10%;
    }

    .comments-list-container {
      width: 90%;
    }

    .order-summary-container {
      padding-top: 1rem;

      @media only screen and (max-width: 768px) {
        padding: 0 1rem;
      }
    }

    .order-summary-page-title {
      padding-bottom: 0.75rem;

      @media only screen and (max-width: 768px) {
        padding: 2rem 1rem 0.75rem 1rem;
      }
    }

    .order-summary-heading {
      @media only screen and (max-width: 768px) {
        display: none;
      }
    }

    .popup-container {
      @include flexCustom('center', 'center');
      background-color: var($--bosch-white);
      position: fixed;
      z-index: 100000;
    }

    .summary-content-division {
      width: 100%;
      height: 0.875rem;
      background-color: $--bosch-black-background-09;
      border: none;
    }

    .label-reject {
      border: 0.0625rem solid $--bosch-red !important;
      background-color: $--bosch-white;
      color: $--bosch-red;
    }

    .extra-class {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $--bosch-white;
      position: fixed;
      z-index: 100000000;
    }

    .one {
      display: none;
    }

    .popup-close {
      display: none;
    }

    .popup-open {
      justify-content: center;
      align-items: center;
      background-color: $--bosch-white;
      position: fixed;
      z-index: 100000000;
    }
  }
}

.order-summary-details {
  @media screen and (max-width: 768px) {
    .step-indicator__details {
      height: auto;
    }

    .step-indicator__m-container {
      display: none;
    }
  }
}

.bread-crumb__arrow {
  display: flex;
  flex-direction: row;
  height: 1.5rem;
}
