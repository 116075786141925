@import '../../../stylesheet/variables.scss';

.header {
  position: fixed;
  width: 100%;
  z-index: 3;
  background-color: var(--bosch-white);

  @media only screen and (max-width: 768px) {
    position: unset;
  }

  .username-block {
    width: auto;
  }
  &__account-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    @media only screen and (max-width: 768px) {
      align-items: center;
    }
  }
  &__title {
    margin-right: 1rem;

    @media only screen and (max-width: 768px) {
      margin-right: 0.5rem;
      font-size: 1rem !important;
    }
  }
  &__container {
    display: flex;
    padding: 1rem 1.5rem;
    justify-content: space-between;

    @media only screen and (max-width: 768px) {
      padding: 0.8rem 1.5rem;
      position: relative;
      z-index: 1;
      background-color: $--bosch-white;
    }
  }
  .strip-small {
    height: 0.5rem !important;

    @media only screen and (max-width: 768px) {
      z-index: 1;
      position: relative;
    }
  }

  .side-navigation {
    .a-icon {
      width: 1rem;
      height: 1rem;
      font-size: 1.5rem;
    }
  }
}
