@import '../../../stylesheet/variables.scss';

.card-style {
  width: 9rem !important;
  height: auto;
}

.a-menu-item {
  border-bottom: 0.07rem solid $--bosch-white-20;
}

.a-menu-item:hover {
  background-color: $--bosch-white-20;
}
